

import { Vendor } from 'src/app/vendors/models/vendor.model';
import { ActivatedRoute, Router } from '@angular/router';

import { Platform, AnimationController, IonNav, ModalController, ToastController, PopoverController, AlertController, IonInput, ActionSheetController } from '@ionic/angular';
import { Component, OnInit, ElementRef, ViewChild, Input, TemplateRef } from '@angular/core';

import { NewOfferComponent } from '../new-offer/new-offer.component';
import { OfferUpdatePage } from '../offer-update/offer-update.page';
import { ApiService } from 'src/app/services/api/api.service';
import { UploadDesktopComponent } from 'src/app/components/media/upload-desktop/upload-desktop.component';
import { OffersService } from 'src/app/services/offers/offers.service';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';

import { CurrencySymbolPipe } from 'src/app/pipes/currency/currency-symbol.pipe';

import { InvoicesService } from 'src/app/services/invoices/invoices.service';
import { InvoicesListPage } from 'src/app/invoices/invoices-list/invoices-list.page';
import { retryWhen } from 'rxjs/operators';
import { ThemeService } from 'src/app/services/theme/theme.service';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ReportsService } from 'src/app/services/reports/reports.service';
import * as moment from 'moment';

class DateOnlyPipe extends DatePipe {
   public override transform(value): any {
    return super.transform(value, 'dd-MM-y');
  }
}
class CurrencyOnlyPipe extends CurrencyPipe {
   public override transform(value): any {
    return super.transform(value, 'EUR', 'symbol');
  }
}

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.page.html',
  styleUrls: ['./offer-details.page.scss'],
})
export class OfferDetailsPage {
  @ViewChild('favIcon', { read: ElementRef }) favIcon: ElementRef;
  @ViewChild(UploadDesktopComponent, { static: true }) UploadDesktopComponent: UploadDesktopComponent;
  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild(CurrencySymbolPipe, { static: true }) CurrencySymbolPipe: CurrencySymbolPipe;

  @ViewChild('totalSummaryTemplate') totalSummaryTemplate: TemplateRef<any>;
  @ViewChild('unitSummaryTemplate') unitSummaryTemplate: TemplateRef<any>;
  @ViewChild('costSummaryTemplate') costSummaryTemplate: TemplateRef<any>;
  @ViewChild('advanceTemplate') advanceTemplate: TemplateRef<any>;

  @ViewChild('costDiscountTemplate') costDiscountTemplate: TemplateRef<any>;
  @ViewChild('costUnityTemplate') costUnityTemplate: TemplateRef<any>;
  @ViewChild('costTemplate') costTemplate: TemplateRef<any>;
  @ViewChild('invoicedTemplate') invoicedTemplate: TemplateRef<any>;
  @ViewChild('cellTax') cellTax: TemplateRef<any>;

  @ViewChild('inputEl', { static: false }) inputEl: IonInput;
  @ViewChild('inputTax', { static: false }) inputTax: IonInput;

  @Input() id: any = '';
  @Input() nav: IonNav;

  public showProgressStatus = false;
  public offer: any;
  public favState = false;
  public favOnAnimation: Animation;
  public favOffAnimation: Animation;

  tabs_offer: any = ['Lines', 'Legals', 'Timing', 'Offers'];
  tab_offer: any = 'Lines'
  hidden = false;
  offers: []

  day = moment().format()

  page_number = 1;
  page_limit = 30;

  filteredAddress = [];
  selectedSegment

  listStatus: any = [];
  listTypesInvoice: any = [];

  availableChangeStatusOffer: any = [];

  editing_local_id_cell = {};

  offer_lines: any = [];
  offer_legals: any = [];
  columns_line = [];
  columns_legal = [];

  selectedLinesRows = [];
  selectedLegalsRows = [];

  selectedFilterLinesRows = [];
  selectedFilterLegalsRows = [];

  lineAdvanceToInvoice = [];
  prepareLinesOfferToInvoice = [];

  ColumnMode = ColumnMode;
  SelectionType = SelectionType;



  total_offer = 0
  diff_discount_offer = 0

  response;
  subscription


  vamps = [
    { name: "Bad Vamp" },
    { name: "Petrovitch the Slain" },
    { name: "Bob of the Everglades" },
    { name: "The Optimistic Reaper" }
  ];

  filterTerm: string;
  enableSearch = false;
  listTaxes



  offersTotals = []

  constructor(
    public platform: Platform,
    private offerApi: OffersService,
    private invoiceApi: InvoicesService,
    private reportApi: ReportsService,
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private animationCtrl: AnimationController,
    ////private nav: IonNav,
    private modalController: ModalController,
    private popoverController: PopoverController,
    private alertController: AlertController,
    private toastController: ToastController,
    private actionSheetCtrl: ActionSheetController,
    private currencyPipe: CurrencySymbolPipe,
    private _datePipe: DatePipe
  ) {
    this.id = this.route.snapshot.paramMap.get('id');

    console.log(this.costTemplate);
    console.log(this.totalSummaryTemplate);



  }

  createcolumns() {
    this.columns_line = [
      //{ prop: 'id', name: 'ID', sortable: true, resizable: true, frozenLeft: true, width: 75, minWidth: 75, maxWidth: 75, cellClass: 'td-id', headerClass: 'th-id', summaryFunc: () => null },
      //{ prop: 'local_id', name: 'Loc ID', sortable: true, resizable: true, frozenLeft: true, width: 75, minWidth: 75, maxWidth: 75, cellClass: 'td-id', headerClass: 'th-id', summaryFunc: () => null },
      //{ prop: 'offer', name: 'Offer id', maxWidth: 100, summaryFunc: () => null },
      {
        prop: 'complete', name: 'Complete', minWidth: 90, width: 90, maxWidth: 90,
        summaryFunc: () => null,
        cellTemplate: this.advanceTemplate,
        sortable: true,
        cellClass: 'td-complete', headerClass: 'th-complete',
      }, {
        prop: 'invoiced', name: 'Invoiced', minWidth: 90, width: 90, maxWidth: 90,
        summaryFunc: () => null,
        cellTemplate: this.invoicedTemplate,
        sortable: true,
        cellClass: 'td-invoiced', headerClass: 'th-invoiced',
      },
      { prop: 'qty', sortable: true, resizable: true, minWidth: 85, width: 85, maxWidth: 85, frozenLeft: false, summaryFunc: () => null },

      //{ prop: 'udm', sortable: true, resizable: true, width: 50, minWidth: 50, maxWidth: 50, summaryFunc: () => null },
      { prop: 'width', sortable: true, resizable: true, width: 100, maxWidth: 100, minWidth: 100, summaryFunc: () => null },
      { prop: 'length', sortable: true, resizable: true, width: 100, maxWidth: 100, summaryFunc: () => null },
      { prop: 'height', sortable: true, resizable: true, width: 100, maxWidth: 100, summaryFunc: () => null },
      { prop: 'weight', sortable: true, resizable: true, width: 100, maxWidth: 100, minWidth: 100, summaryFunc: () => null },
      //{ prop: 'description', sortable: true, resizable: true, width: 550, maxWidth: 550, minWidth: 550, summaryFunc: () => null },
      //{ prop: 'created', sortable: true, resizable: true, width: 250, maxWidth: 250, minWidth: 250, summaryFunc: () => null },
      //{ prop: 'deleted', sortable: true, resizable: true, width: 75, maxWidth: 75, minWidth: 75, summaryFunc: () => null },

      //{ prop: 'empty', sortable: false, resizable: false, width: 145, maxWidth: 145, minWidth: 145, summaryFunc: () => null },

      {
        prop: 'cost', name: 'Cost', minWidth: 190, width: 190, maxWidth: 190,
        summaryFunc: () => null,
        cellTemplate: this.costTemplate,
        summaryTemplate: this.unitSummaryTemplate,
        sortable: true,
        cellClass: 'td-cost', headerClass: 'th-cost'
      },
      /*  {
         prop: 'tax', name: 'Tax', minWidth: 50, width: 50, maxWidth: 50,
         summaryFunc: () => null,
         cellTemplate: this.cellTax,
         sortable: true,
         cellClass: 'td-tax', headerClass: 'th-tax',
       }, */
      {
        prop: 'cost', name: 'Cost Unit',
        sortable: true,
        minWidth: 160, width: 160, maxWidth: 160,
        summaryFunc: () => null,
        cellTemplate: this.costUnityTemplate,
        //summaryTemplate: this.costSummaryTemplate,
        cellClass: 'td-cost_unit', headerClass: 'th-cost_unit',
      },
      {
        prop: 'cost', name: "Discount Cost", minWidth: 190, maxWidth: 190, width: 190,
        summaryFunc: () => null,
        cellTemplate: this.costDiscountTemplate,
        summaryTemplate: this.totalSummaryTemplate,
        sortable: true,
        cellClass: 'td-cost_discount', headerClass: 'th-cost_discount',
      },
    ]



    this.columns_legal = [
      { prop: 'id', name: 'ID', frozenLeft: true, width: 10, maxWidth: 20, cellClass: 'td-id', headerClass: 'th-id', summaryFunc: () => null },
      { prop: 'sequence', width: 20, maxWidth: 20, },
      { prop: 'title', name: 'Title', width: 200, maxWidth: 300, frozenLeft: false },
      /* { prop: 'active', maxWidth: 100 }, */
      { prop: 'content', width: 800 },

      /* { prop: 'created', maxWidth: 250 },
      { prop: 'deleted', width: 75, maxWidth: 75 }, */

    ]
  }

  ionViewWillEnter() {
    console.log('ionViewWillEnter', this.id, this.nav);
    this.selectedFilterLinesRows = []
    this.invoiceApi.getLinesTax(true).subscribe(e => {
      this.listTaxes = e.results
    })

    this.hidden = false;
    //this.ListFileComponent
    //this.dataService.getProjectById(parseInt(id, 10))
    this.offerApi.getOfferById(parseInt(this.id, 10), '')
      .subscribe(e => {
        // if the project doesn't exists, return to home page
        if (!e) {
          //this.router.navigate(['/offer-list']);
        } else {

          this.offer = e;
          console.log(e);

          this.offer_lines = this.offer.offer_lines
          this.offer_legals = this.offer.offer_legals

          //map address legal customer
          console.log(e);
          console.log(e.customer);
          console.log(this.offer.customer);


          this.offer.customer._legal = this.getAddressLegal(this.offer.customer)
          this.offer.vendor.address_legal = this.getAddressLegal(this.offer.vendor)

          this.offer_legals.map(e => {
            console.log(e);
            if (e.active)
              e.isSelected = true
            this.selectedLinesRows.push(e)
          })

          this.caclulateSumm();
          this.caclulateSummDiscount();
          this.getMetaOffer();
          this.getMetaInvoice();


          /* if (this.UploadDesktopComponent && e.folders.length && e.folders[0].folder.id != undefined) {
            this.UploadDesktopComponent.passItem(e);
          } else {
            
          } */


        }
      });

  }

  ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribe((e) => {
      console.log(e);
      this.dismissModal()
    });

    // !! Await for load template Reference and cretea columns table
    this.createcolumns();



    this.response = '';
    this.selectedLegalsRows = [];
    this.selectedLinesRows = [];

    
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  getMetaOffer() {

    
  }

  getMetaInvoice() {
    this.invoiceApi.getListType()
      .subscribe(e => {
        console.log(e);

        // if the project doesn't exists, return to home page
        if (!e) {
          //this.router.navigate(['/offer-list']);
        } else {
          this.listTypesInvoice = e


        }
      });
  }


  getDocuments(id_folder) {
    let url = '?page=' + this.page_number + '&items=' + this.page_limit;

    this.api.getFiles(id_folder, url).subscribe(x => {
      this.page_number++
      return x
    })

  }


  fav(): void {
    if (this.favState) {
      // this.favOnAnimation.stop();
      this.favOffAnimation.play();
    } else {
      // this.favOffAnimation.stop();
      this.favOnAnimation.play();
    }

    this.favState = !this.favState;
  }

  onChangeSelectAcc(event) {
    console.log(event);
  }

  onChangeDiscount(discount, event, row) {
    console.log(discount, event, row);
  }


  async openNewOfferModal() {
    const modal = await this.modalController.create({
      component: NewOfferComponent,
      canDismiss: true,
      componentProps: {
        title: 'Add New Offer',
        parent_type: 'root'
      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      if (e.data && e.data.status != -1) {
        /* this.page_number = 1;
        this.myInput.value = "";
        this.offers = [];
        this.getOffers(false, '') */
      }
    });
    return await modal.present();
  }


  fabCallBack(action) {
    console.log(action);

    if (action) {
      this[action]()
    } else {
      console.log('not call in component, run callback', 'details');


    }
  }


  removeDuplicate(types) {
    if (types && types.lenght) {
      const names = types.map(o => o.name)
      const filtered = types.filter(({ name }, index) => !names.includes(name, index + 1))
      return filtered
    }
  }


  filterAddress(address, event) {

    if (event) {
      console.log(address, event);
      this.filteredAddress = address.filter(function (type) {
        return type.name == event.detail.value;
      });
    }

  }



  editLine(type_offer_meta, type_offer_form, event) {

    console.log(type_offer_meta);
    if (type_offer_meta == 'line') {
      this.modalOfferCreateUpdate(type_offer_meta, type_offer_form, event/* .row */)
    } else if (type_offer_meta == 'legal') {
      console.log('new legals', event);
      if (type_offer_form == 'create')
        this.modalLegalCreateUpdate(type_offer_meta, type_offer_form, event)

    }

    /* if (event.type == 'mouseenter' || event.type == 'mouseleave')
      return */

    console.log(event);
    ////nav: this.nav(OfferDetailsPage, { id: event.detail }, { animated: true })

  }

  async modalOfferEdit() {
    const modal = await this.modalController.create({
      component: OfferUpdatePage,
      canDismiss: true,
      mode: 'md',
      //cssClass: 'fullscreen',
      componentProps: {
        //title: 'Add New Offer',
        id: this.id,
        //name: this.project.name,

      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      console.log('OfferUpdatePage to OfferDetailsPage', e);

      if (!e.data) {
        console.log('no modify');
        return
      } else {
        this.offerApi.getOfferById(parseInt(this.id, 10), '')
          .subscribe(offer => {
            console.log(offer, e.data);

            // if the project doesn't exists, return to home page
            if (!offer) {
              //this.router.navigate(['/offer-list']);
            } else {
              this.offer = offer;
              this.response = e.data;

              /* if (this.UploadDesktopComponent && e.folders.length && e.folders[0].folder.id != undefined) {
                this.UploadDesktopComponent.passItem(e);
              } else {
                
              } */

            }
          });
      }
    });
    return await modal.present();
  }


  /**
   * 
   * @param type_offer_meta Lines or Legals
   * @param row 
   * @returns 
   */

  async modalOfferCreateUpdate(type_offer_meta, type_offer_form, row) {
    console.log('modalOfferCreateUpdate');
    
/*     const modal = await this.modalController.create({
      component: ModalOfferCreateUpdateComponent,
      canDismiss: true,
      mode: 'md',
      componentProps: {
        //title: 'Add New Offer',
        type_form: type_offer_form,
        type_meta: type_offer_meta, // if event.value
        offer: this.offer,
        data: row
        //name: this.project.name,

      }
    })


    modal.onWillDismiss().then((e) => {
      console.log(e);
      if (!e.data) {
        console.log('no modify');
        return
      } else {
        this.ionViewWillEnter();
        this.response = e.data;
      }
    });
    
    return await modal.present(); */
  }


  dismissModal(event?) {
    this.hidden = true
    this.modalController.dismiss(this.response)
  }

  emptySumm() {
    return null;
  }

  // Calcola la riga sommaria
  caclulateSumm() {
    this.total_offer = 0;

    this.offer_lines.map((item, i) => {
      this.total_offer += item.cost * item.qty;
      console.log(item.cost, item.qty, this.total_offer);

    });
  }

  caclulateSummDiscount() {

    this.diff_discount_offer = 0;

    this.offer_lines.forEach((row) => {
      let val = row.cost;
      let approved = row.discounts.filter(e => e.approved);
    
      console.log(approved, approved.length);
      console.log(row, val);
    
      if (approved.length === 0 && row.qty !== 0) {
        console.log('val * row.qty', val * row.qty);
        this.diff_discount_offer += Number(val * row.qty);
      } else if (approved.length !== 0 && row.qty !== 0) {
        let discount_value = approved[0].value;
        let discount_symbol = approved[0].discount_type;
    
        switch (discount_symbol) {
          case 'P':
            val = this.calcPercent(row.cost * row.qty, discount_value);
            break;
    
          case 'S':
            val = this.calcSubtraction(row.qty, row.cost, discount_value);
            break;
    
          // Gestisci altri casi come necessario
          case 'D':
          default:
            break;
        }
    
        if (val >= 0) {
          this.diff_discount_offer += Number(val);
        }
      }
      // Non è necessario gestire il caso `else if (approved.length == 0 && row.qty == 0)` 
      // se non comporta operazioni specifiche, dato che non modifica lo stato o il risultato.
    });
    


  }

  // Calcola lo sconto sulla linea percent
  calcPercent(val, percentage) {
    //console.log('calcPercent', val, percentage);

    let result = val * (percentage / 100);
    //console.log(result);

    return val - result;
  }

  // Calcola lo sconto sulla linea fixed
  calcSubtraction(val, qty, discount_value) {
    //console.log('calcSubtraction', val, qty, discount_value);

    let tot = val * qty;

    //console.log('tot, diff', tot, diff);

    return tot - discount_value
  }

  // Calcola lo sconto sulla linea a seconda del tipo
  calculateSingleDiscount(val, row) {

    let approved = row.discounts.filter(e => e.approved)
    //console.log('calculateSingleDiscount approved', approved, val, row);

    if (approved.length == 0 || row.qty != 0) {
      return val * row.qty
    } else if (approved.length == 0 || row.qty == 0) {
      return 0
    }

    let discount_value = approved[0].value
    let discount_symbol = approved[0].discount_type

    //console.log('approved2', val);

    switch (discount_symbol) {
      case 'P':
        val = this.calcPercent(val * row.qty, discount_value)
        break;

      case 'S':
        val = this.calcSubtraction(val, row.qty, discount_value)
        break;

      case 'D':

        break;

      default:
        break;
    }

    return val >= 0 ? val : 0
  }

  // Calcola lo sconto sulla linea a seconda del tipo
  calculateSingleDiff(val, row) {

    let approved = row.discounts.filter(e => e.approved)
    //console.log('calculateSingleDiff approved', approved, val, row);

    if (approved.length == 0 && row.qty != 0) {
      return val * row.qty
    } else if (approved.length == 0 && row.qty == 0) {
      return 0
    } else if (approved.length != 0 && row.qty != 0) {

      let discount_value = approved[0].value
      let discount_symbol = approved[0].discount_type

      //console.log('approved2', val); 

      switch (discount_symbol) {
        case 'P':
          //console.log(val, row.qty, discount_value);
          val = this.calcPercent(val * row.qty, discount_value)
          //console.log(val);

          break;

        case 'S':
          //console.log(val, row.qty, discount_value);
          val = this.calcSubtraction(val, row.qty, discount_value)
          //console.log(val);
          break;

        case 'D':

          break;

        default:
          break;
      }

      return val >= 0 ? val : 0
    }
  }


  compareStatusOfferOfstatusListOffer() {
    let id = this.offer.status.id

    let current = this.listStatus.filter(function (o1) {
      return o1.id == id; // return the ones with equal id
    });

    console.log(current);


    if (current)
      this.availableChangeStatusOffer = current[0].cons

  }

  updateStatus(string, status) {
    this.showProgressStatus = true;
    const updatedOfferStatus: any = { status_id: status.id };
    console.log(updatedOfferStatus, string);



    setTimeout(() => {
      this.showProgressStatus = false;

    }, 7000);
  }


  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color
    });
    return await toast.present();
  }

  onSelect(selectedRows, type) {
    console.log('Select Event', selectedRows);
    if (!selectedRows.selected)
      return

    if (type == 'line') {
      this.selectedLinesRows.splice(0, this.selectedLinesRows.length);
      this.selectedLinesRows.push(...selectedRows.selected);
      this.selectedFilterLinesRows = [...selectedRows.selected]
    } else if (type == 'legal') {
      /*  this.selectedLegalsRows.splice(0, this.selectedLegalsRows.length);
       this.selectedLegalsRows.push(...selectedRows.selected);
       this.selectedFilterLegalsRows = this.getAllstatusadvanceLine(this.selectedLegalsRows); */
    }

    console.log(this.selectedFilterLinesRows);


  }

  getAllstatusadvanceLine(lines) {
    let array = [];
    lines.map(e => {
      this.invoiceApi.getStatusAdvance(e).subscribe(e => {
        console.log(e);
        array.push(e)
      })
    })
    return array;
  }


  addLineToInvoice(type, event) {


    const newInvoice = {
      type_id: '',
      vendor_id: this.offer.vendor.id,
      customer_id: this.offer.customer.id,
      lines: this.lineAdvanceToInvoice
    }

    console.log(event, newInvoice);

    this.showPromptSelectTypeInvoice(newInvoice);

  }

  showPromptSelectTypeInvoice(newInvoice) {

    let inputs = [];
    this.listTypesInvoice.map(e => {
      console.log(e);

      let item = {
        type: 'radio',
        label: e.name,
        value: e.id
      }
      inputs.push(item)
    })

    this.alertController.create({
      header: 'TYPE INVOICE',
      message: 'Select status of invoice',
      inputs: inputs,
      buttons: [
        {
          text: 'Cancel',
          handler: (data: any) => {
            console.log('Canceled', data);
          }
        },
        {
          text: 'Create',
          handler: (data: any) => {
            console.log('Saved Information', data);

            newInvoice.type_id = data;
            console.log(newInvoice);

            this.invoiceApi.createInvoice(newInvoice).subscribe(ele => {
              console.log(ele);
              if (!ele.data)
                return


              ele.data.lines.map(d => {
                //console.log(d, this.offer_lines);


                this.offer_lines.map((e, i, a) => {
                  //console.log(e.complete.details.filter(adv => adv.id === d.id));

                  let lines = e.complete.details.filter(adv => adv.id !== d.id);
                  e.complete.details = lines
                })


                this.selectedFilterLinesRows.map((e, i, a) => {
                  //console.log(e.complete.details.filter(adv => adv.id === d.id));

                  let lines = e.complete.details.filter(adv => adv.id !== d.id);
                  e.complete.details = lines
                })


              })
              this.offer_lines = [...this.offer_lines]

            })
          }

        }
      ]
    }).then(res => {
      res.present();
    });
  }




  changeAdvanceToInvoice(advance_id, checked) {
    console.log(advance_id, checked.checked);
    console.log(this.prepareLinesOfferToInvoice);

    if (checked.checked)
      this.prepareLinesOfferToInvoice.push(advance_id)
    else if (!checked.checked)
      this.prepareLinesOfferToInvoice = this.prepareLinesOfferToInvoice.filter(e => e != advance_id);
    else
      console.log('else');


    this.lineAdvanceToInvoice = this.prepareLinesOfferToInvoice;
    console.log(this.lineAdvanceToInvoice);

  }

  onActivate(event) {
    console.log('Activate Event', event);

    if (event.type == 'click') {
      this.modalLegalCreateUpdate('legal', 'update', event)
    }



  }

  /**
    * 
    * @param type create, update
    * @param row 
    * @returns 
   */

  async modalLegalCreateUpdate(type_offer_meta, type_offer_form, r) {
    console.log('ModalOfferLegalComponent',type_offer_meta, type_offer_form, r);

/* 
    const modal = await this.modalController.create({
      component: ModalOfferLegalComponent,
      canDismiss: true,
      mode: 'md',
      componentProps: {
        data: r.row,
        offer_id: this.offer.id,
        type: type_offer_meta,
        form: type_offer_form
      }
    })


    modal.onWillDismiss().then((e) => {
      console.log(e);
      if (!e.data) {
        console.log('no modify');
        return
      } else {
        this.ionViewWillEnter();
        this.response = e.data;
      }
    });
    return await modal.present(); */
  }

  /**
   * 
   * @param type create, update
   * @param row 
   * @returns 
  */

  remove() {
    this.selectedLegalsRows = [];
  }

  displayCheck(row) {
    return row.active !== false;
  }

  goToInvoices(event) {

    this.modalController.dismiss(this.response).then(() => {
      //nav: this.nav(InvoicesListPage, { offer_id: this.offer.id }, { animated: true, direction: 'forward' })
    })
  }

  async openPopoverDiscounts(discount, event, row) {
    console.log(this.offer.discounts);
    console.log(discount);
    console.log(event);
    console.log(row);

   /*  const popover = await this.popoverController.create({
      component: DiscountLineComponent,
      //event: event,
      backdropDismiss: true,
      cssClass: 'popover-setting',
      componentProps: {
        offer: this.offer,
        discount: discount,
        event: event,
        line: row,
        type: 'line'
      },
      translucent: true
    });

    popover.onDidDismiss().then((e) => {
      console.log(e);
      if (!e.data || e.data.res != 'OK' || e.role == 'backdrop') {
        console.log('no modify');
        return
      } else {

        console.log(e.data.data);
        this.ionViewWillEnter()


      }
    });

    return await popover.present(); */
    /** Sync event from popover component */

  }


  getApprovedDiscount(discounts) {
    //console.log(discounts);
    if (discounts.length == 0)
      return 'no'

    let approved = discounts.filter(e => e.approved)
    //console.log(approved);
    if (approved.length == 0)
      return 'no'

    let value = approved[0].discount_type == 'S' ? (approved[0].value).toFixed(2) : approved[0].value;
    let symbol = approved[0].discount_type == 'P' ? '%' : approved[0].discount_type == 'S' ? ' ' : approved[0].discount_type;

    return value + ' ' + symbol
  }


  async openPopoverDiscountsToolbar(event) {
    console.log(this.offer.discounts);
    console.log(event);

    /* const popover = await this.popoverController.create({
      component: DiscountLineComponent,
      //event: event,
      backdropDismiss: true,
      cssClass: 'popover-setting',
      componentProps: {
        offer: this.offer,
        event: event,
        type: 'global'
      },
      translucent: true
    });

    popover.onDidDismiss().then((e) => {
      console.log(e);
      if (!e.data || e.data.res != 'OK' || e.role == 'backdrop') {
        console.log('no modify');
        return
      } else {

        console.log(e.data.data);
        this.ionViewWillEnter()


      }
    });

    return await popover.present(); */
    /** Sync event from popover component */

  }


  async openPopoverAdvanceLine(a, b, c, event) {
    console.log(a, b, c);

    /* const popover = await this.popoverController.create({
      component: InvoiceLineComponent,
      //event: event,
      backdropDismiss: true,
      cssClass: 'popover-setting',
      componentProps: {
        //event: event,
        offer: this.offer,
        line: c,
        type: a
      },
      translucent: true
    });

    popover.onDidDismiss().then((e) => {
      console.log(e);
      if (!e.data) {
        console.log('no modify');
        return
      } else {

        console.log(e.data, this.offer_lines);

        let offerLinesIndex = this.offer_lines.map((e, i, a) => e.id).indexOf(c.id)
        console.log(offerLinesIndex);

        this.offer_lines[offerLinesIndex].complete.value = this.offer_lines[offerLinesIndex].complete.value + e.data.value
        this.offer_lines[offerLinesIndex].complete.type = e.data.advance_type

        if (this.offer_lines[offerLinesIndex].complete.details)
          this.offer_lines[offerLinesIndex].complete.details.push(e.data)

        // if product on response
        this.offer_lines = [...this.offer_lines];
        console.log(this.offer_lines);


      }
    });

    return await popover.present(); */
    /** Sync event from popover component */
  }



  updateValue(event, cell, rowIndex) {
    console.log('inline editing_local_id_cell rowIndex', rowIndex);
    console.log(this.editing_local_id_cell);
    console.log(this.editing_local_id_cell[rowIndex + '-' + cell]);
    console.log(this.offer_lines[rowIndex][cell]);

    this.editing_local_id_cell[rowIndex + '-' + cell] = false;
    this.offer_lines[rowIndex][cell] = event.target.value;
    this.offer_lines = [...this.offer_lines];
    console.log(this.offer_lines[rowIndex].id, this.offer_lines[rowIndex][cell]);

    let form = {
      id: this.offer_lines[rowIndex].id,
      local_id: Number(this.offer_lines[rowIndex][cell])
    }
    
  }


  checkCompleteAdvanceLine(line) {
    if (!line.complete)
      return line

    if (line.complete.value == 100 && line.complete.type == 'P' || line.complete.value == line.qty && line.complete.type == 'N')
      return true
      else
      return ''
  }

  concatInput(string) {

    setTimeout(() => {
      this.inputEl.setFocus()
    }, 50);

    return string = true
  }


  async presentActionSheetTaxes(item, ev: Event) {
    let buttons = [];
    console.log(item);

    this.listTaxes.map(e => {

      buttons.push({
        text: e.name,
        //icon: 'thermometer-outline'
        handler: () => {
          console.log(e, ' clicked');
          item.clicked = false
          item.tax = { id: e.id, name: e.name, code: e.code }
          this.invoiceApi.putAdvance(item, { tax_id: e.id }).subscribe(e => {
            console.log(e);

          })
        }
      })
    })

    buttons.push({
      text: 'Cancel',
      icon: 'close',
      role: 'cancel',
      handler: () => {
        console.log('Cancel clicked');
        item.clicked = false
      }
    })

    let actionSheet = await this.actionSheetCtrl.create({
      header: 'Tax lines',
      cssClass: 'my-custom-class',
      buttons: buttons
    });

    await actionSheet.present();

    const { role } = await actionSheet.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
    console.log(this.offer_lines);

  }



  changeTax(index, event) {
    console.log(index, event.detail.value);
    this.offer_lines[index].tax = event.detail.value;

    console.log(this.offer_lines[index]);

  }
/*   print_reomte() {
    this.offerApi.getOffersPrint(this.offer).subscribe((data) => {
      var fileName = 'test.pdf';
      let blob: any = new Blob([data], { type: 'application/pdf; charset=utf-8' });
      console.log(blob);
      var fileURL = window.URL.createObjectURL(blob);
      window.open(fileURL);


      //importedSaveAs(blob, fileName)
    })
  } */

  parseString(string) {
    console.log(string);

    //string = string.replace(/'/g, '"')
    try {
      return JSON.parse(string)
    } catch (error) {
      console.log(error);

    }
  }

  async print() {
    // Increcementa Y verso il basso
    // Incrementa X verso destra


    var doc = new jsPDF('p', 'mm', 'a4');
    console.log(doc);

    var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    let id,
      code,
      vendor,
      customer,
      type;


    let headers_lines = [];
    let headers_legals = [];
    let cell_lines = [];
    let cell_legals = [];


    var totalPagesExp = '{total_pages_count_string}'


    headers_lines = ['#', 'name', 'description' , 'qty', 'cost', 'discount', 'total']
    headers_legals = ['#', 'name', 'description']

    vendor = this.offer.vendor
    customer = this.offer.customer



    console.log(this.offer_lines, this.offer_lines.length, this.offer_legals, this.offer_legals.length);

    if (this.offer_lines.length > 0) {
      this.offer_lines.map(e => {
        cell_lines.push([e.local_id, e.product.name, e.product.description, e.qty, (e.cost).toFixed(2) + '€', 0, (this.calculateSingleDiscount(e.cost, e)).toFixed(2) + '€'])
      })
      console.log(cell_lines);

    }

    if (this.offer_legals.length > 0) {
      let i = 1;
      this.offer_legals.map(e => {
        e.id = i++
        cell_legals.push([e.id, e.title, e.content])
      })
    }



    let creted_at = "Data creazione: " + this._datePipe.transform(Date.now(), 'dd/MM/yyyy HH:mm:ss');
    let creator = "Creatore: "// + this.user.datas[0].firstname + ' ' + this.user.datas[0].lastname;
    //let total = this.offer_lines.reduce((sum, el) => sum + el.id, 0);

    console.log(cell_lines, cell_legals);


    doc.setDrawColor('#ccc');

    /**
     * 
     * Set x - y
     * 
    */
    let x_def = 10
    let y_def = 10

    let x_half = pageWidth / 2
    let x_div_three = pageWidth / 3
    let x_starLine =  x_def 
    let x_endLine = pageWidth - x_def
    let x = x_def
    let y = y_def
    //Set contorno grigio
    doc.roundedRect(x, y, 191, 276, 1, 1, 'S')

    /**
     * LOGO + NUMBER PROJECT
     * 
    */
    // Logo + Copyright  
    doc.setDrawColor('#000');
    doc.addImage("assets/img/logo.png", "PNG", x + 5, 12, 50, 11);
    doc.setTextColor('#959595');    
    doc.setFontSize(6);
    var year = moment().format('YYYY');
    doc.text("Revolution software web application - Copyright 1987 - " + year, 19, 26)


    //Set Number Project
    doc.setFillColor('#032c5d')    
    doc.setTextColor('#fff');
    x = x_half + 1   
    y = y + 1
    let w =  94
    let h = 16
    doc.roundedRect(x, y, w, h, 1, 1, 'F')    
    doc.setFontSize(10)
    y = y + 5  
    x = x + 3  
    doc.text('PROJECT: ' , x , y + 1 )
    doc.text('IT.CTH.00154.CPJ.CNS.003.COE.001', x + 28, y + 8);
    doc.setFontSize(13);
    doc.setFont(undefined, 'bold', undefined);      

    x = x_def 
    y = y + 12  
    doc.setDrawColor('#ccc');
    doc.line(x_starLine, y, x_endLine, y);




    
   
    /**
     * VENDOR & CUSTOMER
     * 
    */    

    // VENDOR
    /** GET VENDOR  */

    let vendor_street_legal = this.offer.vendor.address_legal;//this.parseString(vendor.address).position.street + ', ' + this.parseString(vendor.address[0]).position.number
    let vendor_address_legal = ''//this.parseString(vendor.address[0]).position.cap + ' - ' + this.parseString(vendor.address[0]).position.city + ' - (' + this.parseString(vendor.address[0]).position.province + ')'

    let vendor_contacts = vendor.contact[0].data
    let vendor_email = vendor.contact[1].data

    doc.setFont(undefined, 'bold', undefined);
    doc.setFontSize(8);
    doc.setTextColor('#032c5d');    
    x = x_def + 5
    y = y + 7    
    doc.text("Impresa:", x, y);
    doc.setDrawColor('#032c5d');
    let textWidth = doc.getTextWidth("Impresa:");    
    doc.line(x, y + 1, x + textWidth  , y + 1);
    
    doc.setFontSize(9);
    doc.setTextColor('#337ab7');    
    x = x + 3
    y = y + 6    
    doc.text(vendor.name, x, y, { align: 'left' });
    y = y + 4
    doc.text(vendor_street_legal, x, y, { align: 'left' });    
    //-------------------------
    x = 18
    y = y + 4    
    doc.text("P.IVA: ", x, y, { align: 'left' });
    x = x + 10
    doc.text(vendor.code_vat, x, y, { align: 'left' });
    //-------------------------
    x = 18
    y = y + 4    
    doc.text("C.F: ", x, y, { align: 'left' });
    x = x + 10
    doc.text(vendor.code_fiscal, x, y, { align: 'left' });
    //-------------------------
    x = 18
    y = y + 4
    doc.text("SDI: ", x, y, { align: 'left' });
    x = x + 10
    doc.text(vendor.code_unique, x, y, { align: 'left' });
    //-------------------------
    x = 18
    y = y + 4    
    doc.text("IBAN: ", x, y, { align: 'left' });
    x = x + 10
    doc.text(vendor.code_iban, x, y, { align: 'left' });  
    

    doc.setFont(undefined, 'normal', undefined);
    doc.setFontSize(8);
    doc.setTextColor('#000');

    //----------------------------
    x = 18
    y = y + 4    
    doc.text("Email: ", x, y, { align: 'left' });
    x = x + 8
    doc.text(vendor_email, x, y, { align: 'left' });
    //----------------------------
    x = 18
    y = y + 4    
    doc.text("PEC: ", x, y, { align: 'left' });
    x = x + 8
    doc.text(vendor_email, x, y, { align: 'left' });
    //----------------------------
    x = 18
    y = y + 4    
    doc.text("Tel: ", x, y, { align: 'left' });
    x = x + 5
    doc.text(vendor_contacts, x, y, { align: 'left' });




    // CUSTOMER
    
    let customer_street_legal = this.offer.customer._legal;//this.parseString(vendor.address).position.street + ', ' + this.parseString(vendor.address[0]).position.number
    let customer_address_legal = ''//this.parseString(vendor.address[0]).position.cap + ' - ' + this.parseString(vendor.address[0]).position.city + ' - (' + this.parseString(vendor.address[0]).position.province + ')'

    let customer_contacts = customer.contact[0].data
    let customer_email = customer.contact[1].data

    doc.setFont(undefined, 'bold', undefined);
    doc.setFontSize(8);
    doc.setTextColor('#032c5d');    
    x = x_def + 98
    y = y_def + 25
    doc.text("Cliente:", x, y);
    doc.setDrawColor('#032c5d');
    let textWidth_c = doc.getTextWidth("Cliente:");    
    doc.line(x, y + 1, x + textWidth_c  , y + 1);
    let x_res = x_def + 103
    doc.setFontSize(9);
    doc.setTextColor('#337ab7');    
    x = x_res
    y = y + 6    
    doc.text(customer.name, x, y);
    y = y + 4
    doc.text(customer_street_legal, x, y);    
    //-------------------------
    x = x_res
    y = y + 4    
    doc.text("P.IVA: ", x, y);
    x = x + 10
    doc.text(customer.code_vat, x, y);
    //-------------------------
    x = x_res
    y = y + 4    
    doc.text("C.F: ", x, y);
    x = x + 10
    doc.text(customer.code_fiscal, x, y);
    //-------------------------
    x =x_res
    y = y + 4
    doc.text("SDI: ", x, y);
    x = x + 10
    doc.text(customer.code_unique, x, y);
    //-------------------------
    x = x_res
    y = y + 4    
    doc.text("IBAN: ", x, y);
    x = x + 10
    doc.text(customer.code_iban, x, y);  
    

    doc.setFont(undefined, 'normal', undefined);
    doc.setFontSize(8);
    doc.setTextColor('#000');

    //----------------------------
    x = x_res
    y = y + 4    
    doc.text("Email: ", x, y, { align: 'left' });
    x = x + 8
    doc.text(customer_email, x, y, { align: 'left' });
    //----------------------------
    x = x_res
    y = y + 4    
    doc.text("PEC: ", x, y, { align: 'left' });
    x = x + 8
    doc.text(customer_email, x, y, { align: 'left' });
    //----------------------------
    x = x_res
    y = y + 4    
    doc.text("Tel: ", x, y, { align: 'left' });
    x = x + 5
    doc.text(customer_contacts, x, y, { align: 'left' });

    /**
     * 
     * 
     *  Number Offerta  linea header 
     * 
     * 
     * */
    
    //doc.setFillColor('#032c5d')    
    doc.setFillColor('#286fb3') 
    doc.setTextColor('#fff');
    x = x_def + 2   
    y = y + 4
    w =  187
    h = 7
    doc.roundedRect(x, y, w, h, 1, 1, 'F')    
    doc.setFontSize(10)
    doc.setFont(undefined, 'bold', undefined); 
    let obj = "OFFER";
    doc.text(obj + ': IT.CTH.00154.CPJ.CNS.003.COE.001', x + 2, y + 5);
    doc.setFontSize(13);
    
  


    // DETAILS  


    x = x_def + 4
    y = y + 15
    doc.setDrawColor('#000');
    doc.setFontSize(10);
    doc.setTextColor('#337ab7');
    doc.setFont(undefined, 'bold', undefined);
    doc.text("TIPOLOGIA OFFERTA:", x, y);
    doc.setTextColor('#000');
    doc.setFont(undefined, 'normal', undefined);
    x = x + 45
    doc.text(this.offer.type.name, x, y);
    doc.setDrawColor('#ccc');
    y = y + 2
    doc.line(x_starLine, y , x_endLine, y );
    // --------------------------------------

    x = x_def + 4
    y = y + 5
    doc.setDrawColor('#000');
    doc.setFontSize(10);
    doc.setTextColor('#337ab7');
    doc.setFont(undefined, 'bold', undefined);
    doc.text("DATA DI EMISSIONE:", x, y);
    doc.setTextColor('#000');
    doc.setFont(undefined, 'normal', undefined);
    x = x + 45
    doc.text( moment(new Date()).format("DD/MM/YYYY"), x, y);
    doc.setDrawColor('#ccc');
    y = y + 2
    doc.line(x_starLine, y , x_endLine, y );

    // --------------------------------------

    x = x_def + 4
    y = y + 5
    doc.setDrawColor('#000');
    doc.setFontSize(10);
    doc.setTextColor('#337ab7');
    doc.setFont(undefined, 'bold', undefined);
    doc.text("OGGETTO:", x, y);
    doc.setTextColor('#000');
    doc.setFont(undefined, 'normal', undefined);
    x = x + 45
    doc.text('', x, y);
    doc.setDrawColor('#ccc');
    y = y + 2
    doc.line(x_starLine, y , x_endLine, y );

    // --------------------------------------

    x = x_def + 4
    y = y + 5
    doc.setDrawColor('#000');
    doc.setFontSize(10);
    doc.setTextColor('#337ab7');
    doc.setFont(undefined, 'bold', undefined);
    doc.text("DESCRIZIONE:", x, y);
    doc.setTextColor('#000');
    doc.setFont(undefined, 'normal', undefined);
    x = x + 45
    doc.text('', x, y);
    doc.setDrawColor('#ccc');
    y = y + 20
    doc.line(x_starLine, y , x_endLine, y );

    
  
//---------------------------------
//  Linea Products
    doc.setFillColor('#286fb3')    
    doc.setTextColor('#fff');
    x = x_def + 2   
    y = y + 4
    w =  187
    h = 7
    doc.roundedRect(x, y, w, h, 1, 1, 'F')    
    doc.setFontSize(10)
    obj = "PRODUCTS";
    doc.setFont(undefined, 'bold', undefined); 
    doc.text(obj , x + 2, y + 5);
    doc.setFontSize(13);
   


    // INIT LINEE PRODUCT
    y = y + 10
    // Or use javascript directly:
    console.log(cell_lines);
    let row_custom = ['','','','','','Total', '100']
    cell_lines.push()
    
    autoTable(doc, {
      theme: 'striped',
      bodyStyles: { fontSize: 8, fontStyle : 'normal', halign: "left" },
      startY: y,
      styles: {        
        lineWidth: 0,        
        textColor: 0,
        halign: "right",
    },
      headStyles: {
        fillColor: '#dadada',
        textColor: '#000',
        fontSize: 9,       
        halign: "center",
        valign: "middle",
        //lineColor: Color;
      },
      head: [headers_lines],
      body: cell_lines,
      didParseCell: function (data) {
        var rows = data.table.body;        
        /* if (data.row.index === rows.length - 1) {
          data.cell.styles.fillColor = [239, 154, 154];
        } */
      },
      didDrawCell: function(data) {
        if ((data.row.section === 'head' || data.row.section === 'foot') && data.column.dataKey === "expenses") {
            doc.addImage('', 'PNG', data.cell.x + 5, data.cell.y + 2, 5, 5);
        }
    },
      didDrawPage: function (data) {

        console.log(data, data.table, data.table.pageNumber);

        // Footer
        var str = "Pagina " + doc.getNumberOfPages();

        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.right, pageHeight - 14);
      }
    })


   


    /**
     * 
     * 
     *  Add line LEGALS 
     * 
     * 
     * */
    
    //doc.setFillColor('#032c5d')    
    doc.setFillColor('#286fb3') 
    doc.setTextColor('#fff');
    x = x_def + 2   
    let final = (doc as any).lastAutoTable.finalY
    console.log(final + y, final);
    console.log(y);
    y = final + 10
    
    //y = y + final
    w =  187
    h = 7
    doc.roundedRect(x, y, w, h, 1, 1, 'F')    
    doc.setFontSize(10)
    doc.setFont(undefined, 'bold', undefined); 
    obj = "LEGALS";
    doc.text(obj, x + 2, y + 5);
  

    //y = y + (doc as any).lastAutoTable.finalY;
    y = y + 15
    // Or use javascript directly:
    autoTable(doc, {
      theme: 'striped',
      bodyStyles: { fontSize: 8, fontStyle: 'italic' },
      startY: y + 15,
      headStyles: {
        //fillColor: '#000',
        fontSize: 10,
        textColor: '#fff',
        halign: "left",
        valign: "middle",
        //lineColor: Color;
      },
      head: [headers_legals],
      body: cell_legals,
      didParseCell: function (data) {
        var rows = data.table.body;
        /* if (data.row.index === rows.length - 1) {
          data.cell.styles.fillColor = [239, 154, 154];
        } */
      },

      didDrawPage: function (data) {

        console.log(data, data.table, data.table.pageNumber);

        // Footer
        var str = "Pagina " + doc.getNumberOfPages();

        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.right, pageHeight - 14);
      }
    })

    //await doc.save('Test.pdf');
    doc.output('dataurlnewwindow'); //to check pdf generate


  }



  async export_lines() {

    var doc = new jsPDF();

    let headers = [];
    let cell = [];
    var totalPagesExp = '{total_pages_count_string}'

    //this.columns_line.map(e => {
    //  headers.push(e.name)
    //})
    headers = ['id', 'local_id', 'complete', 'invoiced', 'qty', 'cost', 'disount', 'discountCost']


    for (let i = 0; i <= 0; i++) {
      this.offer_lines.map(e => {
        cell.push([e.id, e.local_id, e.complete.value + ' ' + e.complete.type, e.invoiced.value + ' ' + e.invoiced.type, e.qty, (e.cost).toFixed(2) + '€', 0, (this.calculateSingleDiscount(e.cost, e)).toFixed(2) + '€'])
      })
    }


    let creted_at = "Data creazione: " + this._datePipe.transform(Date.now(), 'dd/MM/yyyy HH:mm:ss');
    let creator = "Creatore: "// + this.user.datas[0].firstname + ' ' + this.user.datas[0].lastname;
    let total = this.offer_lines.reduce((sum, el) => sum + el.id, 0);

    console.log(cell);

    cell.push([{
      content: `ID = ${total}`, colSpan: 2,
      styles: { fillColor: [239, 154, 154] }
    }])


    doc.setFontSize(17);
    doc.text("CoolProjects Export data", 67, 17);
    doc.setTextColor(76, 175, 80);
    doc.setFontSize(10);
    doc.text("LISTA", 67, 23)
    doc.setTextColor(0, 0, 0);
    doc.text(creted_at, 67, 27)
    doc.text(creator, 67, 31)
    //doc.setFontSize(40);
    //doc.text("Export lista pratiche", 12, 25);
    doc.addImage("assets/img/logo_w.png", "PNG", 15, 10, 50, 12);

    // Or use javascript directly:
    autoTable(doc, {
      head: [headers],
      body: cell,
      startY: 50,
      headStyles: {
        //fillColor: '#000',
        textColor: '#fff',
        halign: "left",
        valign: "middle",
        //lineColor: Color;
      },
      didParseCell: function (data) {
        var rows = data.table.body;
        if (data.row.index === rows.length - 1) {
          data.cell.styles.fillColor = [239, 154, 154];
        }
      },

      didDrawPage: function (data) {

        console.log(data, data.table, data.table.pageNumber);

        // Footer
        var str = "Pagina " + doc.getNumberOfPages();

        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.right, pageHeight - 10);
      }
    })

    await doc.save('Test.pdf');

  }

  async export_legals() {

    var doc = new jsPDF();

    let headers = [];
    let cell = [];
    var totalPagesExp = '{total_pages_count_string}'

    //this.columns_line.map(e => {
    //  headers.push(e.name)
    //})
    headers = ['id', 'local_id', 'complete', 'invoiced', 'qty', 'cost', 'disount', 'discountCost']


    for (let i = 0; i <= 0; i++) {
      this.offer_lines.map(e => {
        cell.push([e.id, e.local_id, e.complete.value + ' ' + e.complete.type, e.invoiced.value + ' ' + e.invoiced.type, e.qty, (e.cost).toFixed(2) + '€', 0, (this.calculateSingleDiscount(e.cost, e)).toFixed(2) + '€'])
      })
    }


    let creted_at = "Data creazione: " + this._datePipe.transform(Date.now(), 'dd/MM/yyyy HH:mm:ss');
    let creator = "Creatore: "// + this.user.datas[0].firstname + ' ' + this.user.datas[0].lastname;
    let total = this.offer_lines.reduce((sum, el) => sum + el.id, 0);

    console.log(cell);

    cell.push([{
      content: `ID = ${total}`, colSpan: 2,
      styles: { fillColor: [239, 154, 154] }
    }])


    doc.setFontSize(17);
    doc.text("CoolProjects Export data", 67, 17);
    doc.setTextColor(76, 175, 80);
    doc.setFontSize(10);
    doc.text("LISTA", 67, 23)
    doc.setTextColor(0, 0, 0);
    doc.text(creted_at, 67, 27)
    doc.text(creator, 67, 31)
    //doc.setFontSize(40);
    //doc.text("Export lista pratiche", 12, 25);
    doc.addImage("assets/img/logo_w.png", "PNG", 15, 10, 50, 12);

    // Or use javascript directly:
    autoTable(doc, {
      head: [headers],
      body: cell,
      startY: 50,
      headStyles: {
        //fillColor: '#000',
        textColor: '#fff',
        halign: "left",
        valign: "middle",
        //lineColor: Color;
      },
      didParseCell: function (data) {
        var rows = data.table.body;
        if (data.row.index === rows.length - 1) {
          data.cell.styles.fillColor = [239, 154, 154];
        }
      },

      didDrawPage: function (data) {

        console.log(data, data.table, data.table.pageNumber);

        // Footer
        var str = "Pagina " + doc.getNumberOfPages();

        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.right, pageHeight - 10);
      }
    })

    await doc.save('Test.pdf');

  }


  /**
   * Get Address Legal 
   */
  getAddressLegal(e) {
    return e.address.map((addr:any) => {
      //get first address legal
      if (addr.name != 'work') {
        let p = addr.position;
        return p.street + ' ' + p.number + ', ' + p.city + ', ' + p.nation
      }
      else {
        return ''
      }
    })

  }


  /** Change Tab Offer */
  onSetTab(event) {
    console.log(event);
    this.tab_offer = event.detail.value;
    console.log(this.tab_offer);
  }

  getDateExpectedStop(event) {
    console.log(event);
  }


}
