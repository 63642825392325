/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable object-shorthand */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/naming-convention */

import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  map,
  retryWhen,
  scan,
  tap,
  timeout,
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { NetworkService, ConnectionStatus } from '../network/network.service';
//import { Storage } from '@ionic/storage';
import { OfflineManagerService } from '../network/offline-manager.service';

//import environment from '../../../config.json';
import { Project } from 'src/app/projects/models/projects';
import { Docs } from 'src/app/documents/models/docs';
//import { Customer } from 'src/app/customers/models/customer.model';

const API_STORAGE_KEY = 'wokrerservice';
const TOKEN_KEY = 'access-token';

@Injectable({
  providedIn: 'root',
})
export class TasksService {
  private url: string = 'http';
  private token: any;
  private projects: Project[];
  private documents: Docs[];
  private document: Docs;
  private project_types: any[];
  //public docList = new BehaviorSubject(null);

  constructor(
    private storage: Storage,
    private plt: Platform,
    private http: HttpClient,
    public toastController: ToastController,
    private authService: AuthenticationService,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService
  ) {
    /**
     * Map url API
     */
    this.url = environment.SSL.active ? this.url + 's' : this.url;
    this.url +=
      '://' +
      environment.API_URI.url +
      ':' +
      environment.API_URI.port +
      environment.API_URI.path;

    this.authService.currentToken.subscribe((x) => {
      //console.log(x);
      this.token = x; //= JSON.parse(localStorage.getItem(TOKEN_KEY))
    });
  }

  /**
   *
   * Wokrers
   */

  /**
   *
   *
   * @returns list tasks
   */
  getTasksList(params?, url?): Observable<any> {
    params == undefined ? (params = '') : null;
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    // return this.http.get<any>(`${this.url}task/tasks/${params}`).pipe(
      return this.http.get<any>(`${this.url}task/tasks/${url}&job_id=${params}`).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get task list', []))
    );
  }

  /**
   *
   *
   * @returns list tasks
   */
  createTaskResource(form?): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.post<any>(`${this.url}task/taskresources/`, form).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get task list', []))
    );
  }


    /**
   *
   *
   * @returns list tasks
   */
    updateTask(form?): Observable<any> {
      //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
      return this.http.put<any>(`${this.url}task/task/${form.task_id}/`, form).pipe(
        timeout(16000),
        tap((data) => {
          console.log(data);
          return data;
        }),
        catchError(this.handleError<any[]>('Get task list', []))
      );
    }

  /**
   *
   *
   * @returns list resourcestasks
   */
  getResourcesTasksList(params?): Observable<any> {
    params == undefined ? (params = '') : null;
    return this.http.get<any>(`${this.url}task/taskresources/${params}`).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get task list', []))
    );
  }

  /**
   *
   *
   * @returns get timesheet
   */
  getTimesheet(params?:any): Observable<any> {
    params == undefined ? (params = '') : null;
    return (
      this.http
        .get<any>(`${this.url}task/timesheet/${params}/`)
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          map((data) => {
            console.log(data);
            let newData = data.filter((e) => e.task_status.code !== 'END');
            console.log(newData);
            return newData;
          }),
          catchError(this.handleError<any[]>('Get task list', []))
        )
    );
  }

  getJobsByResource(params?:any): Observable<any> {
    params == undefined ? (params = '') : null;
    return (
      this.http
        .get<any>(`${this.url}task/jobs_by_resource/${params}/`)
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          map((data) => {
            console.log(data);
            return data;
          }),
          catchError(this.handleError<any[]>('Get task list', []))
        )
    );
  }

  getTimesheetSearch(params?:any): Observable<any> {
    params == undefined ? (params = '') : null;
    return (
      this.http
        .get<any>(`${this.url}task/timesheet/${params}`)
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          map((data) => {
            console.log(data);
            let newData = data.filter((e) => e.task_status.code !== 'END');
            console.log(newData);
            return newData;
          }),
          catchError(this.handleError<any[]>('Get task list', []))
        )
    );
  }


  /**
   *
   *
   * @returns get timesheet
   */
  getTimesheetEnd(params?): Observable<any> {
    params == undefined ? (params = '') : null;
    return (
      this.http
        .get<any>(`${this.url}task/timesheet/${params}/`)
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          tap((data) => {
            console.log(data);
            return data;
          }),
          catchError(this.handleError<any[]>('Get task list', []))
        )
    );
  }

  getTimesheetWork(params?): Observable<any> {
    params == undefined ? (params = '') : null;
    return (
      this.http
        .get<any>(`${this.url}employee/sheet/${params}/`)
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          map((data) => {
            console.log(data);
            return data;
          }),
          catchError(this.handleError<any[]>('Get task list', []))
        )
    );
  }

  /**
   *
   *
   * @returns list resourcestasks
   */
  addHours(form?): Observable<any> {
    return (
      this.http
        .post<any>(`${this.url}task/taskworkhistories/`, form)
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          tap((data) => {
            console.log(data);
            return data;
          }),
          catchError(this.handleError<any[]>('Add hours task list', []))
        )
    );
  }

  addHoursAttendance(form?): Observable<any> {
    return (
      this.http
        .post<any>(`${this.url}employee/attendances/`, form)
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          tap((data) => {
            console.log(data);
            return data;
          }),
          catchError(this.handleError<any[]>('Add hours task list', []))
        )
    );
  }

  /**
  * 


        /**
  * 
  *
  * @returns list resourcestasks
  */
  editHours(form?): Observable<any> {
    console.log(form);

    return (
      this.http
        .put<any>(
          `${this.url}task/taskworkhistory/${form.workhistory_id}/`,
          form
        )
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          tap((data) => {
            console.log(data);
            return data;
          }),
          catchError(this.handleError<any[]>('Put edit hours list', []))
        )
    );
  }

  deleteHours(form?): Observable<any> {
    console.log(form);
    return (
      this.http
        .delete<any>(
          `${this.url}task/taskworkhistory/${form.workhistory_id}/`,
        )
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          tap((data) => {
            console.log(data);
            return data;
          }),
          catchError(this.handleError<any[]>('Put edit hours list', []))
        )
    );
  }

  
  closeTask(id?): Observable<any> {
    console.log(id);
    return (
      this.http
        .delete<any>(
          `${this.url}task/task/${id}/`,
        )
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          tap((data) => {
            console.log(data);
            return data;
          }),
          catchError(this.handleError<any[]>('Delete close task', []))
        )
    );
  }

  openTask(id?, form?:any): Observable<any> {
    console.log(id);
    return (
      this.http
        .put<any>(
          `${this.url}task/task/${id}/`, form
        )
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          tap((data) => {
            console.log(data);
            return data;
          }),
          catchError(this.handleError<any[]>('Put open task', []))
        )
    );
  }

  editHoursAttendance(form?): Observable<any> {
    console.log(form);

    return (
      this.http
        .put<any>(
          `${this.url}employee/attendance/${form.history_id}/`,
          form
        )
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          tap((data) => {
            console.log(data);
            return data;
          }),
          catchError(this.handleError<any[]>('Put edit hours list', []))
        )
    );
  }
  
  deleteHoursAttendance(form?): Observable<any> {
    return (
      this.http
        .delete<any>(
          `${this.url}employee/attendance/${form.history_id}/`,
        )
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          tap((data) => {
            console.log(data);
            return data;
          }),
          catchError(this.handleError<any[]>('Put edit hours list', []))
        )
    );
  }
  /**
  * 
  * 
  * 
        /**
  * 
  *
  * @returns list resourcestasks
  */
  approveHour(id?: any, form?: any): Observable<any> {
    console.log(form);
    
    return (
      this.http
        .put<any>(`${this.url}task/taskworkhistory/${id}/`, form)
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          tap((data) => {
            console.log(data);
            return data;
          }),
          catchError(this.handleError<any[]>('Put edit hours list', []))
        )
    );
  }

  approveHourAttendance(id?: any, form?: any): Observable<any> {
    return (
      this.http
        .put<any>(`${this.url}employee/attendance/${id}/`, form)
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          tap((data) => {
            console.log(data);
            return data;
          }),
          catchError(this.handleError<any[]>('Put edit hours list', []))
        )
    );
  }

  /**
  * 

  * @returns list task histories
  */
  getHistoriesTasksList(params?): Observable<any> {
    params == undefined ? (params = '') : null;
    return this.http
      .get<any>(`${this.url}task/taskworkhistories/${params}`)
      .pipe(
        timeout(16000),
        tap((data) => {
          console.log(data);
          return data;
        }),
        catchError(this.handleError<any[]>('Get task list', []))
      );
  }

  /**
   *
   *
   * @returns by id wokrers
   */
  getWorkerById(id, params?): Observable<any> {
    params == undefined ? (params = '') : null;
    return this.http.get<any>(`${this.url}tasks/task/${id}/${params}`).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get wokrers list', []))
    );
  }

  /**
   * Create Task
   *
   */

  createTask(task: any) {
    return this.http.post<any>(`${this.url}task/tasks/`, task).pipe(
      tap((data) => {
        console.log(data);
      }),
      catchError(this.handleError<any[]>('Create task', []))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      //console.error(error);
      console.log(result, error);

      console.log(`${operation} failed`, error);
      const status = error == 'Forbidden' ? 403 : 500;
      this.presentToast(
        status,
        error == 'undefined' ? 'Internal Server Error' : error,
        operation
      );
      return of(result as T);
    };
  }

  async presentToast(status, statusText, message) {
    const toast = await this.toastController.create({
      message: status + ' ' + statusText + ': "' + message + '"',
      duration: 2000,
      mode: 'ios',
      cssClass: 'toast',
      color: status != '200' ? 'danger' : 'primary',
    });
    toast.present();
  }

  logSys(src, status, opt?) {
    const debugStyle1 =
      'background: linear-gradient(135deg,#471ee9,#3a49b7); border: 1px solid #9a9a9a; color: #ffffff; border-bottom-left-radius: 2px; border-top-left-radius: 2px; padding: 2px 0 2px 4px;';
    const debugStyle2 =
      'background: #252b3e; border: 1px solid #9a9a9a; border-top-right-radius: 2px; border-bottom-right-radius: 2px; margin-left: -2px; padding: 2px 4px; color: white;';

    //console.log('ForceRefresh API ', status, this.networkService.getCurrentNetworkStatus(), this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline);
    console.log(
      `%cApiService %c%s`,
      debugStyle1,
      debugStyle2,
      ' ' + src,
      status
    );
  }

  // Save result of API requests
  private setLocalData(key, data) {
    this.storage.set(`${API_STORAGE_KEY}-${key}`, data);
  }

  // Get cached API result
  private getLocalData(key) {
    return this.storage.get(`${API_STORAGE_KEY}-${key}`);
  }
}
