import { OrdersService } from './../../../services/orders/orders.service';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Order } from '../../models/order.model';
import { IonDatetime, LoadingController, ModalController, PopoverController, ToastController } from '@ionic/angular';
import { NewDateComponent } from './new-date/new-date.component';


@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class DateComponent implements OnInit {

  cache = {}

  @Input() prevent: any;
  @Input() date: any;
  @Input() listStatus: any;
  @Input() order: any;
  @Output() date_exp = new EventEmitter();


  constructor(
    private modalCtrl: ModalController,
    private apiOrder: OrdersService,
    private fb: FormBuilder,
    private toastController: ToastController,
    private popCtrl: PopoverController,
    private loadCtrl: LoadingController
  ) {
    //check popoverDatetime2 isOpen


  }

  async ngOnInit() {

  }


  returnOrders(event) {
    console.log(event);
    event = event.data
    this.cache = {}


  }

  hasEditAction(actions: any[]): boolean {
    return actions?.some(action => action.code === 'CAN_U') ?? false;
  }
  

  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color
    });
    return await toast.present();
  }


  dismissModal(message?) {
    this.modalCtrl.dismiss(message);
  }





  customCounterFormatter(inputLength: number, maxLength: number) {
    return `${maxLength - inputLength} characters remaining`;
  }


  async openModal() {
    const modal = await this.modalCtrl.create({
      component: NewDateComponent,
      componentProps: {
        order: this.order
      }
    });
    modal.present();

    await modal.onWillDismiss().then(e => {
      if(e.data){
        this.cache = {}
      }
    })
  }
}
