import { Component, OnInit, Pipe } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';

@Component({
  selector: 'app-task-assign',
  templateUrl: './task-assign.component.html',
  styleUrls: ['./task-assign.component.scss'],
})
export class TaskAssignComponent implements OnInit {
  tasksForm: FormGroup;
  totalPeriod: number = 0; // Somma totale dei giorni
  globalStartDate: string | null = null; // Data di inizio complessiva
  globalEndDate: string | null = null; // Data di fine complessiva
  today: string; // Variabile per la data odierna
  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    // Imposta la variabile today alla data odierna in formato 'YYYY-MM-DD'
    const currentDate = new Date();
    this.today = currentDate.toISOString().split('T')[0];

    this.tasksForm = this.fb.group({
      tasks: this.fb.array([]),
    });

    // Creazione iniziale di 5 task
    for (let i = 0; i < 5; i++) {
      setTimeout(() => {
        this.addTask(); // Aggiungi 5 task con un input di ricerca per gli assegnatari
      }, 100);
    }

    // Ricalcolo del periodo totale e delle date globali ogni volta che il form cambia
    this.tasksForm.valueChanges.subscribe(() => {
      this.calculateTotalPeriod(); // Funzione che calcola la somma totale dei periodi e le date complessive
    });
  }

  get tasks(): FormArray {
    return this.tasksForm.get('tasks') as FormArray;
  }

  createTask(): FormGroup {
    return this.fb.group({
      projectName: ['', Validators.required],
      taskName: ['', Validators.required],
      startDate: [this.today, [Validators.required, this.dateValidator]],
      endDate: [this.today, [Validators.required, this.dateValidator]],
      period: ['', [Validators.required, this.dateValidator]],
      maxHours: ['', [Validators.required, Validators.min(1)]],
      assignees: this.fb.array([]),
      confirmed: [false],
    });
  }

  addTask() {
    const newTask = this.createTask();
    newTask.patchValue({ startDate: new Date().toISOString().split('T')[0] }); // Data odierna come predefinita

    this.tasks.push(newTask);

    // Calcola la data di fine quando cambia il periodo
    newTask.get('period').valueChanges.subscribe(() => {
      this.calculateEndDateFromPeriod(this.tasks.length - 1);
    });

    // Calcola la data di fine quando cambia la data di inizio
    newTask.get('startDate').valueChanges.subscribe(() => {
      this.calculateEndDateFromStartDate(this.tasks.length - 1);
    });
  }

  removeTask(index: number) {
    this.tasks.removeAt(index);
  }

  addAssignee(taskIndex: number) {
    // Aggiunge un input di ricerca iniziale
    const assigneesArray = this.getAssignees(taskIndex);
    if (assigneesArray.length === 0) {
      this.addSearchAssigneeInput(taskIndex);
    }
  }

  filteredAssignees = []; // Risultati filtrati per la ricerca
  availableAssignees = ['riccardo', 'giovanni', 'giulio', 'mauro', 'alessia']; // Simulazione dell'elenco di dipendenti

  filteredAssigneesList: any[] = []; // Array di liste filtrate per ciascun task

  // Aggiorna la funzione di ricerca in modo che sia specifica per il task
  searchAssignees(event: any, taskIndex: number) {
    const searchTerm = event.target.value.toLowerCase();

    // Ottieni gli assegnatari già selezionati per la task specifica
    const selectedAssignees = this.getAssignees(taskIndex)
      .controls.map((control) => control.get('name')?.value)
      .filter((name) => !!name); // Filtra solo i nomi non vuoti

    // Filtra la lista di assegnatari disponibili escludendo quelli già selezionati
    if (searchTerm.length > 0) {
      this.filteredAssigneesList[taskIndex] = this.availableAssignees.filter(
        (assignee) =>
          assignee.toLowerCase().includes(searchTerm) &&
          !selectedAssignees.includes(assignee) // Evita duplicati
      );
    } else {
      this.filteredAssigneesList[taskIndex] = [];
    }

    // Debug: Stampa la lista filtrata per la task corrente
    console.log(
      `Filtered Assignees for Task ${taskIndex + 1}:`,
      this.filteredAssigneesList[taskIndex]
    );
  }

  selectAssignee(assignee: string, taskIndex: number) {
    const assigneesArray = this.getAssignees(taskIndex);

    // Rimuovi l'ultimo input di ricerca
    if (
      assigneesArray.length > 0 &&
      !assigneesArray.at(assigneesArray.length - 1).get('name')
    ) {
      assigneesArray.removeAt(assigneesArray.length - 1);
    }

    // Aggiungi l'assegnatario selezionato
    assigneesArray.push(
      this.fb.group({
        name: [assignee],
      })
    );

    // Aggiungi subito un nuovo input di ricerca
    this.addSearchAssigneeInput(taskIndex);

    setTimeout(() => {
      // Assicurati di ripristinare il campo di input 'searchControl'
      const assigneeSearchControl = assigneesArray
        .at(assigneesArray.length - 1)
        .get('searchControl');
      if (assigneeSearchControl) {
        assigneeSearchControl.setValue(''); // Pulisci il campo di input
      }
    }, 200);

    // Pulisci i risultati della ricerca
    this.filteredAssigneesList[taskIndex] = [];
  }

  addSearchAssigneeInput(taskIndex: number) {
    const assigneesArray = this.getAssignees(taskIndex);

    // Aggiungi sempre un campo di ricerca iniziale quando il form viene creato
    if (
      assigneesArray.length === 0 ||
      assigneesArray.at(assigneesArray.length - 1).get('name')
    ) {
      assigneesArray.push(
        this.fb.group({
          searchControl: [''], // Campo di input di ricerca iniziale
        })
      );
    }
  }

  removeAssignee(taskIndex: number, assigneeIndex: number) {
    const assignees = this.getAssignees(taskIndex);
    assignees.removeAt(assigneeIndex);

    // Svuota la lista filtrata quando un assegnatario viene rimosso
    this.filteredAssignees = [];
  }

  totalAssignes = []
  getAssignees(taskIndex: number): FormArray {
    const assigneesArray = this.tasks.at(taskIndex).get('assignees') as FormArray;
  
    // Filtra per rimuovere i controlli che contengono 'searchControl'
    const filteredAssignees = assigneesArray.controls.filter(control => !control.get('searchControl'));
  
    // Aggiorna 'totalAssignes[taskIndex]' con solo gli assegnatari senza 'searchControl'
    this.totalAssignes[taskIndex] = filteredAssignees; // Salva gli assegnatari solo per la task corrente
    console.log(`Task ${taskIndex} Assignees: `, this.totalAssignes[taskIndex]);
    
    return this.tasks.at(taskIndex).get('assignees') as FormArray;
  }

  

  confirmTask(taskIndex: number) {
    const taskGroup = this.tasks.at(taskIndex);
    taskGroup.patchValue({ confirmed: true });

    // Disabilita i campi del task
    taskGroup.get('projectName').disable();
    taskGroup.get('taskName').disable();
    taskGroup.get('startDate').disable();
    taskGroup.get('endDate').disable();
    taskGroup.get('period').disable();
    taskGroup.get('maxHours').disable();

    // Disabilita gli assegnatari e rimuovi l'input di ricerca
    this.getAssignees(taskIndex).controls.forEach((assignee) => {
      assignee.get('name')?.disable();
    });

    // Rimuovi eventuali input di ricerca rimasti
    const assigneesArray = this.getAssignees(taskIndex);
    if (
      assigneesArray.length > 0 &&
      !assigneesArray.at(assigneesArray.length - 1).get('name')
    ) {
      assigneesArray.removeAt(assigneesArray.length - 1);
    }
  }

  onSubmit() {
    const confirmedTasks = this.tasksForm.value.tasks.filter(
      (task) => task.confirmed
    );
    console.log('Confirmed Tasks:', confirmedTasks);
  }

  editTask(taskIndex: number) {
    const taskGroup = this.tasks.at(taskIndex);
    taskGroup.patchValue({ confirmed: false });

    // Riabilita i campi per la modifica
    taskGroup.get('projectName').enable();
    taskGroup.get('taskName').enable();
    taskGroup.get('startDate').enable();
    taskGroup.get('endDate').enable();
    taskGroup.get('period').enable();

    taskGroup.get('maxHours').enable();

    // Riabilita gli assegnatari esistenti
    this.getAssignees(taskIndex).controls.forEach((assignee) => {
      assignee.get('name').enable();
    });

    // Riaggiungi l'input di ricerca per assegnatari se non esiste già
    this.addSearchAssigneeInput(taskIndex);
  }

  // Funzione per calcolare la data di fine (solo giorni lavorativi)
  calculateEndDate(startDate: Date, period: number): string {
    let daysAdded = 0;
    let currentDate = new Date(startDate);

    // Aggiungi solo giorni lavorativi
    while (daysAdded < period) {
      currentDate.setDate(currentDate.getDate() + 1);
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        // Esclude sabato e domenica
        daysAdded++;
      }
    }

    return currentDate.toISOString().split('T')[0]; // Ritorna la data in formato YYYY-MM-DD
  }

  // Funzione che calcola il periodo dai giorni tra due date
  calculatePeriod(taskIndex: number) {
    const task = this.tasks.at(taskIndex);
    const startDate = new Date(task.get('startDate').value);
    const endDate = new Date(task.get('endDate').value);
    if (startDate && endDate) {
      const diffInTime = endDate.getTime() - startDate.getTime();
      const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24)); // Calcola la differenza in giorni
      task.patchValue({ period: diffInDays });
    }
  }

  // Funzione che calcola il totale dei giorni e le date complessive
  calculateTotalPeriod() {
    let totalPeriod = 0;
    let firstStartDate: Date | null = null;
    let lastEndDate: Date | null = null;

    // console.log('Inizio calcolo del periodo totale');

    this.tasks.controls.forEach((task, index) => {
      const period = task.get('period').value;
      const startDateStr = task.get('startDate').value; // Start date come stringa (formato YYYY-MM-DD)
      const endDateStr = task.get('endDate').value; // End date come stringa (formato YYYY-MM-DD)

      // console.log(`Task ${index + 1} - Period: ${period}, Start Date: ${startDateStr}, End Date: ${endDateStr}`);

      // Verifica che startDate ed endDate siano stringhe valide nel formato 'YYYY-MM-DD'
      const startDate = startDateStr ? new Date(startDateStr) : null;
      const endDate = endDateStr ? new Date(endDateStr) : null;

      // if (startDate) {
      //   console.log(`Task ${index + 1} - Start Date valida: ${startDate}`);
      // } else {
      //   console.log(`Task ${index + 1} - Start Date non valida`);
      // }

      // if (endDate) {
      //   console.log(`Task ${index + 1} - End Date valida: ${endDate}`);
      // } else {
      //   console.log(`Task ${index + 1} - End Date non valida`);
      // }

      // Somma il periodo (se esiste)
      if (period) {
        totalPeriod += parseInt(period, 10);
        // console.log(`Task ${index + 1} - Period sommato: ${totalPeriod}`);
      }

      // Verifica che la data di inizio sia valida
      if (startDate && !isNaN(startDate.getTime())) {
        if (!firstStartDate || startDate < firstStartDate) {
          firstStartDate = startDate;
          // console.log(`Nuova First Start Date: ${firstStartDate}`);
        }
      }

      // Verifica che la data di fine sia valida
      if (endDate && !isNaN(endDate.getTime())) {
        if (!lastEndDate || endDate > lastEndDate) {
          lastEndDate = endDate;
          // console.log(`Nuova Last End Date: ${lastEndDate}`);
        }
      }
    });

    // Imposta la somma totale dei giorni e le date complessive nel formato YYYY-MM-DD
    this.totalPeriod = totalPeriod;
    this.globalStartDate = firstStartDate
      ? this.formatDateToDDMMYYYY(firstStartDate)
      : null;
    this.globalEndDate = lastEndDate
      ? this.formatDateToDDMMYYYY(lastEndDate)
      : null;

    console.log(`Periodo Totale: ${this.totalPeriod}`);
    console.log(`Global Start Date: ${this.globalStartDate}`);
    console.log(`Global End Date: ${this.globalEndDate}`);
  }

  formatDateToDDMMYYYY(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0'); // Aggiunge uno zero iniziale se necessario
    const month = String(date.getMonth() + 1).padStart(2, '0'); // I mesi vanno da 0 a 11, quindi aggiungiamo 1
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  dateValidator(control: any) {
    const datePattern = /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[012])\/\d{4}$/; // Regex per formato gg/mm/aaaa
    return datePattern.test(control.value) ? null : { invalidDate: true };
  }

  calculatePeriodFromDates(taskIndex: number) {
    const task = this.tasks.at(taskIndex);
    const startDate = new Date(); // Data odierna
    const endDate = new Date(task.get('endDate').value);

    if (startDate && endDate) {
      const diffInTime = endDate.getTime() - startDate.getTime();
      const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24)); // Differenza in giorni

      if (diffInDays >= 0) {
        task.patchValue({ period: diffInDays });
      } else {
        task.patchValue({ period: 0 }); // Imposta a 0 se la data di fine è precedente alla data di inizio
      }
    }
  }

  calculateEndDateFromPeriod(taskIndex: number) {
    const task = this.tasks.at(taskIndex);
    const period = task.get('period').value; // Numero di giorni inseriti
    const startDate = new Date(task.get('startDate').value); // Data di inizio

    console.log(`Calcolo della data di fine per Task ${taskIndex + 1}`);
    console.log(`Start Date: ${startDate}, Period: ${period}`);

    if (period && startDate) {
      let daysAdded = 0;
      let currentDate = new Date(startDate);

      // Aggiungi giorni lavorativi fino a raggiungere il periodo
      while (daysAdded < parseInt(period, 10)) {
        currentDate.setDate(currentDate.getDate() + 1); // Vai al giorno successivo
        const dayOfWeek = currentDate.getDay();
        // Conta solo i giorni lavorativi (lunedì - venerdì)
        if (dayOfWeek !== 0 && dayOfWeek !== 6) {
          daysAdded++;
        }
      }

      // Imposta la nuova data di fine nel formato YYYY-MM-DD
      const formattedEndDate = currentDate.toISOString().split('T')[0];
      console.log(
        `Nuova End Date per Task ${taskIndex + 1}: ${formattedEndDate}`
      );

      // Imposta la nuova data di fine nel form (nel formato corretto)
      task.patchValue({ endDate: formattedEndDate });
    }
  }

  calculateEndDateFromStartDate(taskIndex: number) {
    const task = this.tasks.at(taskIndex);
    const period = task.get('period').value;
    const startDate = new Date(task.get('startDate').value); // Nuova data di inizio

    if (period && startDate) {
      this.calculateEndDateFromPeriod(taskIndex); // Ricalcola la data di fine basandosi sulla nuova data di inizio
    }
  }

  // Calcola la data di fine quando cambia il periodo
  onPeriodChange(taskIndex: number) {
    const task = this.tasks.at(taskIndex);
    const period = task.get('period').value;
    const startDate = new Date(task.get('startDate').value);

    if (period && startDate) {
      task.patchValue({ endDate: this.calculateEndDate(startDate, period) });
    }
  }

  // Aggiorna la data di fine quando cambia la data di inizio
  onStartDateChange(taskIndex: number) {
    const task = this.tasks.at(taskIndex);
    const period = task.get('period').value;
    const startDate = new Date(task.get('startDate').value);

    if (period && startDate) {
      task.patchValue({ endDate: this.calculateEndDate(startDate, period) });
    }
  }

  test(event) {
    console.log(event);
  }
}
