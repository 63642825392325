import { Component, OnInit, ViewChild, Input } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { FormGroup, FormControl, Validators, FormGroupDirective, FormBuilder } from '@angular/forms';
import { DataService } from '../../services/data/data.service';
import { Project } from '../models/projects';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api/api.service';
import { IonDatetime, ModalController } from '@ionic/angular';
import { ProjectsService } from 'src/app/services/projects/projects.service';

@Component({
  selector: 'app-update-project',
  templateUrl: './update-project.page.html',
  styleUrls: ['./update-project.page.scss'],
})
export class UpdateProjectPage {
  public project: any;
  updateProjectForm: FormGroup;
  formIsEdited: boolean = false;
  types_category: any = [];

  minDate = moment().format();
  maxDate = moment().add(1, 'd').format();

  response = '';

  @ViewChild('createForm') createForm: FormGroupDirective;

  @ViewChild(IonDatetime, { static: true }) datetime: IonDatetime;
  @ViewChild(IonDatetime, { static: true }) datetime2: IonDatetime;
  @Input() id: any;
  @Input() item: any;

  constructor(
    private dataService: DataService,
    private api: ProjectsService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalController: ModalController

  ) {
    //const id = this.activatedRoute.snapshot.paramMap.get('id');
  }


  createBaseForm() {

    this.updateProjectForm = this.fb.group({
      'name': this.fb.control(this.item.name, Validators.required),
      'code': this.fb.control(this.item.code, Validators.required),
      //'description': this.fb.control(this.item.description, Validators.required),
      //'email': this.fb.control(this.item.email),
      //'budget': this.fb.control(this.item.budget, Validators.required),
      'date_pre_start': this.fb.control(this.item.date_pre_start, Validators.required),
      'date_pre_end': this.fb.control(this.item.date_pre_end, Validators.required),
      'cost_pre': this.fb.control('', Validators.required),
      'type_id': this.fb.control('', Validators.required),
    });
    //this.updateProjectForm.get('email').disable()

    this.updateProjectForm.valueChanges.subscribe(values => {
      this.formIsEdited = true;
    })

    console.log(this.updateProjectForm);
    
  }

  get errorControl() {

    return this.updateProjectForm.controls;
  }



  ionViewWillEnter() {

    this.api.getTypesList().subscribe(e => {
      this.types_category = e
    })

    this.createBaseForm()
    console.log(this.item);
    

  }

  submitForm() {
    this.createForm.onSubmit(undefined);
  }

  async updateProject(values: any) {/* await */
    // copy all the form values into the project to be updated
    const updatedProject: Project = { id: this.item.id, ...values };
    /* const projectUpdated =  this.api.updateProject(updatedProject).subscribe(e => {
      console.log(e);
      if (e) {

ù      }
    }) */
    /* if (projectUpdated != null) {
    } */
  }

  async deleteProject(projectId: number) {
    //const projectDeleted = await this.api.deleteProject(projectId);

    //if (projectDeleted != null) {
    //this.router.navigate(['/projects-list']);
    //}
  }

  onChangeStartDate(event) {
    this.item.end_date = moment(event.detail.value).add(1, 'd').format();
    this.updateProjectForm.controls['end_date'].setValue(this.item.end_date)
  }


  formatDate(value: string) {
    //return format(parseISO(value), 'MMM dd yyyy');
  }



  dismissModal(event?) {
    this.modalController.dismiss(event)
  }

  //{message: this.response}
}

