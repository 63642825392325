import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges, Renderer2, OnDestroy, HostListener, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IonSelectOption } from '@ionic/angular';
import { IonicSelectableComponent } from 'ionic-selectable';

@Component({
  selector: 'app-ion-selector',
  templateUrl: './ion-selector.component.html',
  styleUrls: ['./ion-selector.component.scss'],
})
export class IonSelectorComponent implements OnInit, OnChanges {

  @Input() selectedValue
  @Input() refreshItem
  @Input() pushItem;
  @Input() disabled 

  @ViewChild('ionSelector') ionSelector: IonicSelectableComponent;

  @Input() addItem = true;
  @Input() saveItem = false;
  @Input() deleteItem = true;
  @Input() debounce = 500;
  @Input() canSearch = true;
  @Input() jobId = false


  @Input() searchItem = null

  @Input() title = 'TITLE';
  @Input() items = [];
  @Input() size = 50;
  @Input() createFormSelectable: FormGroup;

  @Input() page = 1;
  @Input() numPages = 1

  eventSelector: { component: IonicSelectableComponent }
  itemsSelector
  scrollHeight = 0

  @Output() emitterSelect: EventEmitter<{ component: IonicSelectableComponent, item: any, isSelected: boolean, module: string }> = new EventEmitter();
  @Output() emitterAdd: EventEmitter<string> = new EventEmitter();
  @Output() emitterSave: EventEmitter<string> = new EventEmitter();
  @Output() emitterDel: EventEmitter<string> = new EventEmitter();
  @Output() emitterEdit: EventEmitter<{ action: string, item: any, module: string }> = new EventEmitter();
  @Output() emitterSearch: EventEmitter<{ event: any, text: string, module: string }> = new EventEmitter();
  @Output() emitterInfinite: EventEmitter<any> = new EventEmitter();
  @Output() getMore: EventEmitter<any> = new EventEmitter();
  @Output() emitterClearForm: EventEmitter<any> = new EventEmitter();

  @Input() module;

  constructor(
    private fb: FormBuilder,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef
  ) {

    this.itemsSelector = this.items
    console.log('JOBID', this.jobId);
    
  }

  ngOnInit() {

    this.createFormSelectable = new FormGroup({
      'name': new FormControl('', [Validators.minLength(5), Validators.maxLength(31), Validators.required]),
    })

  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('|---------- ngOnChanges -----------|', changes, this.searchItem);

    if (!this.disabled)
      if ((changes['items'] && !changes['items'].firstChange) && !changes['searchItem']) {

        console.log(changes, this.eventSelector);
        console.log(changes['items'].currentValue);

        let rows = Array.from(new Map( changes['items'].currentValue.map(job => [job.id, job])).values());
        console.log(rows);
        
        // this.itemsSelector = this.itemsSelector.concat(rows);
        this.itemsSelector =  Array.from(new Map(this.itemsSelector.concat(rows).map(job => [job.id, job])).values());
        this.cd.detectChanges()

        console.log(this.itemsSelector);

        this.eventSelector.component.items = this.itemsSelector;
        this.eventSelector.component._modalComponent._content.scrollY = false;
        console.log('false');

        setTimeout(() => {
          console.log(this.eventSelector.component._modalComponent._element.nativeElement.childNodes[1].childNodes[1].offsetHeight);
          //this.renderer.setProperty(this.eventSelector.component._modalComponent._element.nativeElement,)
          this.eventSelector.component._modalComponent._element.nativeElement.childNodes[1].childNodes[1].scrollTop = this.scrollHeight + 100
        }, 250);

        this.eventSelector.component._modalComponent._content.scrollY = true;
        console.log('true');



        this.eventSelector.component.endInfiniteScroll();
        this.eventSelector.component.endSearch();

      } else if ((changes['items'] && !changes['items'].firstChange) && (changes['searchItem'] && !changes['searchItem'].currentValue)) {

        console.log(changes, this.eventSelector.component.items);
        console.log(changes['items'].currentValue);
        /* if (changes['items'].currentValue == null)
          this.itemsSelector = [] */

        let rows = Array.from(new Map( changes['items'].currentValue.map(job => [job.id, job])).values());
        this.itemsSelector = this.itemsSelector.concat(rows);
        this.cd.detectChanges()

        console.log(this.itemsSelector);

        this.eventSelector.component.items = this.itemsSelector;

        this.eventSelector.component._modalComponent._content.scrollY = false;
        console.log('false');

        setTimeout(() => {
          console.log(1500, this.eventSelector.component._modalComponent._element.nativeElement.childNodes[1].offsetHeight);
          this.eventSelector.component._modalComponent._element.nativeElement.childNodes[1].childNodes[1].scrollTop = this.scrollHeight + 100
        }, 250);

        this.eventSelector.component._modalComponent._content.scrollY = true;
        console.log('true');



        this.eventSelector.component.endInfiniteScroll();
        this.eventSelector.component.endSearch();

      } else if (changes['searchItem'] && !changes['searchItem'].firstChange) {

        console.log(changes);
        let rows = changes['items'] ? changes['items'].currentValue : [];

        //if (!this.searchItem || this.searchItem != '') {
        console.log(changes);
        this.cd.detectChanges()
        this.itemsSelector = []
        //}
        //let rows = this.items


        this.itemsSelector = this.itemsSelector.concat(rows);
        console.log(this.itemsSelector);

        if (this.eventSelector) {
          this.eventSelector.component.items = this.itemsSelector;
          this.eventSelector.component._modalComponent._content.scrollY = false;
          console.log('false');

          setTimeout(() => {
            console.log(1500, this.eventSelector.component._modalComponent._element.nativeElement.childNodes[1]);
            this.eventSelector.component._modalComponent._element.nativeElement.childNodes[1].childNodes[1].scrollTop = this.scrollHeight + 100
          }, 300);

          this.eventSelector.component._modalComponent._content.scrollY = true;
          console.log('true');

          this.eventSelector.component.endInfiniteScroll();
          this.eventSelector.component.endSearch();
        }
        //}
        /*  else {
  
          this.itemsSelector = this.itemsSelector = (rows);
          console.log(this.itemsSelector);
  
          this.eventSelector.component._modalComponent._content.scrollY = true;
          this.eventSelector.component.items = this.itemsSelector;
          //this.eventSelector.component.endInfiniteScroll();
          this.eventSelector.component.endSearch();
  
        } */

      } else if ((changes['refreshItem'] && !changes['refreshItem'].firstChange)) {

        let index = this.itemsSelector.map(e => e.id).indexOf(changes['refreshItem'].currentValue.id)
        if (index >= 0)
          this.itemsSelector[index] = changes['refreshItem'].currentValue
        this.cd.detectChanges()
      } else if ((changes['pushItem'] && !changes['pushItem'].firstChange)) {
        console.log(changes['pushItem'], this.eventSelector, this.itemsSelector);

        this.itemsSelector.unshift(changes['pushItem'].currentValue)
        this.cd.detectChanges()

        this.eventSelector ? this.eventSelector.component._modalComponent._content.scrollToTop(200) : ''

      }
    /*  else if ((changes['items'] && !changes['items'].firstChange)) {


      console.log(changes['items'].currentValue);

      let rows = changes['items'].currentValue

      if (this.searchItem == '') {
        console.log(changes, this.eventSelector.component.items);
        let rows = changes['items'].currentValue
        this.eventSelector.component.endInfiniteScroll();
        //this.itemsSelector = this.eventSelector.component.items.concat(rows);
        this.itemsSelector = this.itemsSelector.concat(rows);

        console.log(this.itemsSelector);

        this.eventSelector.component._modalComponent._content.scrollY = true
        this.eventSelector.component.items = this.itemsSelector;

      } else {

        this.eventSelector.component.endInfiniteScroll();
        //this.itemsSelector = this.eventSelector.component.items.concat(rows);
        this.itemsSelector = this.itemsSelector = (rows);

        console.log(this.itemsSelector);

        this.eventSelector.component._modalComponent._content.scrollY = true
        this.eventSelector.component.items = this.itemsSelector;
        this.eventSelector.component.endSearch();


      }

    } */ else if (changes['page'] && !changes['page'].firstChange) {
        console.log(changes);


      } else if (changes['numPages'] && !changes['numPages'].firstChange) {
        console.log(changes);

      }

  }

  onAddPort(event: {
    component: IonicSelectableComponent
  }) {
    // Clean form.
    this.createFormSelectable ? this.createFormSelectable.reset() : '';

    console.log('onAddPort', event);
    // Copy search text to port name field, so
    // user doesn't have to type again.
    //this.createFormSelectable.controls['name'].setValue(event.component.searchText);

    // Show form.
    //event.component.showAddItemTemplate();
    //





    if (!this.disabled)
      this.emitterAdd.emit('open')
    //this.openNewProjectModal()
  }

  onSavePort(event: {
    component: IonicSelectableComponent,
    item//: Port
  }) {
    console.log('onSavePort', event);

    // Fill form.
    //this.createFormSelectable.setValue(event.item);
    ///this.portCountryControl.setValue(event.item.country);

    // Show form.
    //event.component.showAddItemTemplate();








    if (!this.disabled)
      this.emitterEdit.emit({ action: 'open', item: event.item, module: this.module })
    //this.openUpdateProjectModal(event.item)
  }

  onDeletePort(event: {
    component: IonicSelectableComponent,
    item//: Port
  }) {
    // Delete port from storage.
    //this.portService.deletePort(event.item);

    // Delete port from list.
    event.component.deleteItem(event.item);
  }

  searchPorts(event: { component: IonicSelectableComponent; text: string }, type) {
    console.log(event);

    let url = ''
    const text = event.text.trim().toLowerCase();
    console.log(text)
    this.eventSelector = event
    this.itemsSelector = event.component.items

    console.log(event, text, this.module);
    /* console.log(event.component._modalComponent);
    console.log(event.component._modalComponent._content); */


    this.page = 1
    this.itemsSelector = []

    event.component.startSearch();
    event.component.enableInfiniteScroll();

    if (!this.disabled) {

      if (!text || text == '') {
        // Close any running subscription.



        /***
         * API GET
         */

        this.searchItem = ''
        this.emitterSearch.emit({ event, text, module: this.module })

        /* this.project.getProjectsList_rev(url).subscribe((data) => {
          console.log('!text call more getCustomersList', data);
    
          data.results = event.component.items = (data.results);
    
          this.numpage = data.num_pages;
    
          event.component.items = data.results;
    
        }) */
        //event.component.endInfiniteScroll();


        event.component._modalComponent ? event.component._modalComponent._content.scrollY = false : null

        return

      }


      /**
       * API GET
       */


      this.searchItem = text
      this.emitterSearch.emit({ event, text, module: this.module })


      event.component._modalComponent._content.scrollY = false
      //event.component.endInfiniteScroll();
    }





  }

  // Metodo para usar con Infinity Scroll en ionic-selectable
  onInfiniteScroll(
    event: { component: IonicSelectableComponent; text: string },
    type: string) {

    this.eventSelector = event
    this.itemsSelector = event.component.items


    console.log(event, this.module);
    //console.log(event.component._modalComponent);
    //console.log(event.component._modalComponent._content);
    //console.log(event.component._modalComponent._content['el']);
    /*     let icon_ios = event.component._modalComponent._content['el'].childNodes[1].childNodes[0].childNodes
    
        console.log('icon_ios', icon_ios);
        Array.from(icon_ios).map(e => {
          console.log(e.childNodes[10]);
    
    
        }) */
    //const page = Math.round(event.component.items ? (event.component.items.length / this.size) : 1);
    //console.log('event.component._modalComponent._content.scrollY ', page);

    console.log(this.page, this.numPages);

    this.scrollHeight = event.component._modalComponent._element.nativeElement.childNodes[1].childNodes[1].offsetHeight

    if (this.page >= (this.numPages) && !this.disabled) {
      event.component.disableInfiniteScroll();
      //event.component.endInfiniteScroll();


      event.component._modalComponent._content.scrollY = true

      console.log('disable scroll end search');

      return;
    }

    //console.log('after');
    //event.component._modalComponent._content.scrollY = false



    console.log('page', this.numPages);

    this.searchItem = ''

    this.emitterInfinite.emit({ event, type, module: this.module })

    event.component._modalComponent._content.scrollToBottom(1500);
    event.component._modalComponent._content.scrollY = false


  }

  getMorePorts(event: {
    component: IonicSelectableComponent,
    text: string
  }, type) {
    this.eventSelector = event
    let url

    event.component._modalComponent._content.scrollToBottom(300);  // <---- Fixed position
    const page = Math.round(event.component.items ? (event.component.items.length / this.size) : 1)

    const text = (event.text || '').trim().toLowerCase();
    console.log('pageNumber', this.page, this.numPages);

    // There're no more ports - disable infinite scroll.
    if (this.page > this.numPages) {
      event.component.disableInfiniteScroll();
      return;
    }

    if (!text) {
      url = '?page=' + (++this.page) + '&items=' + this.size;

      /**
       *  API GET
       */

      /* this.project.getProjectsList_rev(url).subscribe(data => {
        console.log('get getProjectsList', data);
 
        data.results = event.component.items.concat(data.results);
        //event.component.items.concat(data.results);
 
        this.numPages = data.num_pages
 
        event.component.items = data.results;
        event.component.endInfiniteScroll();
 
        console.log(event.component.items);
 
 
      }) */


    }




    // There're no more ports - disable infinite scroll.



  }

  filterPorts(ports: any[], text: string) {
    return ports.filter(port => {
      return port.name.toLowerCase().indexOf(text) !== -1 ||
        port.code.toLowerCase().indexOf(text) !== -1;
    });
  }

  submitFormProject(event, type) {
    console.log('Submit Project', event);
    /**
     *  EMITTER OPEN MODAL CREATE
     */
    //this.order_orders_create(event)
    //this.emitterSave.emit('reset')

  }


  Cancel() {
    console.log(this.ionSelector);
    this.emitterClearForm.emit('reset')
  }

  onClose(event) {
    console.log('close modal', event);

    //this.ngOnDestroy()
  }

  onSelect(event) {
    console.log('on select', event);
    event['module'] = this.module
    console.log(this.module, event);

    this.emitterSelect.emit(event)
    //this.ngOnDestroy()
  }
}
