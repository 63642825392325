import { Observable } from 'rxjs';
import { StepModel } from './../models/step.models';
import { StepsOrderService } from './../../services/orders/steps.service';
import { OrderDetailsPage } from 'src/app/orders/order-details/order-details.page';
import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, ElementRef, Input, OnInit, QueryList, Renderer2, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { IonSegment, ModalController, Platform, ToastController, IonNav } from '@ionic/angular';
import { IonicSelectableComponent } from 'ionic-selectable';
import * as moment from 'moment';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { VendorsService } from 'src/app/services/vendors/vendors.service';

import { Order } from '../models/order.model';
import { UpdateProjectPage } from 'src/app/projects/update-project/update-project.page';
import { NewProjectComponent } from 'src/app/projects/new-project/new-project.component';
import { NewVendorComponent } from 'src/app/vendors/new-vendor/new-vendor.component';
import { VendorUpdatePage } from 'src/app/vendors/vendor-update/vendor-update.page';
import { JobsService } from 'src/app/services/jobs/jobs.service';

export class Port {
  public id: number;
  public name: string;
}

@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.scss'],
})
export class NewOrderComponent {

  @Input() project_in: any; // per un ipotetico inject se necessita di ereditare delle scelte
  @Input() title: string;
  @Input() category:string;
  @Input() categoryId:string;
  @Input() fromCostCenter:string;
  @Input() fromJobsList:string;
  @ViewChild('createForm') createForm: FormGroupDirective;
  @ViewChild('wrapperAddress', { read: ViewContainerRef }) target: ViewContainerRef;
  @ViewChild('projectModel') projectModel: IonicSelectableComponent;

  @ViewChild('priority') priority: ElementRef;

  private componentRef: ComponentRef<any>;
  private emailRegex = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
  private maxRowAddress = 5;
  private maxRowPhone = 5;
  private maxRowEmail = 5;
  // group form general

  selectedValueProject
  portsSubscription

  order_orders_createForm: FormGroup;
  types: any = [];
  categories: any = [];
  vendors: any = [];
  priorities: any = [];
  types_cost: any = [{ name: 'Single Cost', value: 's' }, { name: 'Multiple Cost', value: 'm' }];
  type_cost: any;

  projects: any = [];
  customers: any = [];
  causals: any = [];
  port: Port;

  response
  subscription

  options_lessor = [{ name: 'Locatore', value: true }, { name: 'Conduttore', value: false }]
  options_year = []
  actual_year = parseInt(moment(Date.now()).format('Y'))

  currentStep: Observable<StepModel>;
  isLast: boolean
  isFirst: boolean

  /**
   * PER GESTIRE LA LISTA, RICERCA, MODIFICA E AGGIUNTA ENTITY
   */
  size = 20

  page_project = 1
  page_customer = 1
  page_vendor = 1

  numPage_project = 0
  numPage_customer = 0
  numPage_vendor = 0

  onSelectProject
  onSelectCustomer
  onSelectVendor

  onPushProject
  onPushCustomer
  onPushVendor

  onSearchSelector = ''
  extraTypes:any = []

  constructor(
    private modalController: ModalController,
    private toastController: ToastController,
    private platform: Platform,
    private fb: FormBuilder,
    private api: OrdersService,
    private vendor: VendorsService,
    private project: ProjectsService,
    private job: JobsService,
    private customer: CustomersService,
    private renderer: Renderer2,
    //private nav: IonNav,
    private stepsService: StepsOrderService,

  ) {
    this.createOptionsYear();
    this.currentStep = this.stepsService.getCurrentStep();
    this.isLast = false


    
  }

  onNextStep() {
    if (!this.stepsService.isLastStep()) {
      this.stepsService.moveToNextStep();
    } else {
      this.isLast = this.stepsService.isLastStep()
      console.log(this.isLast);
    }
  }

  onPrewStep() {
    if (!this.stepsService.isFirstStep()) {
      this.stepsService.moveToPrewStep();
    } else {
      console.log(this.isFirst);
      console.log('Prew');

    }
  }

  showButtonLabel() {
    return !this.stepsService.isLastStep() ? 'Continue' : 'Finish';
  }




  amountChanged(event) {
    console.log('event', event);
    let range
    this.order_orders_createForm.controls['cost'].setValue(event/1000)

  }

  createOptionsYear() {
    let past_year = 15;

    console.log(this.actual_year);

    for (let i = 0; i <= past_year; i++) {
      this.options_year.push(this.actual_year--)
    }
  }


  segmentPriority(event) {

    let val = event
    //console.log('segmentPriority', val.value);
    switch (val.value) {
      case '1':
        return 'low'
        break;
      case '2':
        return 'normal'
        break;
      case '3':
        return 'high'
        break;
      default:
        return 'normal'
        break;
    }
  }

  createBaseForm() {

    let _project = this.checkProjectIn();

    console.log(_project, this.priorities, this.types, this.categories, this.projects, this.customers, this.vendors);
    console.log(this.priorities)

    this.order_orders_createForm = this.fb.group({
      priority_id: this.fb.control(this.priorities[0] ? this.priorities[0].id : null),
      //code: this.fb.control('', Validators.required),
      //offer_id: this.fthis.fb.control('', Validators.required),b.control('', Validators.required),
      //is_lessor: this.fb.control(true, Validators.required),
      // subject: this.fb.control('', Validators.required),
      // note: this.fb.control(''),
      // fiscal_year: this.fb.control(2022, Validators.required),
      type_id: this.fb.control(this.types[0].id, Validators.required),
      category_id: this.fb.control('', Validators.required),
      //casual_id: this.fb.control(0),
      customer_id: this.fb.control(0, Validators.required),
      vendor_id: this.fb.control(0, Validators.required),
      job_id: this.fb.control(_project, Validators.required),
      //desc_cost: this.fb.control(''),
      causal_id: this.fb.control('', Validators.required),
      extra_type_id: [{ value: '', disabled: true }],
      cost: this.fb.control(0),
      code: this.fb.control(''),
      description: this.fb.control(''),
      //extra_works: this.fb.control(0),
      parent_id: this.fb.control(0),
    });


    this.order_orders_createForm.valueChanges.subscribe(e => {
      console.log(e);

    })
    this.order_orders_createForm?.get('type_id').valueChanges.subscribe((value) => {
      console.log('type_id changed:', value);
      const selectedType = this.types.find(type => type.id === value);
      console.log('selectedType:', selectedType);
  
      if (selectedType && selectedType.name === 'Manutenzione') {
        this.order_orders_createForm.get('extra_type_id').enable();
        this.order_orders_createForm.get('extra_type_id').setValidators(Validators.required);
        console.log('extra_type_id should be enabled now');
      } else {
        this.order_orders_createForm.get('extra_type_id').disable();
        this.order_orders_createForm.get('extra_type_id').clearValidators();
        console.log('extra_type_id should be disabled now');
      }
      
      this.order_orders_createForm.get('extra_type_id').updateValueAndValidity();
    });
  }

  get errorControl() {

    return this.order_orders_createForm.controls;
  }

  isMaintenanceSelected(): boolean {
    const selectedType = this.types.find((type) => type.id === this.order_orders_createForm.get('type_id').value);
    return selectedType && selectedType.name === 'Manutenzione';
  }
  


  async getOrderMeta() {
    console.log(this.category)
    // this.categories = await this.api.order_categories_list('?page=1&items=25')
    // .toPromise()
    // .then(e => e.results)
    // .then(categories => categories.filter(category => category.parent && category.parent.id === this.categoryId));
    

    if(!this.fromCostCenter || this.fromJobsList){

      this.categories = await this.api.order_categories_list('?page=1&items=25')
      .toPromise()
      .then(e => e.results)
      .then(categories => {
        // Trova l'elemento padre
        const parentCategory = categories.find(category => category.id === this.categoryId);
        
        // Filtra per trovare tutti i figli
        const childCategories = categories.filter(category => category.parent && category.parent.id === this.categoryId);
        
        // Unisci l'elemento padre e i figli in un unico array
        return [parentCategory, ...childCategories];
      });
    }
    else {
      this.categories = await this.api.order_categories_list('?page=1&items=25')
      .toPromise()
      .then(e => e.results)
      .then(categories => {
        // Trova l'elemento padre
        
        // Filtra per trovare tutti i figli
        return categories.filter(category => category.parent === null);
        
        // Unisci l'elemento padre e i figli in un unico array
      });
      console.log(this.categories)
    }


    this.projects = await this.job.getJobsList('?page=' + this.page_project + '&items=' + this.size).toPromise().then(e => e.results)
    this.customers = await this.customer.getCustomersList('?page=' + this.page_customer + '&items=' + this.size).toPromise().then(e => e.results)
    this.vendors = await this.vendor.getVendorsListNoActive('?page=' + this.page_vendor + '&items=' + this.size).toPromise().then(e => e.results)
    this.priorities = await this.api.getPrioritiesOrder().toPromise()
    this.types = await this.api.getOrderTypes().toPromise()
    this.causals = await this.api.getCausalsList().toPromise()
    this.extraTypes = await this.api.getOrderExtraTypes('?page=1&items=25').toPromise().then(e => e.results)
    console.log(this.extraTypes)


    let res = await Promise.all([this.priorities, this.types, this.categories, this.projects, this.customers, this.vendors]);

    return res

  }

  checkProjectIn() {

    if (this.project_in) {
      this.projects = [this.project_in]
      return this.project_in.id;
    } else {
      return ''
    }
  }

  onChange(event) {
    console.log(event);
    //this.order_orders_createForm.controls['vendor_id'].setValue(event.value.id)
  }

  dismissModal(message?) {
    if (message)
      this.response = message
    this.modalController.dismiss(this.response);
  }

  async ionViewDidEnter() {
    console.log(this.fromCostCenter)
    
    let meta = await this.getOrderMeta(); 
    this.createBaseForm()
    this.order_orders_createForm.get('category_id').setValue(this.categoryId)
    console.log(meta);
    if (meta['0'].length == 0 || meta[1].length == 0)
      this.order_orders_createForm = this.fb.group({})


  }

  ionViewWillLeave() {
  }


  ionViewWillEnter() { }


  // Call submitForm with click Save
  isButtonClicked: boolean = false;

  submitForm() {
    console.log('onSubmit');
    this.isButtonClicked = true;
    this.createForm.onSubmit(undefined);
  }



  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color
    });
    return await toast.present();
  }

  async order_orders_create(values: any) {
    console.log(values);

    // copy all the form values into the new vendor
    const newOrder: Order = this.order_orders_createForm.value;
    //newOrder.created = moment(newOrder.created).format('YYYY-MM-DD')


    console.log(newOrder);

    this.api.order_orders_create(newOrder).subscribe(async e => {
      console.log(e);
      if (e.data) {
        this.presentToast(e.message, 'success')
        this.response = e.data
        console.log(this.response);




      } else {
        console.log('no modify');

      }
      this.dismissModal(this.response);
    })
  }

  reset() {
    this.order_orders_createForm.reset();
  }

  setTypeCost(e) {
    this.type_cost = e;
  }



  async openUpdateProjectModal(data?) {
    console.log(this.project);

    const modal = await this.modalController.create({
      component: UpdateProjectPage,
      canDismiss: true,
      mode: 'md',
      //cssClass: 'fullscreen',
      componentProps: {
        item: this.project,
        ////nav: this.nav  // Pass nav for IonNav from modal if necessary
      },
    })


    modal.onWillDismiss().then((e) => {
      console.log(e);

      if (e.data && e.data.message != 'OK') {
        console.log('no modify');
        return
      } else {
        /*  let url = this.concateQuery(this.queryString)
         this.order.getOrdersByProjectId(parseInt(this.id, 10), url)
           .subscribe(orders => {
             console.log(orders);
 
             // if the orders doesn't exists, return to home page
             if (!orders) {
               //this.router.navigate(['/orders-list']);
             } else {
               this.orders = orders.results;
             }
           }); */

      }
    });
    return await modal.present();
  }

  async openNewProjectModal() {
    const modal = await this.modalController.create({
      component: NewProjectComponent,
      cssClass: 'newProjectModalForm',
      canDismiss: true,
      componentProps: {
        //nav: this.nav,
        redirectAfterPost: false
      },

    });

    modal.onWillDismiss().then(async (e) => {
      console.log(e.data)

      if (!e.data) {
        console.log('no modify');
        return
      } else {
        //this.loadProjects(this.currentSegment);
        //this.projects = await this.project.getProjectsList_rev('?page=' + this.page_project + '&items=' + this.size).toPromise().then(e => e.results)

        this.page_project = 1
        //this.onSelectProject = [e.data]
        this.onPushProject = e.data

      }
    });

    return await modal.present();
  }
  async openUpdateCustomerModal(data?) {
    console.log(this.project, data);

    const modal = await this.modalController.create({
      component: VendorUpdatePage,
      canDismiss: true,
      mode: 'md',
      //cssClass: 'fullscreen',
      componentProps: {
        id: data.item.id,
        ////nav: this.nav  // Pass nav for IonNav from modal if necessary
      },
    })


    modal.onWillDismiss().then((e) => {
      console.log(e);

      if (!e.data) {
        console.log('no modify');
        return
      } else {
        //this.page_customer = 1
        this.onSelectCustomer = e.data
        this.onSelectVendor = e.data

      }
    });
    return await modal.present();
  }

  async openNewCustomerModal() {
    const modal = await this.modalController.create({
      component: NewVendorComponent,
      //cssClass: 'newOrderModalForm',
      canDismiss: true,
      componentProps: {
        //nav: this.nav,
        title: 'Add Company',
        redirectAfterPost: false
      },

    });

    modal.onWillDismiss().then(async (e) => {
      console.log(e);

      if (!e.data) {
        console.log('no modify');
        return
      } else {
        //this.loadProjects(this.currentSegment);
        //this.projects = await this.project.getProjectsList_rev('?page=' + this.page_project + '&items=' + this.size).toPromise().then(e => e.results)

        this.page_customer = 1
        this.page_vendor = 1
        //this.onSelectProject = [e.data]
        this.onPushVendor = e.data
        this.onPushCustomer = e.data
      }
    });

    return await modal.present();
  }



  onAddPort(event: {
    component: IonicSelectableComponent
  }) {
    // Clean form.
    this.order_orders_createForm.reset();

    console.log('onAddPort', event);
    // Copy search text to port name field, so
    // user doesn't have to type again.
    //this.createFormCommitter.controls['name'].setValue(event.component.searchText);

    // Show form.
    //event.component.showAddItemTemplate();
    this.openNewProjectModal()
  }

  onSavePort(event: {
    component: IonicSelectableComponent,
    item//: Port
  }) {
    console.log('onSavePort', event);

    // Fill form.
    //this.createFormCommitter.setValue(event.item);
    //this.portCountryControl.setValue(event.item.country);

    // Show form.
    //event.component.showAddItemTemplate();

    //this.openUpdateProjectModal(event.item)
  }

  onDeletePort(event: {
    component: IonicSelectableComponent,
    item//: Port
  }) {
    // Delete port from storage.
    //this.portService.deletePort(event.item);

    // Delete port from list.
    event.component.deleteItem(event.item);
  }

  searchPorts(event/*:  { component: IonicSelectableComponent; text: string }, type */) {

    console.log(event, event.text);
    //console.log(event.component._modalComponent);
    //console.log(event.component._modalComponent._content);

    //event.component._modalComponent._content.scrollToBottom(1500);
    //event.component._modalComponent._content.scrollY = false

    let url = ''

    const text = event.text.trim().toLowerCase();
    //event.component.startSearch();


    // Close any running subscription.
    if (this.portsSubscription) {
      this.portsSubscription.unsubscribe();
    }


    if (!text) {

      // Close any running subscription.
      if (this.portsSubscription) {
        this.portsSubscription.unsubscribe();
      }


      //event.component.endSearch();
      //event.component.enableInfiniteScroll();
      switch (event['module']) {
        case 'project':
          this.page_project = 1

          url = '?page=' + this.page_project + '&items=' + this.size;

          this.project.getProjectsList_rev(url).subscribe((data) => {
            console.log('!text call more getProjectsList', data);

            //data.results = event.component.items = (data.results);

            //this.numPage_project = data.num_pages;

            //event.component.items = data.results;
            this.onSearchSelector = ''
            this.numPage_project = data.num_pages;
            this.projects = data.results
            //event.component.endInfiniteScroll();
          })
          break;

        case 'customer':
          this.page_customer = 1

          url = '?page=' + this.page_customer + '&items=' + this.size;

          this.customer.getCustomersList(url).subscribe((data) => {
            console.log('!text call more getCustomersList', data);

            //data.results = event.component.items = (data.results);

            //this.numPage_project = data.num_pages;

            //event.component.items = data.results;
            this.onSearchSelector = ''
            this.numPage_customer = data.num_pages;
            this.customers = data.results
            //event.component.endInfiniteScroll();
          })
          break;

        case 'vendor':
          this.page_vendor = 1

          url = '?page=' + this.page_vendor + '&items=' + this.size;

          this.vendor.getVendorsListNoActive(url).subscribe((data) => {
            console.log('!text call more getVendorsListNoActive', data);

            //data.results = event.component.items = (data.results);

            //this.numPage_project = data.num_pages;

            //event.component.items = data.results;
            this.onSearchSelector = ''
            this.numPage_vendor = data.num_pages;
            this.vendors = data.results
            //event.component.endInfiniteScroll();
          })
          break;

        case 'job':
          this.page_project = 1

          url = '?page=' + this.page_project + '&items=' + this.size;

          this.job.getJobsList(url).subscribe((data) => {
            console.log('!text call more getJobsList', data);

            //data.results = event.component.items = (data.results);

            //this.numPage_project = data.num_pages;

            //event.component.items = data.results;
            this.onSearchSelector = ''
            this.numPage_project = data.num_pages;
            this.projects = data.results
            //event.component.endInfiniteScroll();
          })
          break;

        default:
          console.log('Default');

          break;
      }

      return

    }


    switch (event['module']) {
      case 'project':

        url = '?page=' + this.page_project + '&items=25&name=' + text;
        this.portsSubscription = this.project.getProjectsList_rev(url).subscribe((data) => {
          console.log('text call from search getProjectsList', data);
          // Subscription will be closed when unsubscribed manually.
          if (this.portsSubscription.closed) {
            return;
          }
          this.onSearchSelector = text
          this.numPage_project = data.num_pages;
          this.projects = data.results

        })

        break;

      case 'customer':

        url = '?page=' + this.page_customer + '&items=25&name=' + text;
        this.portsSubscription = this.customer.getCustomersList(url).subscribe((data) => {
          console.log('text call from search getCustomersList', data);
          // Subscription will be closed when unsubscribed manually.
          if (this.portsSubscription.closed) {
            return;
          }
          this.onSearchSelector = text
          this.numPage_customer = data.num_pages;
          this.customers = data.results

        })

        break;

      case 'vendor':

        url = '?page=' + this.page_vendor + '&items=25&name=' + text;
        this.portsSubscription = this.vendor.getVendorsListNoActive(url).subscribe((data) => {
          console.log('text call from search getVendorsListNoActive', data);
          // Subscription will be closed when unsubscribed manually.
          if (this.portsSubscription.closed) {
            return;
          }
          this.onSearchSelector = text
          this.numPage_vendor = data.num_pages;
          this.vendors = data.results

        })

        break;

      case 'job':

        url = '?page=' + this.page_project + '&items=25&name=' + text;
        this.portsSubscription = this.job.getJobsList(url).subscribe((data) => {
          console.log('text call from search getJobsList', data);
          // Subscription will be closed when unsubscribed manually.
          if (this.portsSubscription.closed) {
            return;
          }
          this.onSearchSelector = text
          this.numPage_project = data.num_pages;
          this.projects = data.results

        })

        break;



      default:
        break;
    }








  }


  // Metodo para usar con Infinity Scroll en ionic-selectable
  onInfiniteScroll(/* 
    event: { component: IonicSelectableComponent; text: string },
    type: string */ event) {

    console.log(event);
    console.log(this.page_project, this.numPage_project);
    console.log(this.page_customer, this.numPage_customer);
    console.log(this.page_vendor, this.numPage_vendor);
    console.log(this.title);

    let url
    let _type = event['type'] ? event['type'] : null;
    console.log('after', _type);

    switch (_type) {

      case 'project': {

        console.log('page', this.numPage_project);

        url = '?page=' + (++this.page_project) + '&items=' + this.size;

        this.project.getProjectsList_rev(url).subscribe((data) => {
          console.log('onInfiniteScroll', data);
          //event.component.endInfiniteScroll();
          //data.results = event.component.items.concat(data.results);
          this.onSearchSelector = '';
          this.numPage_project = data.num_pages;
          this.projects = data.results;
          //event.component._modalComponent._content.scrollY = true
          //event.component.items = data.results;
        });

        break;
      }
      case 'customer':

        url = '?page=' + (++this.page_customer) + '&items=' + this.size;

        this.customer.getCustomersList(url).subscribe((data) => {
          console.log('!text call more getCustomersList', data);

          //data.results = event.component.items = (data.results);

          //this.numPage_project = data.num_pages;

          //event.component.items = data.results;
          this.onSearchSelector = ''
          this.numPage_customer = data.num_pages;
          this.customers = data.results
          //event.component.endInfiniteScroll();
        })
        break;

      case 'vendor':

        url = '?page=' + (++this.page_vendor) + '&items=' + this.size;

        this.vendor.getVendorsListNoActive(url).subscribe((data) => {
          console.log('!text call more getVendorsListNoActive', data);

          //data.results = event.component.items = (data.results);

          //this.numPage_project = data.num_pages;

          //event.component.items = data.results;
          this.onSearchSelector = ''
          this.numPage_vendor = data.num_pages;
          this.vendors = data.results
          //event.component.endInfiniteScroll();
        })
        break;

      case 'job': {

        console.log('page', this.numPage_project);

        url = '?page=' + (++this.page_project) + '&items=' + this.size;

        this.job.getJobsList(url).subscribe((data) => {
          console.log('onInfiniteScroll', data);
          //event.component.endInfiniteScroll();
          //data.results = event.component.items.concat(data.results);
          this.onSearchSelector = '';
          this.numPage_project = data.num_pages;
          this.projects = data.results;
          //event.component._modalComponent._content.scrollY = true
          //event.component.items = data.results;
        });

        break;
      }


      default:
        console.log('Default');

        break;

      //.... more code case
    }



  }



  filterPorts(ports: any[], text: string) {
    return ports.filter(port => {
      return port.name.toLowerCase().indexOf(text) !== -1 ||
        port.code.toLowerCase().indexOf(text) !== -1;
    });
  }

  submitFormProject(event, type) {
    console.log('Submit Project', event);
    this.order_orders_create(event)

  }


  Cancel() {
    console.log(this.projectModel);
    this.projectModel.hideAddItemTemplate()
  }



  onSelect(event) {
    console.log(event);
    if (event.isSelected/*  && !this.project_in */) {
      if (event.module == 'project') {
        this.onSelectProject = event.item
      } else if (event.module == 'job') {
        this.onSelectProject = event.item
        this.order_orders_createForm.controls['job_id'].setValue(event.item.id)
      } else if (event.module == 'customer') {
        this.onSelectCustomer = event.item
        this.order_orders_createForm.controls['customer_id'].setValue(event.item.id)
      } else if (event.module == 'vendor') {
        this.onSelectVendor = event.item
        this.order_orders_createForm.controls['vendor_id'].setValue(event.item.id)

      }
    } else if (!event.isSelected /* && !this.project_in */) {
      if (event.module == 'project') {
        this.onSelectProject = ''
      } else if (event.module == 'job') {
        this.onSelectProject = ''
        this.order_orders_createForm.controls['job_id'].setValue(0)
      } else if (event.module == 'customer') {
        this.onSelectCustomer = ''
        this.order_orders_createForm.controls['customer_id'].setValue(0)
      } else if (event.module == 'vendor') {
        this.onSelectVendor = ''
        this.order_orders_createForm.controls['vendor_id'].setValue(0)

      }
    }
  }


}
