import { AfterViewInit, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { IonNav, IonSegment, ModalController, Platform } from '@ionic/angular';
import { WizardComponent } from '../components/clarity/wizard/wizard.component';

import { RenderDocumentsPage } from '../documents/render-documents/render-documents.page';
import { InvoicesListPage } from '../invoices/invoices-list/invoices-list.page';
import { DiscountLineComponent } from '../orders/components/discount-line/discount-line.component';
import { ModalOrderCreateUpdateComponent } from '../orders/components/modal-order-meta/modal-order-meta.component';
import { NewOrderComponent } from '../orders/new-order/new-order.component';
import { OrderUpdatePage } from '../orders/order-update/order-update.page';
import { OrdersListPage } from '../orders/orders-list/orders-list.page';
import { ProductsListPage } from '../products/products-list/products-list.page';
import { ProjectsListPage } from '../projects/projects-list/projects-list.page';
import { ReportsListPage } from '../reports/reports-list/reports-list.page';
import { SubMenuService } from '../services/utils/sub-menu/sub-menu.service';
import { TitleAppService } from '../services/utils/title-app/title-app.service';
import { ProfileUserPage } from '../users/profile-user/profile-user.page';
import { UsersListPage } from '../users/users-list/users-list.page';
import { NewVendorComponent } from '../vendors/new-vendor/new-vendor.component';
import { VendorsListPage } from '../vendors/vendors-list/vendors-list.page';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';
import { JobsListPage } from '../jobs/jobs-list/jobs-list.page';
import { PlatformService } from '../services/utils/platform/plarformservice';


@Component({
  selector: 'app-menu-desktop',
  templateUrl: './menu-desktop.page.html',
  styleUrls: ['./menu-desktop.page.scss'],
})
export class MenuDesktopPage {
  myInput: any;
  orders: any;
  listLiks:any = []

  // listLiks = [
  //   {
  //     id: 5,
  //     name: 'COMMESSE',
  //     code: 'projects',
  //     title: 'Projects',
  //     sub: 'Gestione progetti',
  //     desc: 'Gestione progetti',
  //     icon: 'layers-outline',
  //     color: '#f2f2f2f2',
  //     bgColor: '#9d3e3e',
  //     image: 'assets/img/projects.png',
  //     disable: false
  //   },
  //   /* { id: 4, name: 'ORDINI', code: 'order', title: 'Orders', sub: 'Gestione ordini', desc: 'Gestione ordini e fatture', icon: 'prism-outline', color: '#90e3db', bgColor: '#009688', image: 'https://img.freepik.com/free-vector/podium-wallpaper-with-geometrical-3d-shapes_52683-61178.jpg?t=st=1646955179~exp=1646955779~hmac=e934a45e1263bb9d6a172b0a41c753f6f602901ffa3b9398735ab3632c7edeb6&w=1380' },
  //   { id: 6, name: 'FATTURE', code: 'invoice', title: 'Invoices', sub: 'Gestione fatture', desc: 'Gestione fatture', image: 'https://img.freepik.com/free-vector/podium-wallpaper-with-geometrical-3d-shapes_52683-61178.jpg?t=st=1646955179~exp=1646955779~hmac=e934a45e1263bb9d6a172b0a41c753f6f602901ffa3b9398735ab3632c7edeb6&w=1380' },
  //    */
  //   {
  //     id: 2,
  //     name: 'ANAGRAFICHE',
  //     code: 'vendor',
  //     title: 'Vendors',
  //     sub: 'Gestione fornitori',
  //     desc: 'Gestione fornitori e anagrafica',
  //     icon: 'people-outline',
  //     color: '#f2f2f2f2',
  //     bgColor: '#4169E1',
  //     image: 'assets/img/vendors.png',
  //     disable: false
  //   },
  //   {
  //     id: 3,
  //     name: 'PRODOTTI',
  //     code: 'mahazin',
  //     title: 'Products',
  //     sub: 'Gestione prodotti',
  //     desc: 'Gestione prodotti e magazzino',
  //     icon: 'cube-outline',
  //     color: '#f2f2f2f2',
  //     bgColor: '#c1be3a',
  //     image: 'assets/img/projects.png',
  //     disable: false
  //   },
  //   {
  //     id: 8,
  //     name: 'ORDINI',
  //     code: 'order',
  //     title: 'Orders',
  //     sub: 'Gestione ordini',
  //     desc: 'Gestione ordini',
  //     icon: 'prism',
  //     color: '#f2f2f2f2',
  //     bgColor: '#8bc34a',
  //     //bgColor: '#ffe8e8',
  //     image: 'assets/img/orders.png',
  //     disable: false
  //   },
  // /*   {
  //     id: 11,
  //     name: 'COMMESSE',
  //     code: 'job',
  //     title: 'Orders',
  //     sub: 'Gestione ordini',
  //     desc: 'Gestione ordini',
  //     icon: 'prism',
  //     color: '#6b923e',
  //     bgColor: '#cdefa4',
  //     image: 'assets/img/orders.png',
  //     disable: false
  //   }, */
  //   {
  //     id: 1,
  //     name: 'DOCUMENTI',
  //     code: 'documan',
  //     title: 'Documenti',
  //     sub: 'Gestione documentale',
  //     desc: 'Gestione documenti e archivio',
  //     icon: 'folder-outline',
  //     //color: '#f7bcd1' /* '#570a24' */,
  //     color:'#f2f2f2f2',
  //     bgColor: '#e91e63',
  //     image: 'assets/img/documents.png',
  //     disable: true
  //   },
  //   {
  //     id: 7,
  //     name: 'REPORTS',
  //     code: 'reports',
  //     title: 'Reports',
  //     sub: 'Gestione reports',
  //     desc: ' ',
  //     icon: 'stats-chart-outline',
  //     color: '#f2f2f2f2',
  //     bgColor: '#009688',
  //     image: 'assets/img/reports.png',
  //     disable: false
  //   },
  //   {
  //     id: 7,
  //     name: 'TIMESHEET',
  //     code: 'timesheet',
  //     title: 'timesheet',
  //     sub: 'Gestione Timesheet',
  //     desc: ' ',
  //     icon: 'time-outline',
  //     color: '#f2f2f2f2',
  //     bgColor: '#873d78',
  //     image: 'assets/img/reports.png',
  //     disable: false
  //   },
  //   {
  //     id: 7,
  //     name: 'Recruitment',
  //     code: 'hr-management',
  //     title: 'HRmanagement',
  //     sub: 'Gestione HR',
  //     desc: ' ',
  //     icon: 'man-outline',
  //     color: '#515151',
  //     bgColor: '#e9e9e9',
  //     image: 'assets/img/reports.png',
  //     disable: false,
  //   },
  //   {
  //     id: 7,
  //     name: 'Gare',
  //     code: 'tenders',
  //     title: 'Gare',
  //     sub: 'Gestione Gare',
  //     desc: ' ',
  //     icon: 'file-tray-full-outline',
  //     color: '#f2f2f2f2',
  //     bgColor: '#dd751b',
  //     image: 'assets/img/reports.png',
  //     disable: false,
  //   },
  //   {
  //     id: 7,
  //     name: 'Profilo',
  //     code: 'profile',
  //     title: 'Profilo',
  //     sub: 'Profilo utente',
  //     desc: 'Info account',
  //     icon: 'person-circle-outline',
  //     color: '#f2f2f2f2',
  //     bgColor: '#795548',
  //     //bgColor: '#a9e9ff',
  //     image: 'https://img.freepik.com/free-vector/podium-wallpaper-with-geometrical-3d-shapes_52683-61177.jpg?w=1060'
  //   },
  //   {
  //     id: 8,
  //     name: 'Stores',
  //     code: 'stores',
  //     title: 'Stores',
  //     sub: 'Magazzini',
  //     desc: 'Info magazzini',
  //     icon: 'storefront-outline',
  //     color: '#f2f2f2f2',
  //     bgColor: '#678dbd',
  //     //bgColor: '#a9e9ff',
  //     image: 'assets/img/stores.png',
  //   },
  //   // {
  //   //   id: 9,
  //   //   name: 'Manutenzione',
  //   //   code: 'photo-report',
  //   //   title: 'Manutenzione',
  //   //   sub: 'Form foto',
  //   //   desc: 'Invia Manutenzione',
  //   //   icon: 'camera-outline',
  //   //   color: '#eec7b9',
  //   //   bgColor: '#986499',
  //   //   //bgColor: '#a9e9ff',
  //   //   image: 'assets/img/stores.png',
  //   // },
  // ]


  private animationItem!: AnimationItem;
  private animationItem2!: AnimationItem;

  options: AnimationOptions = {
    name: 'test',
    path: '/assets/lottie/empty-box.json',
    autoplay: false,
    loop: false
  };

  options2: AnimationOptions = {
    name: 'test',
    path: '/assets/lottie/profile-setup.json',
    autoplay: false,
    loop: false
  };

  segment = 0;
  prev = ''

  @ViewChild(WizardComponent) wizardComp: WizardComponent;
  @ViewChild('seg', { static: true }) seg: IonSegment;
job: any;
projectId: any;
cache: any;
isMobile = ''
  constructor(
    // //private nav: IonNav,
    private renderer2: Renderer2,
    private platform: Platform,
    private submenuService: SubMenuService,
    private modalController: ModalController,
    private titleAppService: TitleAppService,
    private authService: AuthenticationService,
    private router: Router,
    private platformService: PlatformService

  ) {
    console.log(this.platformService.getPlatform().valueOf());
    this.isMobile = this.platformService.getPlatform().valueOf()


    this.router.events.pipe(
      filter(event => event instanceof NavigationStart || event instanceof NavigationEnd)
    ).subscribe(event => {
      console.log(event);
    });

    //this.listLiks = []
    this.platform.ready().then(e => {
      console.log(e);

      

    })
    this.segment = 0;

    
    this.authService.getLocalData('auth-profile').then(e => {
      console.log(e);
      this.listLiks = e?.user_data?.applications
            
      if((e.user_data.email.includes('test@coolprojects.it') ||e.user_data.email.includes('maurizio.lamotta@coolprojects.it')) && this.isMobile === 'mobile'){
        this.listLiks = e?.user_data?.applications.filter(e => e.code === 'order')
      }
      if(e.user_data.email.includes('mauro.maimone@coolprojects.it')){
        let obj = 'this.listLiks.find(element => element.id === 9);'

      }
    })

    if(!this.authService.isAuthenticated()){
      console.log(this.authService.isAuthenticated());
      this.router.navigate(['/login'])
    }

  }




  animationCreated(animationItem: AnimationItem): void {
    console.log('animationItem', animationItem);
    if (animationItem) {
      this.animationItem = animationItem;
      this.animationItem.stop()
      //this.animationItem.goToAndStop()
      ////this.animationItem.play()
      ////this.animationItem.setSpeed(2 )
    }
  }


  animationCreated2(animationItem: AnimationItem): void {
    console.log('animationItem2', animationItem);
    if (animationItem) {
      this.animationItem2 = animationItem;
      this.animationItem2.stop()
      //this.animationItem.goToAndStop()
      ////this.animationItem.play()
      ////this.animationItem.setSpeed(2 )
    }
  }

  goToOrder(id) {
    console.log(id);
    // //nav: this.nav(OrderDetailsPage, { id: id })

  }

  ionViewWillEnter() {
    this.titleAppService.title.next('')
    return
    // //nav: this.nav(RenderDocumentsPage, { //nav: this.nav })



    // setTimeout(async () => {
    //   console.log(this.wizardComp);
    //   /* 
    //         this.wizardComp.title = 'Benvenuto'
    //         this.wizardComp._open = !this.wizardComp._open */

    //   return
    //   const modal = await this.modalController.create({
    //     component: OrderDetailsPage,
    //     cssClass: 'fullscreen',
    //     //backdropDismiss: false,
    //     mode: 'md',
    //     componentProps: {
    //       id: 60402,
    //       /*  project_id: 895,
    //        title: 'New Company', */
    //       // //nav: this.nav
    //     }
    //     //presentingElement: t  his.routerOutlet.nativeEl,
    //   })
    //   return await modal.present();


    // }, 1300);

  }

  goTo(component) {
    ////nav: this.nav(component, { routerDirection: 'forward' })
    console.log(component)
    /* if (component.name == 'ORDINI') {
      this.animationItem.goToAndPlay(0)
    } else if (component.name == 'Profilo') {
      this.animationItem2.goToAndPlay(0)
    } */
      this.router.navigate(['/'+component.code])
    
      // this.submenuService.component.next(component.code)

    //this.tabs.loadTabComponent(component.code)

  }

  async segmentChanged(event) {

    //await this.slider.slideTo(this.segment);
    //let prev = await this.slider.getPreviousIndex();


  }

  onScroll(event) {

    if (event.detail.currentY >= 100) {
      //console.log(event);
      //console.log(this.fab);
    } else if (event.detail.currentY <= 99) {
      //console.log(event);
      //console.log(this.fab);
    }
  }


  async openPopoverDiscountsToolbar(event) {

    console.log(event);

    const popover = await this.modalController.create({
      component: DiscountLineComponent,
      //event: event,
      backdropDismiss: true,
      //cssClass: 'popover-setting',
      componentProps: {
        order: { id: 60362 },
        event: event,
        type: 'global'
      },
      //translucent: true
    });



    return await popover.present();
    /** Sync event from popover component */

  }
}
