import { TasksListPage } from './../tasks/tasks-list/tasks-list.page';
import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ContentChildren, ElementRef, HostListener, Input, OnChanges, OnInit, QueryList, Renderer2, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { DomController, IonContent, IonNav, MenuController, ModalController, NavController, Platform, PopoverController } from '@ionic/angular';
import { ModalInfoFileComponent } from '../components/media/modal-info-file/modal-info-file.component';
import { RenderDocumentsPage } from '../documents/render-documents/render-documents.page';
import { HomePage } from '../home/home.page';
import { OrdersListPage } from '../orders/orders-list/orders-list.page';
import { ProjectsListPage } from '../projects/projects-list/projects-list.page';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { ProfileUserPage } from '../users/profile-user/profile-user.page';
import { VendorsListPage } from '../vendors/vendors-list/vendors-list.page';
import { MenuMobilePage } from '../menu-mobile/menu-mobile.page';
import { MenuDesktopPage } from '../menu-desktop/menu-desktop.page';
import { SubMenuService } from '../services/utils/sub-menu/sub-menu.service';
import { InvoicesListPage } from '../invoices/invoices-list/invoices-list.page';
import { ProductsListPage } from '../products/products-list/products-list.page';
import { RegisterPage } from '../public/register/register.page';
import { UsersListPage } from '../users/users-list/users-list.page';
import { NewProjectComponent } from '../projects/new-project/new-project.component';
import { ReportsListPage } from '../reports/reports-list/reports-list.page';
import { WorkersListPage } from '../workers/workers-list/workers-list.page';
import { PlatformService } from '../services/utils/platform/plarformservice';
import { fromEvent, of } from 'rxjs';
import { ThemeService } from '../services/theme/theme.service';
import { OffersListPage } from '../offers/offers-list/offers-list.page';
import { environment } from 'src/environments/environment';
import { TitleAppService } from '../services/utils/title-app/title-app.service';
import { map, pairwise, switchMap, tap } from 'rxjs/operators';
import { StoresListPage } from '../stores/stores-list/stores-list.page';
import { JobsListPage } from '../jobs/jobs-list/jobs-list.page';
import { forwardRef } from '@angular/core';
import { VendorsService } from '../services/vendors/vendors.service';
import { CustomersService } from '../services/customers/customers.service';
import { OrdersService } from '../services/orders/orders.service';
import { ReportDetailsPage } from '../reports/reports-details/reports-details.page';
import { TimesheetPage } from '../timesheet/timesheet.page';


@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.page.html',
  styleUrls: ['./tab-bar.page.scss'],
})
export class TabBarPage implements  AfterViewInit {

  canGoBack = false;
  canGoBack0 = false;
  canGoBack1 = false;
  canGoBack2 = false;
  canGoBack3 = false;
  canGoBack4 = false;

  tabIndex = 0;
  prev

  env = environment.production

  tab0 = HomePage;
  tab1 = ProjectsListPage;
  tab2 = RenderDocumentsPage;
  tab3 = ProfileUserPage;
  tab4 = VendorsListPage
  tab5 = OrdersListPage
  tab7 = JobsListPage
  tab6 = MenuMobilePage
  tab6_1 = MenuDesktopPage
  tab8 = StoresListPage

  title = '';
  icon = 'home-outline';

  selectTab
  articles: any[];

  tabs = [];
  companies: any = [];



  nav: IonNav;
  @ContentChildren(forwardRef(() => TabBarPage)) superTabs: QueryList<TabBarPage>;
  @ViewChild('nav-icon1') nav_icon1: ElementRef
  @ViewChild('header', { read: ElementRef }) header: ElementRef;
  @ViewChild('condenseheader', { read: ElementRef }) condenseheader: ElementRef;
  @ViewChild('toolbar', { read: ElementRef }) toolbar: ElementRef;
  @ViewChild('content') content: ElementRef;
  @ViewChild(IonContent) ionContent: IonContent;
  @Input() type: string;

  footerHidden = false
  private toolbarHeight = 60;
  deltaScrollY
  _component: any;
  componentsList = [];
  display = 'none'
  clickToggleMenu = true;
  subscription

  n_tabs = 0;
  n_tabs_limit = 2;


  animateHeader = true;
  iconName = ''
  showIcon = false

  constructor(
    private modalController: ModalController,
    private popoverController: PopoverController,
    private plt: Platform,
    private ref: ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private auth: AuthenticationService,
    private submenuService: SubMenuService,
    private platformService: PlatformService,
    private menu: MenuController,
    private renderer: Renderer2,
    private domCtrl: DomController,
    private themeService: ThemeService,
    private titleAppService: TitleAppService,
    private vendorApi: VendorsService,
    private customerApi: CustomersService,
    private orderApi: OrdersService


  ) {

    
    this.themeService.dark.subscribe(e => {
      console.log(e);

      if(e === undefined || e == true){
        this.iconName = ''
        this.showIcon = false
      }
      else{
        this.iconName = 'garland'
        this.showIcon = true

      }

    })

    this.n_tabs = 0;

    this.titleAppService.title.subscribe(title => {
      this.animateHeader = false;

      setTimeout(() => {
        console.log('titlePage change:', title);
        this.title = title
        this.animateHeader = true
      }, 0);

    })

    /* prevent back on browser */
    history.pushState(null, null, location.href);
    this.subscription = fromEvent(window, 'popstate').subscribe(_ => {
      history.pushState(null, null, location.href);
      console.log(`You can't make changes or go back at this time.`, 'Okay');

      this.modalController.getTop().then((e) => {
        console.log('modalController', e);
        if (e)
          this.modalController.dismiss();
        else
          this.goBack();
      }).catch(e => {
        //this.goBack();

      })

      this.popoverController.getTop().then((e) => {
        console.log('popoverController', e);
        if (e)
          this.popoverController.dismiss();
        //else
        //this.goBack();
      }).catch(e => {
        //this.goBack();

      })

    });

    console.log(this.platformService.getPlatform().valueOf());

    if (this.platformService.getPlatform().valueOf() == 'mobile') {
      this.articles = [
        //{ id: 1, name: 'Home', code: 'home', icon: 'home' },
        { id: 2, name: 'Home', code: 'module', icon: 'home' },

        { id: 4, name: 'Profilo', code: 'profile', icon: 'person' }
      ]
      this.display = 'block';
    } else {
      this.articles = [
        //{ id: 1, name: 'Home', code: 'home', icon: 'home' },
        { id: 2, name: '', code: 'module', icon: 'grid' }
      ]
      this.display = 'none';
    }

    this.submenuService.component.subscribe(async e => {
      console.log(e);
      let _comp = ''
      let params = '' // :1 - :2 - :3

      _comp = e
      if (e.split(':')[1]) {
        params = e.split(':')[1];
        _comp = e.split(':')[0];
      }


      if (this.prev == e) {
        return //this.nav.popToRoot({ duration: 0 })
      }

      if (_comp != 'register') {

        this.prev = e;
        let comp = this.loadTabComponent(_comp)
        console.log(comp, params);

        console.log(this.nav)
        this.nav.push(comp, { dataProps: params }, { animated: true, direction: 'forward' });

      } else {
        const modal = await this.modalController.create({
          component: RegisterPage,
          canDismiss: true,
          cssClass: 'fullscreen',
          componentProps: {
            title: 'Add New Order',
            parent_type: 'root'
          }
          //presentingElement: this.routerOutlet.nativeEl,
        })


        modal.onWillDismiss().then((e) => {
          if (e.data && e.data.status != -1) {
            /* this.page_number = 1;
            this.myInput.value = "";
            this.orders = [];
            this.getOrders(false, '') */
          }
        });
        return await modal.present();
      }



    })

    this.submenuService.open.subscribe(e => {
      console.log(e);
      this.clickToggleMenu = e

    })

  }

  addNewTab() {
    if (this.plt.is('cordova')) {
      if (this.n_tabs <= this.n_tabs_limit) {
        console.log('new-tab');
        this.articles.push({ id: 3, name: 'Tab' + this.n_tabs, code: 'module', icon: 'cube' },)
        this.n_tabs++
      }
    }
    //this.menu.toggle('features')
    //this.menu.toggle('features')
  }

  onScroll($event) {
    //console.log($event);

    this.deltaScrollY = $event.detail.scrollTop
    let newPosition = - (this.deltaScrollY / 5);

    if (newPosition < -this.toolbarHeight) {
      newPosition = -this.toolbarHeight;
    }

    let newOpacity = 1 - (newPosition / -this.toolbarHeight);

    /* this.domCtrl.write(() => {
      this.renderer.setStyle(this.toolbar, 'top', newPosition + 'px');
      this.renderer.setStyle(this.toolbar, 'opacity', newOpacity);
    }); */



    this.renderer.setStyle(this.toolbar.nativeElement, 'transition', '.5s');

    // used a couple of "guards" to prevent unnecessary assignments if scrolling in a direction and the var is set already:
    //if ($event.detail.scrollTop > 0 && this.footerHidden) return;
    if ($event.detail.scrollTop <= 0 && !this.footerHidden) return;
    if ($event.detail.scrollTop > 15) {
      //console.log("scrolling down, hiding footer...", $event.detail.scrollTop);
      this.footerHidden = true;
      this.domCtrl.write(() => {
        this.renderer.setStyle(this.toolbar.nativeElement, 'top', -this.toolbarHeight + 'px');
        this.renderer.setStyle(this.toolbar.nativeElement, 'opacity', '0');
        //this.renderer.setStyle(this.condenseheader.nativeElement, 'opacity', '1');

      });
    } else {
      //console.log("scrolling up, revealing footer...");
      this.footerHidden = false;
      this.domCtrl.write(() => {
        this.renderer.setStyle(this.toolbar.nativeElement, 'top', '0px');
        this.renderer.setStyle(this.toolbar.nativeElement, 'opacity', '1');
        //this.renderer.setStyle(this.condenseheader.nativeElement, 'opacity', '0');
      });
    };


  }


  loadTabComponent(_selectedTab: string) {
    this.title = _selectedTab
    console.log('componment ' + _selectedTab)
    if (_selectedTab == "home") {
      this.title = ''
      //return HomePage;
      return MenuDesktopPage;
    }
    else if (_selectedTab == "documan")
      return RenderDocumentsPage;
    else if (_selectedTab == "profile")
      return ProfileUserPage;
    else if (_selectedTab == "vendor")
      return VendorsListPage;
    else if (_selectedTab == "mahazin")
      return ProductsListPage;
    else if (_selectedTab == "stores")
      return StoresListPage;
    else if (_selectedTab == "order")
      return OrdersListPage;
    else if (_selectedTab == "offer")
      return OffersListPage;
    else if (_selectedTab == "projects")
      return ProjectsListPage;
    else if (_selectedTab == "job")
      return JobsListPage;
    /* else if (_selectedTab == "module")
      return MenuDesktopPage; */
    //return MenuMobilePage;
    else if (_selectedTab == "invoice")
      return InvoicesListPage;
    else if (_selectedTab == "register")
      return RegisterPage;
    else if (_selectedTab == "users")
      return UsersListPage;
    else if (_selectedTab == "worker")
      return WorkersListPage;
    else if (_selectedTab == "reports")
      return ReportDetailsPage;
    else if (_selectedTab == "tasks")
      return TasksListPage;
    else if (_selectedTab == "timesheet")
      return TimesheetPage;
    else{
      return ''
    }
  }

  /* link from menu sidebar ee link vari */
  passNav(event) {
    console.log('recevier from single ', event);
    this.canGoBack = event.canGoBack;
    this.canGoBack ? null : this.prev = ''
    this.nav = event.ionNav;
    //this.title = event.ionNav.root.name;
    this.icon = event.icon;
    this.ref.detectChanges();
    /* let e = event.ionNav.ionNavDidChange.subscribe(async ev => {
      console.log('recevier CHANGE : ', ev);
      this.canGoBack = await event.ionNav.canGoBack();
    }) */
    this.renderer.setStyle(this.toolbar.nativeElement, 'top', '0px');
    this.renderer.setStyle(this.toolbar.nativeElement, 'opacity', '1');


  }

  /* link from tab button */
  selected(event) {
    console.log('recevier from Selected ', event);
    this.canGoBack = event.canGoBack;
    this.nav = event.ionNav;
    //this.title = event.title;
    this.icon = event.icon;
    this.ref.detectChanges();
    /* event.ionNav.ionNavDidChange.subscribe(async ev => {
      console.log('recevier CHANGE TAB0: ', ev);
      this.canGoBack = await event.ionNav.canGoBack();
    }) */
    this.renderer.setStyle(this.toolbar.nativeElement, 'top', '0px');
    this.renderer.setStyle(this.toolbar.nativeElement, 'opacity', '1');


  }

  enableView = false
  ngAfterViewInit() {


    this.auth.getLocalData('auth-profile').then(e => {
      console.log(e);
      if(e.user_data.email.includes('mauro.maimone@coolprojects.it')){
        this.enableView = true
      }
      else{
        this.enableView = false
      }
      this.articles = this.articles.sort(function (a, b) {
        var x = a['id']; var y = b['id'];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      });
      /* this.articles = e.applications.sort(function (a, b) {
        var x = a['id']; var y = b['id'];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      }); */


    })


    this.plt.backButton.subscribeWithPriority(10, (processNextHandler) => {

      this.modalController.getTop().then((e) => {
        console.log('modalController', e);
        if (e)
          this.modalController.dismiss();
        else
          this.goBack();
      }).catch(e => {
        this.goBack();

      })
    })
  }

  goBack() {
    console.log('goBack', this.canGoBack);

    let disable = false;

    if (this.canGoBack) {
      this.titleAppService.title.pipe(pairwise()).subscribe(([previous, current]) => {
        console.log(previous, current);

        if (previous !== current) {
          // Some code to process if the new value was different.

        }

      })

      this.nav.pop({ animated: false, direction: 'back' });


    } else {
      this.prev = ''
      this.menu.toggle('pages')

      // this.st.selectTab(this.tabs[0])
    }

  }

  onTabChange(event) {
    console.log(event, this.superTabs);

    this.selectTab = event;

  }

  infoUtils() {
    console.log('click link to user manual guide');
    this.clickToggleMenu = !this.clickToggleMenu;
    this.submenuService.open.next(this.clickToggleMenu)
  }

  callVar(str) {
    return this[str]
  }



  hamburgerMenu() {
    this.clickToggleMenu = !this.clickToggleMenu;
    this.submenuService.open.next(this.clickToggleMenu)

    console.log(this.nav_icon1);

    if (!this.clickToggleMenu)
      this.renderer.addClass(this.nav_icon1.nativeElement, 'open')
    else
      this.renderer.removeClass(this.nav_icon1.nativeElement, 'open')

  }

  // getVendorCustomerList() {

  //   let page_ = 1;
  //   let url = '?page=' + page_ + '&items=' + 200000;

  //     //Vendor
  //     this.vendorApi.getVendorsList(url).subscribe(e => {
  //       console.log(e);
  //       this.companies = e.results;
  //       this.vendorApi.setVendors(this.companies)
  //       /* if (e.num_pages > 1) {
  //         for (let i = 2; i <= e.num_pages; i++) {
  //           console.log(i);
  //           page_++
  //           let url = '?page=' + page_ + '&items=' + 25;
  //           this.vendorApi.getVendorsList(url).subscribe(e => {
  //             console.log(e);
  //             this.companies.push(...e.results);
              
  //           })
  
  //         }
  
  //       } */
  //     })
  //   }

copyText = false
  copyToClipboard(text: string): void {
    const match = text.match(/\[(.*?)\]/);
    if (match && match[1]) {
      const textToCopy = match[1];
      navigator.clipboard.writeText(textToCopy).then(() => {
        console.log('Testo copiato negli appunti:', textToCopy);
        // Mostra un messaggio all'utente, se necessario
        this.copyText = true
        setTimeout(() => {
          this.copyText = false
        }, 1000);
      }).catch(err => {
        console.error('Errore durante la copia negli appunti:', err);
      });
    } else {
      console.log('Nessun testo tra parentesi quadre trovato');
      // Gestisci il caso in cui non ci sono parentesi quadre
    }
  }

  test(){
    let url = '?page=1&items=100'

    this.orderApi.order_orders_list(url).subscribe( e=>{
      console.log(e);
      let a = e.results.filter(e => e.creator == 23)
      console.log(a);
      
     })
  }
  
}
