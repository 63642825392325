
import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavController, ModalController, IonNav, IonInfiniteScroll, IonSearchbar, IonContent, IonSegment, IonSegmentButton, IonMenu, MenuController, LoadingController, PopoverController, IonDatetime } from '@ionic/angular';

import { animate, query, sequence, stagger, state, style, transition, trigger } from '@angular/animations';

import { TabsService } from 'src/app/services/tabs/tabs.service';

import { ColumnChangesService, ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { SubMenuService } from 'src/app/services/utils/sub-menu/sub-menu.service';
import { CurrencyPipe } from '@angular/common';
import { PlatformService } from 'src/app/services/utils/platform/plarformservice';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

interface PageInfo {
  offset: number;
  pageSize: number;
  limit: number;
  count: number;
}
/**
 * An object used to get page information from the server
 */
export class Page {
  limit: number = 25;
  // The number of elements in the page
  size: number = 25;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 1;
}

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('180ms', animate('300ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave',
      animate('200ms', style({ opacity: 0 })),
      { optional: true }
    )
  ])
]);

class CurrencyOnlyPipe extends CurrencyPipe {
  public override transform(value, digitInfo, locale): any {
    return super.transform(value, 'EUR', 'symbol', digitInfo, locale);
  }
}
@Component({
  selector: 'app-list-dates',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListDateComponent implements OnInit, AfterViewInit, OnChanges {


  n = 0
  public data: any; // Data;
  public columns: any;
  public rows = new Array<any>()

  rowIsNotExpanded = true

  editing = {};

  selected = [];

  funder = [];
  calculated = [];
  pending = [];
  ColumnMode: ColumnMode
  expanded: any = {};
  timeout: any;
  total_order = 0;
  currentSegment: string = "all";

  module = 'orders'
  @Input() orderId;
  @Input() order;
  @Input() jobId;
  @Input() cache;

  orders;
  @Output() clicked = new EventEmitter<any>();
  @Output() clickedCategory = new EventEmitter<any>();
  @Output() sendOrders = new EventEmitter<any>();
  @Output() switch = new EventEmitter<any>();

  @Output() emitterOpenNewOrder = new EventEmitter<any>();

  @ViewChild('list') list: ElementRef;
  @ViewChild('categories') categories: ElementRef;

  @ViewChild('editRowTemplate') editRowTemplate: TemplateRef<any>;
  @ViewChild('dateStartTemplate') dateStartTemplate: TemplateRef<any>;
  @ViewChild('dateEndTemplate') dateEndTemplate: TemplateRef<any>;

  @ViewChild('segment') segment: IonSegment;
  @ViewChild('btn_segment') btn_segment: IonSegmentButton;
  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonContent) content: IonContent;


  fab: boolean = false;
  customAlertOptions: any = {
    header: 'List groups',
    //subHeader: 'Select group for user',
    message: 'Select group for user',
    translucent: true
  };

  searching = false
  res: any = []
  order_status = [];
  order_types = [];
  order_categories = [];

  page = new Page();

  total_page = 0;
  page_number = 1;
  page_limit = 30;

  isSearch: boolean = false;
  isLoad: boolean = false;

  queryString = [{
    search_by: '',
    search_data: ''
  }];

  queryFilter = {
    search_by: 'sort',
    search_data: [{ prop: 'priority.id', dir: 'asc' }]
  };


  totalElements: number;
  pageNumber: number = 1;

  isLoading = 0;
  typeData = true


  navMenu = [
    { action: 'toggleSearch', label: 'Search' },
    { action: 'openMenu', label: 'Filter' },
  ]


  filterItems: any = [
    {
      search_by: 'status', //name //
      search_data: ''
    },
  ]

  activeStatusOrder
  stringStatusOrderFilter = '--'

  heightTable
  innerHeight

  sortedCategories: any = []
  tableOrder = false

  isMobile = false


  order_orders_createForm: FormGroup;

  minDate = moment("2020-01-01").add(1, 'd').format()
  maxDate = moment("2020-01-01").format();


  @ViewChild('popoverDatetime') popoverDatetime: IonDatetime;
  @ViewChild('popoverDatetime2') popoverDatetime2: IonDatetime;

  constructor(
    private fb: FormBuilder,
    private menu: MenuController,
    private subMenu: SubMenuService,
    private cd: ChangeDetectorRef,
    private navCtrl: NavController,
    private api: OrdersService,
    private modalController: ModalController,
    //private nav: IonNav,
    private tabService: TabsService,
    private renderer: Renderer2,
    private columnChangesService: ColumnChangesService,
    private el: ElementRef,
    private loaderCtrl: LoadingController,
    private utils: PlatformService,
    private popCtrl: PopoverController,
    private loadCtrl: LoadingController
  ) {

    console.log('list orders constructor');

    this.page.size = 25;
    var width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;

    this.isMobile = width < 530

    this.utils.autoWidth.subscribe(e => {
      this.isMobile = e < 530
    })

    this.callFilterItem()
  }


  hasEditAction(actions: any[]): boolean {
    return actions?.some(action => action.code === 'CAN_U') ?? false;
  }


  createBaseForm() {



    this.order_orders_createForm = this.fb.group({
      order_id: this.fb.control(this.orderId, Validators.required),
      type_id: this.fb.control('', [Validators.required, Validators.minLength(3)]),
      date_start: this.fb.control(moment(new Date()).format('YYYY-MM-DD'), Validators.required),
      date_end: this.fb.control(null),
    });


    this.order_orders_createForm.valueChanges.subscribe(e => {
      console.log(e);

    })




  }
  // forza il reset al l'entrata
  callFilterItem() {

    let readFilter = {};

    try {
      //readFilter = JSON.parse(localStorage.getItem('filter_orders'))
      console.log(readFilter, this.filterItems);


      /*       if (readFilter == null) {
              localStorage.setItem('filter_orders', JSON.stringify(this.filterItems))
            } else {
              this.filterItems = readFilter;
            } */

      readFilter[0].search_data = ''
      readFilter[1].search_data = ''
      //localStorage.setItem('filter_orders', JSON.stringify(readFilter))



    }
    catch {
      console.log('error');
      //localStorage.setItem('filter_orders', JSON.stringify(this.filterItems))
      this.filterItems = readFilter;
    }



  }

  createColumns() {

    this.columns = [
      //{ prop: 'id', name: 'ID'/* , frozenLeft: true */, minWidth: 75, maxWidth: 75, flexGrow: 0.3, cellClass: 'td-id', headerClass: 'th-id' },
      /* {
        prop: 'id', width: 75, minWidth: 75
      }, */
      
      {
        /* prop: 'cost', */ name: "Actions", maxWidth: 110, width: 110,
        summaryFunc: () => null,
        cellTemplate: this.editRowTemplate,
        //summaryTemplate: this.diffSummaryTemplate,
        sortable: false,
        cellClass: 'td-edit', headerClass: 'th-edit',
      },
      {
        prop: 'type.name', name: 'Order', flexGrow: 1, minWidth: 190, maxWidth: 190,
        cellClass: 'td-priority', headerClass: 'th-priority'
      },
      {
        prop: 'date_start', flexGrow: 1, minWidth: 240, maxWidth: 240, cellClass: 'td-code', headerClass: 'th-code', cellTemplate: this.dateStartTemplate
      },
      {
        prop: 'date_end', flexGrow: 1, minWidth: 240, maxWidth: 240, cellClass: 'td-code', headerClass: 'th-code', cellTemplate: this.dateEndTemplate
      },
    /*   {
        name: 'action',flexGrow: 1, minWidth: 240, maxWidth: 240, cellClass: 'td-code', headerClass: 'th-code', cellTemplate: this.editRowTemplate
      }, */
      //{ prop: 'deleted', maxWidth: 70, cellClass: 'td-deleted', headerClass: 'th-deleted' },



    ]

  }

  ngOnInit() {
    console.log('list orders ngOnInit');

    //this.orders = [];
    this.fab = true

    //this.loadProjects(this.queryString);
    //this.getOrders(false, "", this.queryString)


    this.getOrdersMeta()


  }


  ngAfterViewInit(): void {

    console.log('list orders ngAfterViewInit', this.pageNumber);
    console.log(this.orders);
    console.log(this.orderId);
    console.log(this.cache);
    this.createColumns()


  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if (changes['orders'] && !changes['orders'].firstChange) {

    } else if (changes['cache'] && !changes['cache'].firstChange) {
      this.getOrdersMeta()
    }

    // setTimeout(() => {

    //   console.log(this.list);
    //   console.log(this.list['el'].getBoundingClientRect());
    //   let rect = this.list['el'].getBoundingClientRect()
    //   this.heightTable = rect.top + 0
    //   this.innerHeight = innerHeight;
    //   console.log(innerHeight, rect.top);



    // }, 200)

    /*    
   
         this.total_order = 0;
         this.total_page = 0;
         this.page_number = 0;
   
         this.orders = []
         this.cache = {}
        
         setTimeout(() => {
           this.table.bodyComponent.offsetX = 0;
           this.table.bodyComponent.offsetY = 0;
           this.table.headerComponent.offsetX = 0;
           this.table.recalculateColumns();
           this.orders = [...this.orders];
         }, 100);
         this.loadProjects(this.queryString)
       } */
  }

  openMenu() {
    this.menu.open('admin')
    this.subMenu.params.next({
      title: 'Filter',
      icon: 'funnel-outline',
      accordions: [
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Status'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Priority'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Category'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Type'
        }
      ]
    })
  }







  concateQuery() {
    console.log('concateQuery');
    let params = JSON.parse(localStorage.getItem('filter_orders'))
    //if (this.pageNumber <= 1 && index == -1)
    //this.columns.splice(2, 0, { prop: 'status.name', name: 'Status', width: 95, maxWidth: 95, minWidth: 95, cellClass: 'td-status', headerClass: 'th-status' })
    console.log(params);


    let url = '?page=' + (this.page.pageNumber + 1) + '&items=' + this.page.size;


    if (!params) return url
    params.map((param, i) => {
      let query = '';

      /*  console.log('index', i);
       console.log(param);
       console.log(param != '');
       console.log(param.search_data, param.search_data != ''); */


      if (param && param != '' && param.search_data != '') {
        query += '&' + param.search_by + '=';
        query += param.search_data;


        //let filtered = this.columns.filter(obj => obj.name !== 'Status');


        //console.log(filtered);


        //this.columns = [...this.columns]
        url += query
      } else {
        console.log('else');

      }


    })
    console.log(url);

    return url
  }

  /**
   * @description Get order metadata
   */
  async getOrdersMeta() {

    this.popCtrl.getTop().then(v => v ? this.popCtrl.dismiss() : null);

    let loading = this.loadCtrl.create({
      duration: 16000,
      spinner: 'circular',
      message: 'please wait...',
      mode: 'md',
      keyboardClose: false
    })

    await loading.then(e => e.present())

    this.createBaseForm()

    this.api.order_orderdates_list_by_order_id('page=1&items=25', this.orderId)
      .subscribe(data => {
        console.log(data);
        this.rows = data.results
        this.page.totalElements = data.total_items
        this.page.size = 25
        this.page.pageNumber = 1
        this.rows = [...this.rows]

        this.loadCtrl.dismiss()


        /* this.order_status.push(
             {
              id: 'all',
              name: 'All',
              code: 'ALL'
            }, 
 
         )*/
      })

  }

  triggerColumnChangeDetection(): void {
    this.columnChangesService.onInputChange();
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.collapseAllRows();
    this.table.rowDetail.toggleExpandRow(row);
  }


  sorted(columnProp: string, direction: string) {
    console.log(columnProp, direction);
    if (columnProp == 'priority.name') {
      columnProp = 'priority.id'
    }

    this.queryFilter = { search_by: 'sort', search_data: [{ prop: columnProp, dir: direction }] }
    localStorage.setItem('filter_sort_orders', JSON.stringify({ search_by: 'sort', search_data: [{ prop: columnProp, dir: direction }] }))

    console.log(this.queryFilter);
  }


  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
  }

  /**
   * @description onClick on row open order details
   * @param event pass obj row clicked
   */
  onActivate(event) {
    /* if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    } */
    if (event.type == 'click') {
      console.log('Activate Event', event);
      //this.openOrderModal(event.row)
      this.clicked.emit(event);
    }
  }

  onActivate2(event) {
    /* if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    } */
    let res = {
      type: 'click',
      row: event
    }
    console.log('Activate Event', res);
    //this.openOrderModal(event.row)
    this.clicked.emit(res);

  }

  setExpandStatus(expanded: any): void {
    console.log('setExpandStatus Event', expanded);
  }



  onGetRowClass = (row:any) => {
    if (!row.status)
      return ''
    //console.log(row);
    // id 3 is Delete for Status Order
    if (row.status.id === 4 || row.status.id === 5) {
      return 'deleted';
    } else if (row.status.id === 2) {
      return 'request';
    } else if (row.status.id === 1) {
      return 'draft';
    } else if (row.status.id === 3) {
      return 'order';
    }
    else
      return ''
  }


  /**
   * @description ritorna la somma delle colonne che gli si assegna questa funzione
   * @param n 
   * @returns somma della colonna in "rowSummary" template
   */
  caclulateSumm(n?) {
    console.log();

    this.total_order = 0;
    this.orders.map((item) => {
      this.total_order += item.cost;
      //this.currencyCode = item.currency;
      //console.log(this.total_order);
    });
    return this.total_order.toFixed(2) + ' ' + n;
  }

  setPage(pageInfo) {
    console.log(pageInfo, this.orderId);
    console.log('load api list');


    let loader = this.loaderCtrl.create({
      cssClass: 'loading-in-content',
      message: '',
      duration: 9000,
    }).then((response) => {
      //response.present();
    });

    this.page.pageNumber = pageInfo.offset
    // set the loading flag, which serves two purposes:
    // 1) it prevents the same page from being loaded twice
    // 2) it enables display of the loading indicator
    this.isLoading = 1;

    const index = this.columns.findIndex(item => item.name === "Status");
    console.log(index)


    if (this.page_number <= 1 && index == -1)
      this.columns.splice(1, 0, { prop: 'status.name', name: 'Status', flexGrow: 2, minWidth: 150, maxWidth: 150, cellClass: 'td-status', headerClass: 'th-status' })


    let url = this.concateQuery()


    if (this.orderId) {
      this.api.getOrdersByProjectId(this.orderId, url).subscribe(data => {
        console.log(data);

        this.page.totalElements = data.total_items;
        this.page.totalPages = this.page.totalElements / this.page.size;
        this.rows = data.results
        this.rows = [...this.rows]


        console.log(this.rows);

        this.isLoading = 0;


        //this.loaderCtrl.dismiss()

      });
    } else if (this.jobId) {
      this.api.getOrdersByJobId(this.jobId.id, url).subscribe(data => {
        console.log(data);

        this.page.totalElements = data.total_items;
        this.page.totalPages = this.page.totalElements / this.page.size;
        this.rows = data.results
        this.rows = [...this.rows]


        console.log(this.rows);

        this.isLoading = 0;


        //this.loaderCtrl.dismiss()

      });
    } else {
      this.api.order_orders_list(url).subscribe(data => {
        console.log(data);

        this.page.totalElements = data.total_items;
        this.page.totalPages = this.page.totalElements / this.page.size;
        this.rows = data.results
        this.rows = [...this.rows]


        console.log(this.rows);
        this.sortedCategories = this.rows.map(obj => obj.category.name).sort((a, b) => a.localeCompare(b)).filter((value, index, array) => array.indexOf(value) === index)
        console.log(this.sortedCategories);

        this.isLoading = 0;


        //this.loaderCtrl.dismiss()

      });
    }

  }




  getOrdersListById(url) {

    console.log(url);

    if (url && url != '') {
      url = this.concateQuery()
    }
    //this.isLoading++;
    //this.isLoading++
    let loader = this.loaderCtrl.create({
      message: '',
      duration: 13000
    }).then((response) => {
      response.present();
    });

    return
    console.log('load api list by project id');

  }

  getOrderCode(code) {

    let codes = code.split('.')
    let string = ''
    for (var i = 5; i < codes.length; i++) {
      if (i != 5)
        string += '.' + codes[i]
      else
        string += codes[i]
    }
    return string;
  }


  methodAction(action) {

    try {
      this[action]()
    } catch {
      console.log('not call in parent component', action);
      this.emitterOpenNewOrder.emit(action)
    }
  }


  dataChangedReaded(newObj) {
    // here comes the object as parameter
    console.log('dataChangedReaded', newObj);
    //this.activeStatusOrder = true
    this.updateFilterItem('status', newObj)

    this.setPage({ offset: 0 })

  }

  resetSort(event) {
    let sorted
    console.log('EVENT::resetSort', event);
    event.stopPropagation()
    event.preventDefault()

    this.stringStatusOrderFilter = ''
    this.activeStatusOrder = false

    this.updateFilterItem('status', '')

    this.setPage({ offset: 0 })

    console.log('EVENT::resetSort::End');

  }


  updateFilterItem(filter, data) {
    this.filterItems = JSON.parse(localStorage.getItem('filter_orders'))
    let foundIndex

    foundIndex = this.filterItems.map(e => e.search_by).indexOf(filter);
    if (foundIndex == -1) {
      this.filterItems.push({ search_by: filter, search_data: data });
    } else {
      this.filterItems[foundIndex] = { search_by: filter, search_data: data };

    }

    localStorage.setItem('filter_orders', JSON.stringify(this.filterItems))
    console.log('updateFilterItem', this.filterItems);


    this.filterItems.map(e => {
      if (e.search_data != '') {
        switch (e.search_by) {
          case 'status':
            this.activeStatusOrder = true
            this.stringStatusOrderFilter = e.search_data
            break;


          default:
            break;
        }
      } else if (e.search_data == '') {
        switch (e.search_by) {
          case 'status':
            this.activeStatusOrder = false
            this.stringStatusOrderFilter = e.search_data
            break;


          default:
            break;
        }
      }
    })



  }

  onActivateCategory(event) {
    console.log(event)
    //this.openOrderModal(event.row)
    this.clickedCategory.emit(event);

    /* this.submenuService.component.next(component.code) */
  }

  

  async onChangeDate(event, row, type) {
    console.log(event, row, type);

    this.maxDate = moment(event.detail.value)/* .add(1, 'd') */.format();


    if (type == 'start')
      this.order_orders_createForm.controls['date_start'].setValue(moment(event.detail.value).format('YYYY-MM-DD'))

    if (type == 'end') {
      this.order_orders_createForm.controls['date_start'].setValue(moment(row.date_start).format('YYYY-MM-DD'))
      this.order_orders_createForm.controls['date_end'].setValue(moment(event.detail.value).format('YYYY-MM-DD'))
    }

    this.order_orders_createForm.patchValue({ type_id: row.type.id })

    // copy all the form values into the new vendor
    const newOrder: any = this.order_orders_createForm.value;
    //newOrder.created = moment(newOrder.created).format('YYYY-MM-DD')

    console.log(newOrder);
    Object.keys(newOrder).forEach(key => newOrder[key] == null ? delete newOrder[key] : '');
    Object.keys(newOrder).forEach(key => newOrder[key] == 'Invalid date' ? delete newOrder[key] : '');
    Object.keys(newOrder).forEach(key => newOrder[key] == '' ? delete newOrder[key] : '');

    this.api.order_orderdate_update(newOrder, row.id).subscribe(x => {
      console.log(x);

      this.sendOrders.emit(x)
      return x
    })
  }

  deleteDates(event) {
    console.log(event);
    this.api.order_orderdate_delete(event.id).subscribe(x => {
      console.log(x);

      this.sendOrders.emit(x)
      return x
    })

  }

  formatDate(value: string) {
    try {

      return moment(value).format('DD-MM-YYYY');
    } catch (error) {

      return null;
    }
  }
}




