import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titleAssignesPipe',
})
export class titleAssignesPipe implements PipeTransform {
  
  transform(totalAssignes: any[]): string {
    if (!totalAssignes || totalAssignes.length === 0) {
      return ''; // Ritorna stringa vuota se non ci sono assegnatari
    }
    
    // Estrai i nomi degli assegnatari
    const assigneesNames = totalAssignes
      .map(assignee => assignee.get('name')?.value) // Ottieni il valore del nome
      .filter(name => name); // Filtra i valori null o undefined

    return assigneesNames.join(', '); // Unisci i nomi separati da virgola
  }
}
