import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Pipe,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import {
  AlertController,
  IonDatetime,
  IonModal,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { forkJoin, Subscription } from 'rxjs';

import {
  ColumnMode,
  DatatableComponent,
  id,
  SelectionType,
} from '@swimlane/ngx-datatable';

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { TimesheetService } from '../services/timesheet/timesheet.service';
import { RoutingStateService } from '../services/routing-state/routing-state.service';
import { TasksService } from '../services/tasks/tasks.service';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { ProjectsService } from '../services/projects/projects.service';
import { TimesheetChartsPage } from './timesheet-charts/timesheet-charts.page';

export class Page {
  limit: number = 25;
  // The number of elements in the page
  size: number = 25;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 1;
}

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.page.html',
  styleUrls: ['./timesheet.page.scss'],
  encapsulation: ViewEncapsulation.None,
  //changeDetection: ChangeDetectionStrategy.OnPush,
})

//Tutto ciò che è project_* in realtà è job_*,
//changeDetection: ChangeDetectionStrategy.OnPush,
export class TimesheetPage implements OnInit {
  public readonly LAYOUT: any = {
    XS: 768,
    SM: 992,
    MD: 1200,
    LG: 1600,
    XL: 1920,
    XXL: 2560,
  };
  public layout: number = this.LAYOUT.MD;

  updateDateButton = document.getElementById(
    'updateDateButton'
  ) as HTMLIonButtonElement;
  myDateInput = document.getElementById('dateInput') as HTMLInputElement;
  @ViewChild(DatatableComponent) table: any = DatatableComponent;
  @ViewChild(DatatableComponent) tableRequest: any = DatatableComponent;
  @ViewChild(DatatableComponent) tableUser: any = DatatableComponent;
  @ViewChild(DatatableComponent) tableUserRequest: any = DatatableComponent;
  @ViewChild(DatatableComponent) tableAllRequests: any = DatatableComponent;
  @ViewChild('dayCellTemplate') dayCellTemplate: TemplateRef<any>;
  @ViewChild('unitSummaryTemplate') unitSummaryTemplate: TemplateRef<any>;
  @HostBinding('class') public cssClass: string | undefined;
  @ViewChild('inputText') inputText: any;
  @ViewChild('inputText') inputTextUser: any;
  @Input() index: number | undefined;
  @Input() userInfo: any | undefined;
  @ViewChild('popover') popover: any;
  @ViewChild('inputCell') inputCell: HTMLInputElement;
  @ViewChild('myTable1') table1: any;
  @ViewChild(IonModal) modal: IonModal;
  @ViewChild(IonDatetime) datetimeBulk: IonDatetime;

  selectedDates: string[] = [];
  highlightedDates: any = [];
  highlightedDatesBackup: any = [];
  workhour: number;
  description: string = '';
  selectedTypeId: string = '';
  dayValues = '5,10,15,20,25,30'; // Stringa di valori di giorni

  rows: any;
  rowsAttendance;
  columns: any;
  columnsWork: any;
  spinner = false;
  showAddButton = true;
  isModalRequestOpen = false;
  isNewTaskModalOpen = false;
  isNewSetPrjTask = false;
  isNewTaskModalRespOpen = false;
  isTaskDetailsOpen = false;
  isAttendanceDetailsOpen = false;
  isChartsOpen = false;
  isBulkModalOpen = false;
  isEditModalOpen = false;
  isAllRequestsModalOpen = false;
  isBulkRequestModalOpen = false;
  forms: any[] = [this.createBulkForm()];
  formsRequest: any[] = [this.createBulkRequestForm()];
  // rows:any
  rowsRequest: any;
  rowsUser: any;
  rowsUserRequest: any;
  rowsAllRequests: any;
  // columns:any
  columnsRequest: any;
  columnsUser: any;
  columnsUserRequest: any;
  columnsAllRequests: any;
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  avatar: any = '';
  addSingleForm: any = FormGroup;
  addRequestForm: any = FormGroup;
  addTaskForm: any = FormGroup;
  addPrjTaskForm: any = FormGroup;
  detailsTaskForm: any = FormGroup;
  addBulkForm: any = FormGroup;
  editForm: any = FormGroup;
  projectStats: any = FormGroup;
  showCodiceProgetto = false;
  showCodiceProgettoBulk: boolean[] = [];
  showCodiceProgettoBulkRequest: boolean[] = [];
  codiciProgetto: any = [];
  currentDate = new Date();
  subscription = new Subscription();
  workingDaysOfMonth: string[] = this.getWorkingDaysOfMonth();
  userDate: any = [];
  showAlertMancanti = false;
  showSpinner = true;
  showText = false;
  arrayMancanti: any = [];
  item: any;
  input: any;
  inputUser: any;
  inputBulk: any;
  inputBulkRequest: any;
  employee: any;
  checkCode: any;
  checkCodeUser: any;
  checkCodeUserProject: any;
  checkCodeUserBulkRequest: any;
  items: any = [];
  itemsRequest: any = [];
  itemsBulkRequest: any = [];
  itemsBulkRequestProject: any = [];
  allEmployees: any = [];
  file: File = null!;
  valRequired = false;
  theme: string = '';
  search_bar: string = '';
  filteredData: any = [];
  filteredDataUser: any = [];
  filteredDataRequest: any = [];
  filteredDataUserRequest: any = [];
  attachmentList: any = [];
  row_id: any;
  notes: any;
  hours: any;
  submitting = true;
  task: any = '';
  tipologia: any = '';
  codice: any;
  data: any = ''; //data di inserimento riga
  parentId: any = '';
  allUsers: any = [];
  allUsersInfo: string[] = [];
  usersInfo: any[] = [];
  fullNameRichiedente: any;
  showTable = false;
  previousTipologiaBulk: any = '';
  previousTipologiaBulkRequest: any = '';
  previousTipologia: any = '';
  progressBar = false;
  currentStep: number = 0;
  totalSteps: number = 0;
  showTask = false;
  attachModel: any[] | undefined;
  p: any;
  arrAttach: any = [];
  attachment: any;
  arrayAttach: any = [];
  attachmentToUpload: any = [];
  showNotification = true;
  isPopoverOpen = false;
  showRequest = true;
  showUserRequest = false;
  showCalendar = false;
  showUserCalendar = false;
  valueSearch: any = '';
  showAdminBoard = false;
  myTeam: any = [];
  showUserTable = false;
  dataUserInfo: any = [];
  fullNameUser: any;
  showUserTableTemp = false;
  approvalValue: boolean | undefined;
  deniedValue: boolean | undefined;
  selectedOption: any = 'Assegnati';
  isResponsabile = false;
  responsabileInfo = '';
  showSuperResp = false;
  myDate: Date = new Date();
  allData: any;
  isLoggedIn: boolean = false;
  currentUser: any;
  groupExpansionDefaultStatus = false;

  employee_id: any;
  page = new Page();
  isLoading = 0;

  days: any[] = [];
  isResp = false;
  isDeps = false;
  selectedEmployee: any;
  page_project: number = 1;
  numPage_project: any;
  onSearchSelector: any;
  projects: any;
  onPushProject: any;
  onSelectProject: any;
  onSelectCommitter: any;
  selectedText: any;
  prjSubscription: Subscription;
  size: number = 25;
  prj: any = [];
  committer: any = [];
  userTask: any;
  rowsEnd: any;
  currentRow: any;

  view: any = [1009, 1000];
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Ore';
  showYAxisLabel = true;
  yAxisLabel = 'Giorni';

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'],
  };

  chartData: any[] = [];
  allChartData: any[] = [];
  pieChartData: any[] = [];
  allPieChartData: any[] = [];
  selectedMonth: string = '';
  selectedDay = '';
  allApproved: any;

  page_vendor = 1;
  numPage_vendor = 0;
  vendors: any = [];
  onPushVendor;
  goToUser: any = [];
  typesAttendances: any = [];
  buttonHours = [
    { hour: 1, color:'#F9844A', },
    { hour: 2, color:'#FFA639', },
    { hour: 3, color:'#F9C74F', },
    { hour: 4, color:'#90BE6D', },
    { hour: 5, color:'#43AA8B', },
    { hour: 6, color:'#4D908E', },
    { hour: 7, color:'#577590', },
    { hour: 8, color:'#277DA1', },
  ];

  selectedHour: number | null = null;
  customHour: number | null = null;
  segmentActive = false; // Controlla se il segment è a
  inputActive = false; // Controlla se l'input è attivo
  deleteHours = false;
  setHoursDeleted = [];

  constructor(
    private router: Router,
    private alertController: AlertController,
    private timesheet: TimesheetService,
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private breakpointObserver: BreakpointObserver,
    private routingState: RoutingStateService,
    private taskService: TasksService,
    private authService: AuthenticationService,
    private apiProject: ProjectsService,
    private toastController: ToastController
  ) {
    this.addSingleForm = this.formBuilder.group({
      tipologia: ['', Validators.required],
      codice_progetto: [
        { value: '', disabled: !this.showCodiceProgetto },
        this.showCodiceProgetto ? Validators.required : null,
      ],
      task: [{ value: '', disabled: !this.showCodiceProgetto }],
      date: [new Date().toISOString(), Validators.required],
      hours: ['', Validators.required],
      notes: [''],
      giustificativo: [''],
      firstname: [''],
      lastname: [''],
      richiedente: ['', Validators.required],
    });

    this.editForm = this.formBuilder.group({
      tipologia: ['', Validators.required],
      codice_progetto: [
        { value: '', disabled: !this.showCodiceProgetto },
        this.showCodiceProgetto ? Validators.required : null,
      ],
      task: [{ value: '', disabled: !this.showCodiceProgetto }],
      date: [new Date().toISOString(), Validators.required],
      hours: [''],
      notes: [''],
      firstname: [''],
      lastname: [''],
      richiedente: [''],
    });
  }

  getTotalWorkDay(day_total: any[], day: string): string {
    console.log(day_total);

    const entry = day_total.find((item) => item === day);
    return entry ? entry.toString() : 0;
  }

  onButtonClick(day: any, row: any, content: any) {
    console.log(row);
    console.log(content);
    console.log(this.inputCell);
    // Usare la stessa logica per ottenere la data in formato esteso
    // Convertire la data nel formato "dd/mm/yyyy"
    const date = new Date(day);
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`;

    // Convertire la data in formato esteso
    const daysOfWeek = [
      'domenica',
      'lunedì',
      'martedì',
      'mercoledì',
      'giovedì',
      'venerdì',
      'sabato',
    ];
    const monthsOfYear = [
      'gennaio',
      'febbraio',
      'marzo',
      'aprile',
      'maggio',
      'giugno',
      'luglio',
      'agosto',
      'settembre',
      'ottobre',
      'novembre',
      'dicembre',
    ];
    const formattedLongDate = `${daysOfWeek[date.getDay()]} ${date.getDate()} ${
      monthsOfYear[date.getMonth()]
    } ${date.getFullYear()}`;

    console.log(formattedDate); // Esempio: 20/07/2024
    console.log(formattedLongDate); // Esempio: lunedì 20 luglio 2024
    this.selectedDay = formattedLongDate;
    console.log(
      `Clicked on ${day}, Activity: ${row.task_name}, Content: ${content}`
    );
    // if(row.taskresource_id === this.userInfo.contracts[0].ref_id){
    if (row.timesheet) {
      if (content === '+') {
        console.log("Clicked on '+', add new work hours here. Timesheet");
        // Qui puoi aggiungere la logica per gestire il caso in cui viene cliccato '+'
        this.hourForm(row.taskresource_id, day);
      } else if (content === 'close') {
        console.log(row);
        this.closeTask(row);
      } else if (content === 'open') {
        console.log(row);
        this.openTask(row);
      } else {
        console.log(
          `Clicked on existing hours: ${content}, perform an update or view details. Timesheet`
        );
        // Qui puoi aggiungere la logica per gestire il caso in cui sono visualizzate le ore esistenti
        let task = row.timesheet.filter((e) => e.workhistory_date === day);
        this.editHourForm(row, task, day);
      }
    }
    if (row.attendance) {
      if (content === '+') {
        console.log("Clicked on '+', add new work hours here. Attendance");
        // Qui puoi aggiungere la logica per gestire il caso in cui viene cliccato '+'
        this.hourFormAttendance(row, day);
      } else {
        console.log(
          `Clicked on existing hours: ${content}, perform an update or view details. Attendance`
        );
        // Qui puoi aggiungere la logica per gestire il caso in cui sono visualizzate le ore esistenti
        let attendance = row.attendance.filter((e) => e.history_date === day);
        this.editHourFormAttendance(row, attendance, day);
      }
    }
    // }

    // Additional logic based on button click
  }

  async editHourForm(row: any, task: any, day: string) {
    console.log(task);

    const alert = await this.alertController.create({
      header: 'Edit hours',
      cssClass: 'alert-timesheet-hours',
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
            alert.dismiss;
          },
        },
        {
          text: 'Confirm',
          handler: (data) => {
            let form = {
              workdate: day,
              workhour: parseFloat(data.workhour),
              description: data.description,
              taskresource_id: row.taskresource_id,
              workhistory_id: task[0].workhistory_id,
            };
            if (form.workhour === 0) {
              this.taskService.deleteHours(form).subscribe((e) => {
                console.log(e);
                if (this.isResp && this.selectedEmployee) {
                  this.taskService
                    .getTimesheet(this.selectedEmployee.ref_id)
                    .subscribe((e) => {
                      this.rows = [...e];
                      this.loadData();
                    });
                } else {
                  this.taskService
                    .getTimesheet(this.employee_id)
                    .subscribe((e) => {
                      this.rows = [...e];
                      this.loadData();
                    });
                }
              });
            } else {
              this.taskService.editHours(form).subscribe((e) => {
                console.log(e);
                if (this.isResp && this.selectedEmployee) {
                  this.taskService
                    .getTimesheet(this.selectedEmployee.ref_id)
                    .subscribe((e) => {
                      this.rows = [...e];
                      this.loadData();
                    });
                } else {
                  this.taskService
                    .getTimesheet(this.employee_id)
                    .subscribe((e) => {
                      this.rows = [...e];
                      this.loadData();
                    });
                }
              });
            }
          },
        },
      ],
      inputs: [
        {
          name: 'workhour',
          type: 'number',
          placeholder: 'Enter hours - type 0 to delete',
          min: 1,
          max: 12,
          value: task[0].workhistory_hour, // Prepopulate the input with current work hours
        },
        {
          name: 'description',
          type: 'textarea',
          placeholder: 'Add a description',
          value: task[0].workhistory_description, // Prepopulate the input with current work hours
        },
      ],
    });

    await alert.present();
  }

  async editHourFormAttendance(row: any, attendance: any, day: string) {
    console.log(row);
    console.log(attendance);

    const alert = await this.alertController.create({
      header: 'Edit hours',
      cssClass: 'alert-timesheet-hours',
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
            alert.dismiss;
          },
        },
        {
          text: 'Confirm',
          handler: (data) => {
            let form = {
              workdate: day,
              workhour: parseFloat(data.workhour),
              description: data.description,
              // person_id: row.task_id,
              history_id: attendance[0].history_id,
            };
            console.log(form);
            if (form.workhour === 0) {
              this.taskService.deleteHoursAttendance(form).subscribe((e) => {
                console.log(e);
                if (this.isResp && this.selectedEmployee) {
                  this.taskService
                    .getTimesheetWork(this.selectedEmployee.ref_id)
                    .subscribe((e) => {
                      this.rowsAttendance = [...e];
                      this.loadDataAttendance();
                    });
                } else {
                  this.taskService
                    .getTimesheetWork(this.employee_id)
                    .subscribe((e) => {
                      this.rowsAttendance = [...e];
                      this.loadDataAttendance();
                    });
                }
              });
            } else {
              this.taskService.editHoursAttendance(form).subscribe((e) => {
                console.log(e);
                if (this.isResp && this.selectedEmployee) {
                  this.taskService
                    .getTimesheetWork(this.selectedEmployee.ref_id)
                    .subscribe((e) => {
                      this.rowsAttendance = [...e];
                      this.loadDataAttendance();
                    });
                } else {
                  this.taskService
                    .getTimesheetWork(this.employee_id)
                    .subscribe((e) => {
                      this.rowsAttendance = [...e];
                      this.loadDataAttendance();
                    });
                }
              });
            }
          },
        },
      ],
      inputs: [
        {
          name: 'workhour',
          type: 'number',
          placeholder: 'Enter hours - type 0 to delete',
          min: 1,
          max: 12,
          value: attendance[0].history_hour, // Prepopulate the input with current work hours
        },
        {
          name: 'description',
          type: 'textarea',
          placeholder: 'Add a description',
          value: attendance[0].history_description, // Prepopulate the input with current work hours
        },
      ],
    });

    await alert.present();
  }

  async hourForm(id: any, day: string) {
    const alert = await this.alertController.create({
      header: 'Add hours to: ' + this.selectedDay,
      cssClass: 'alert-timesheet-hours',
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
            alert.dismiss;
          },
        },
        {
          text: 'Confirm',
          handler: (data) => {
            let form = {
              workdate: day,
              workhour: parseFloat(data.workhour),
              description: data.description,
              taskresource_id: id,
            };
            this.taskService.addHours(form).subscribe((e) => {
              console.log(e);
              if (this.isResp) {
                this.taskService
                  .getTimesheet(this.selectedEmployee.ref_id)
                  .subscribe((e) => {
                    this.rows = [...e];
                    this.loadData();
                  });
              } else {
                this.taskService
                  .getTimesheet(this.employee_id)
                  .subscribe((e) => {
                    this.rows = [...e];
                    this.loadData();
                  });
              }
            });
          },
        },
      ],
      inputs: [
        {
          name: 'workhour',
          type: 'number',
          placeholder: 'Enter hours',
          min: 1,
          max: 12,
        },
        {
          name: 'description',
          type: 'textarea',
          placeholder: 'Add a description',
        },
      ],
    });

    await alert.present();
  }

  async hourFormAttendance(row: any, day: string) {
    console.log(row);

    const alert = await this.alertController.create({
      header: 'Add hours to: ' + this.selectedDay,
      cssClass: 'alert-timesheet-hours',
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
            alert.dismiss;
          },
        },
        {
          text: 'Confirm',
          handler: (data) => {
            let form = {
              workdate: day,
              workhour: parseFloat(data.workhour),
              description: data.description,
              person_id: this.employee_id,
              type_id: row.task_id,
            };
            this.taskService.addHoursAttendance(form).subscribe((e) => {
              console.log(e);
              this.taskService
                .getTimesheetWork(this.employee_id)
                .subscribe((e) => {
                  this.rowsAttendance = [...e];
                  this.loadDataAttendance();
                });
            });
          },
        },
      ],
      inputs: [
        {
          name: 'workhour',
          type: 'number',
          placeholder: 'Enter hours',
          min: 1,
          max: 12,
        },
        {
          name: 'description',
          type: 'textarea',
          placeholder: 'Add a description',
        },
      ],
    });

    await alert.present();
  }
  // {
  //   "workdate": "2024-10-07",
  //   "workhour": 1,
  //   "description": "",
  //   "person_id": 8,
  //   "type_id": 6}

  isWeekday = (dateString: string) => {
    const date = new Date(dateString);
    const utcDay = date.getUTCDay();

    /**
     * Date will be enabled if it is not
     * Sunday or Saturday
     */
    return utcDay !== 0 && utcDay !== 6;
  };

  resetDatetime() {
    this.datetimeBulk?.reset();
  }
  test(event: any) {
    console.log(event);
    const selectedDates = event.detail.value; // Array di date selezionate
    console.log(selectedDates);
  }

  updateHighlightedDates() {
    if (this.deleteHours) {
      console.log(this.setHoursDeleted);
      this.highlightedDates = this.highlightedDates.filter(
        (item) => !this.setHoursDeleted.includes(item.history_id)
      );
    }
    console.log('Highlighted Dates dopo la rimozione:', this.highlightedDates);
    // Filtriamo gli elementi con history_id non nullo
    this.highlightedDates = this.highlightedDates.filter(
      (item) => item.history_id !== null && item.history_id !== undefined
    );

    // Creiamo una mappa per tracciare gli elementi unici
    const uniqueMap = new Map<string, any>();
    this.highlightedDates.forEach((item) => {
      const key = `${item.history_id}_${item.date}`;
      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, item);
      }
    });

    // Aggiorniamo highlightedDates con gli elementi unici
    this.highlightedDates = Array.from(uniqueMap.values());
    this.highlightedDatesBackup = this.highlightedDates;
    console.log(this.highlightedDatesBackup);
  }

  selectTypeId() {
    // Cerca la data nell'array highlightedDates
    setTimeout(() => {
      this.datetimeBulk?.reset();
    }, 100);
    console.log(this.highlightedDates);

    this.highlightedDatesBackup = this.highlightedDates.filter(
      (highlight) => this.selectedTypeId === highlight.task_id
    );
  }

  dismiss() {
    this.workhour = null;
    this.description = '';
    this.selectedTypeId = '';
    this.datetimeBulk?.reset();
    this.modal.dismiss();
  }

  async confirm() {
    let form: any[] = [];
    console.log(this.selectedDates);
    this.selectedDates.forEach((e) => {
      form.push({
        task_id: this.selectedTypeId,
        date: e,
      });
    });
    console.log(form);
    const result = this.compareDatesArray(form, this.highlightedDates);

    console.log(result.hasCommon); // Output: true
    console.log('Elementi in comune:', result.commonElements);
    console.log('Elementi non in comune:', result.nonCommonElements);
    console.log(this.highlightedDates);
    console.log(this.workhour);
    console.log(this.description);
    console.log(this.selectedTypeId);
    console.log(this.highlightedDatesBackup);

    // Eseguiamo le operazioni necessarie, attendendo il completamento
    if (result.nonCommonElements.length !== 0 && this.workhour !== 0) {
      console.log('no common e workhour !== 0');
      await this.addHoursForSelectedDates(
        result.nonCommonElements,
        this.workhour,
        this.description,
        this.selectedTypeId
      );
    }
    if (result.commonElements.length !== 0 && this.workhour === 0) {
      console.log('common e workhour == 0');
      await this.deleteHoursForSelectedDates(
        result.commonElements,
        this.workhour,
        this.description,
        this.selectedTypeId
      );
    }
    if (result.commonElements.length !== 0 && this.workhour !== 0) {
      console.log('common e workhour !== 0');
      await this.editHoursForSelectedDates(
        result.commonElements,
        this.workhour,
        this.description,
        this.selectedTypeId
      );
    }

    this.updateHighlightedDates();

    this.dismiss();
  }

  compareDatesArray(
    array1: any[],
    array2: any[]
  ): {
    hasCommon: boolean;
    commonElements: any[];
    nonCommonElements: any[];
  } {
    // Creiamo un Set con combinazioni uniche di task_id e date dal secondo array
    const setArray2 = new Set(
      array2.map((item) => `${item.task_id}_${item.date}`)
    );

    // Troviamo le chiavi degli elementi comuni (presenti in entrambi gli array)
    const commonKeys = new Set(
      array1
        .filter((item) => setArray2.has(`${item.task_id}_${item.date}`))
        .map((item) => `${item.task_id}_${item.date}`)
    );

    // Creiamo una mappa per eliminare duplicati negli elementi comuni
    const commonElementsMap = new Map<string, any>();

    // Otteniamo gli elementi in comune dal secondo array, evitando duplicati
    array2.forEach((item) => {
      const key = `${item.task_id}_${item.date}`;
      if (commonKeys.has(key) && !commonElementsMap.has(key)) {
        commonElementsMap.set(key, item);
      }
    });

    // Convertiamo la mappa in un array di valori
    const commonElements = Array.from(commonElementsMap.values());

    // Troviamo gli elementi non in comune dal primo array
    const nonCommonElements = array1.filter(
      (item) => !setArray2.has(`${item.task_id}_${item.date}`)
    );

    // Verifichiamo se esiste almeno un elemento in comune
    const hasCommon = commonElements.length > 0;

    return { hasCommon, commonElements, nonCommonElements };
  }

  hasHoursForDate(date: string): boolean {
    // Trova se esiste un elemento in rowsAttendance che contiene la data con ore registrate
    return this.rowsAttendance.some(
      (item) =>
        item.day_total && item.day_total[date] && item.day_total[date] > 0
    );
  }

  async addHoursForSelectedDates(
    dates: any,
    workhour: number,
    description: string,
    type_id: string
  ) {
    // Crea un array di osservabili per le chiamate addHoursAttendance
    const addObservables = dates.map((attendance) => {
      const form = {
        workdate: attendance.date, // Date is already in 'YYYY-MM-DD' format from ion-datetime
        workhour: workhour,
        description: description,
        person_id: this.employee_id,
        type_id: type_id,
      };

      // Restituisci l'osservabile della chiamata addHoursAttendance
      return this.taskService.addHoursAttendance(form);
    });

    // Usa forkJoin per eseguire tutte le chiamate e attendere il completamento
    forkJoin(addObservables).subscribe(
      (responses) => {
        console.log('All add operations completed:', responses);

        // Chiama getTimesheetWork e loadDataAttendance solo dopo che tutte le chiamate sono completate
        this.taskService
          .getTimesheetWork(this.employee_id)
          .subscribe((updatedAttendance) => {
            this.rowsAttendance = [...updatedAttendance];
            this.loadDataAttendance();
            this.rowsAttendance.forEach((item) => {
              if (item.attendance && item.attendance.length > 0) {
                item.attendance.forEach((attendanceDate) => {
                  if (attendanceDate.history_approved) {
                    this.highlightedDates.push({
                      date: attendanceDate.history_date,
                      textColor: '#ffffff',
                      backgroundColor: '#2dd55b',
                      history_id: attendanceDate.history_id,
                      task_id: item.task_id,
                    });
                  } else if (
                    attendanceDate.history_approved == null &&
                    attendanceDate.history_not_approved == null
                  ) {
                    this.highlightedDates.push({
                      date: attendanceDate.history_date,
                      textColor: '#ffffff',
                      backgroundColor: '#FFC91C',
                      history_id: attendanceDate.history_id,
                      task_id: item.task_id,
                    });
                  } else if (attendanceDate.history_not_approved) {
                    this.highlightedDates.push({
                      date: attendanceDate.history_date,
                      textColor: '#ffffff',
                      backgroundColor: '#D92F46',
                      history_id: attendanceDate.history_id,
                      task_id: item.task_id,
                    });
                  }
                });
              }
            });
          });
      },
      (error) => {
        console.error('Error during add operations:', error);
      }
    );
  }

  async deleteHoursForSelectedDates(
    dates: any[],
    workhour: number,
    description: string,
    type_id: string
  ) {
    console.log(dates);

    // Creiamo un array di osservabili delle chiamate API
    const deleteObservables = dates.map((attendance) => {
      const form = {
        workdate: attendance.date, // Date is already in 'YYYY-MM-DD' format from ion-datetime
        workhour: workhour,
        description: description,
        person_id: this.employee_id,
        type_id: type_id,
        history_id: attendance.history_id,
      };
      console.log(form);
      this.setHoursDeleted.push(form.history_id);

      // Restituisci l'osservabile della chiamata deleteHoursAttendance
      return this.taskService.deleteHoursAttendance(form);
    });

    // Usa forkJoin per eseguire tutte le chiamate e attendere il completamento
    forkJoin(deleteObservables).subscribe(
      (responses) => {
        console.log('All delete operations completed:', responses);
        this.deleteHours = true;
        // Chiama loadDataAttendance solo dopo che tutte le chiamate sono completate
        this.taskService
          .getTimesheetWork(this.employee_id)
          .subscribe((updatedAttendance) => {
            this.rowsAttendance = [...updatedAttendance];
            this.loadDataAttendance();
          });
      },
      (error) => {
        console.error('Error during delete operations:', error);
      }
    );
  }

  async editHoursForSelectedDates(
    dates: any[],
    workhour: number,
    description: string,
    type_id: string
  ) {
    console.log(dates);

    // Creiamo un array di osservabili delle chiamate API
    const editObservables = dates.map((attendance) => {
      const form = {
        workdate: attendance.date, // Date is already in 'YYYY-MM-DD' format from ion-datetime
        workhour: workhour,
        description: description,
        person_id: this.employee_id,
        type_id: type_id,
        history_id: attendance.history_id,
      };
      console.log(form);

      // Restituisci l'osservabile della chiamata deleteHoursAttendance
      return this.taskService.editHoursAttendance(form);
    });

    // Usa forkJoin per eseguire tutte le chiamate e attendere il completamento
    forkJoin(editObservables).subscribe(
      (responses) => {
        console.log('All delete operations completed:', responses);

        // Chiama loadDataAttendance solo dopo che tutte le chiamate sono completate
        this.taskService
          .getTimesheetWork(this.employee_id)
          .subscribe((updatedAttendance) => {
            this.rowsAttendance = [...updatedAttendance];
            this.loadDataAttendance();
            this.rowsAttendance.forEach((item) => {
              if (item.attendance && item.attendance.length > 0) {
                item.attendance.forEach((attendanceDate) => {
                  if (attendanceDate.history_approved) {
                    this.highlightedDates.push({
                      date: attendanceDate.history_date,
                      textColor: '#ffffff',
                      backgroundColor: '#2dd55b',
                      history_id: attendanceDate.history_id,
                      task_id: item.task_id,
                    });
                  } else if (
                    attendanceDate.history_approved == null &&
                    attendanceDate.history_not_approved == null
                  ) {
                    this.highlightedDates.push({
                      date: attendanceDate.history_date,
                      textColor: '#ffffff',
                      backgroundColor: '#FFC91C',
                      history_id: attendanceDate.history_id,
                      task_id: item.task_id,
                    });
                  } else if (attendanceDate.history_not_approved) {
                    this.highlightedDates.push({
                      date: attendanceDate.history_date,
                      textColor: '#ffffff',
                      backgroundColor: '#D92F46',
                      history_id: attendanceDate.history_id,
                      task_id: item.task_id,
                    });
                  }
                });
              }
            });
          });
      },
      (error) => {
        console.error('Error during delete operations:', error);
      }
    );
  }

  async closeTask(row: any) {
    const alert = await this.alertController.create({
      header: 'Vuoi chiudere la task?',
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
            alert.dismiss;
          },
        },
        {
          text: 'Confirm',
          handler: () => {
            this.taskService.closeTask(row.task_id).subscribe((e) => {
              console.log(e);
              if (this.showEndTaskToggle) {
                this.taskService
                  .getTimesheetEnd(this.selectedEmployee.ref_id)
                  .subscribe((e) => {
                    this.rows = [...e];
                  });
                this.loadData();
              } else {
                this.taskService
                  .getTimesheet(this.selectedEmployee.ref_id)
                  .subscribe((e) => {
                    this.rows = [...e];
                  });
                this.loadData();
              }
            });
          },
        },
      ],
    });

    await alert.present();
  }

  async openTask(row: any) {
    const alert = await this.alertController.create({
      header: 'Vuoi riaprire la task?',
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
            alert.dismiss;
          },
        },
        {
          text: 'Confirm',
          handler: () => {
            let form = {
              status_id: 3,
            };
            if (this.showEndTaskToggle) {
              this.taskService.openTask(row.task_id, form).subscribe((e) => {
                console.log(e);
                this.taskService
                  .getTimesheetEnd(this.selectedEmployee.ref_id)
                  .subscribe((e) => {
                    this.rows = [...e];
                  });
                this.loadData();
              });
            } else {
              this.taskService.openTask(row.task_id, form).subscribe((e) => {
                console.log(e);
                this.taskService
                  .getTimesheet(this.selectedEmployee.ref_id)
                  .subscribe((e) => {
                    this.rows = [...e];
                  });
                this.loadData();
              });
            }
          },
        },
      ],
    });

    await alert.present();
  }

  ngOnInit() {
    this.setupDays();
    this.setupColumns();
    this.setupDaysWork();
    this.setupColumnsWork();

    this.itemsBulkRequest = new Array(this.formsRequest.length).fill([]);
    this.itemsBulkRequestProject = new Array(this.formsRequest.length).fill([]);
    const breakpoints = Object.keys(this.LAYOUT).map(
      (k: any) => this.LAYOUT[k]
    );
    breakpoints.forEach((maxWidth, index) => {
      const minWidth = index > 0 ? breakpoints[index - 1] : 0;
      this.breakpointObserver
        .observe([
          `(min-width: ${minWidth}px) and (max-width: ${maxWidth - 1}px)`,
        ])
        .subscribe((state: BreakpointState) => {
          if (!state.matches) {
            return;
          }
          this.layout = maxWidth;
          //console.log('Layout', this.layout);
        });
    });
    this.loadPage();
  }

  currentWeekOffset = 0; // 0 è la settimana corrente, 1 la prossima settimana, -1 la settimana precedente, ecc.
  currentWeekOffsetWork = 0; // 0 è la settimana corrente, 1 la prossima settimana, -1 la settimana precedente, ecc.

  changeWeek(offset: number) {
    this.currentWeekOffset += offset;
    this.setupDays();
    this.setupDaysWork();
  }

  changeWeekWork(offset: number) {
    this.currentWeekOffset += offset;
    this.setupDaysWork();
  }

  changeToday() {
    this.currentWeekOffset = 0;
    this.setupDays();
    this.setupDaysWork();
  }

  changeTodayWork() {
    this.currentWeekOffset = 0;
    this.setupDaysWork();
  }

  setupDays() {
    const today = new Date();
    today.setDate(today.getDate() + this.currentWeekOffset * 7);
    const dayOfWeek = today.getDay();
    const monday = new Date(today);
    monday.setDate(today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));

    this.days = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(
        monday.getFullYear(),
        monday.getMonth(),
        monday.getDate() + i
      );
      this.days.push({
        name: day.toLocaleDateString('it-IT', {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
        }),
        date: day.toLocaleDateString('en-CA'),
      });
    }
    this.setupColumns();
  }

  setupDaysWork() {
    const today = new Date();
    today.setDate(today.getDate() + this.currentWeekOffset * 7);
    const dayOfWeek = today.getDay();
    const monday = new Date(today);
    monday.setDate(today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));

    this.days = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(
        monday.getFullYear(),
        monday.getMonth(),
        monday.getDate() + i
      );
      this.days.push({
        name: day.toLocaleDateString('it-IT', {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
        }),
        date: day.toLocaleDateString('en-CA'),
      });
    }
    this.setupColumnsWork();
  }

  loadData() {
    // Simula il caricamento dei dati
    console.log(this.isResp);

    if (this.isResp) {
      console.log(this.selectedEmployee);

      this.taskService
        .getTimesheetEnd(this.selectedEmployee.ref_id)
        .subscribe((e) => {
          console.log(e);
          this.rowsEnd = e;
          this.calculateTotalsAndAddRow(this.rowsEnd);
        });
    } else {
      this.taskService.getTimesheetEnd(this.employee_id).subscribe((e) => {
        console.log(e);
        this.rowsEnd = e;
        this.calculateTotalsAndAddRow(this.rowsEnd);
      });
    }
  }

  loadDataAttendance() {
    // Simula il caricamento dei dati
    this.calculateTotalsAndAddRowAttendance(this.rowsAttendance);
  }
  loadDataToggle() {
    // Simula il caricamento dei dati
    this.calculateTotalsAndAddRow(this.rows);
  }

  calculateTotalsAndAddRow(rows: any) {
    // Inizializzazione della riga dei totali
    console.log('aaaaq', rows);
    let totalsRow: any = {};
    totalsRow = {
      project_name: 'Totale',
      task_name_slug: '',
      task_parent: '',
      totals: true,
      timesheet: [],
    };

    // Calcola i totali per ogni giorno
    let totalsByDay = rows.reduce((acc, row) => {
      row.timesheet.forEach((entry) => {
        if (acc[entry.workhistory_date]) {
          acc[entry.workhistory_date] += entry.workhistory_hour;
        } else {
          acc[entry.workhistory_date] = entry.workhistory_hour;
        }
      });
      return acc;
    }, {});

    // Prepara la struttura timesheet per la riga dei totali
    totalsRow.timesheet = Object.entries(totalsByDay).map(([date, hours]) => ({
      workhistory_date: date,
      workhistory_hour: hours,
      workhistory_description: 'Total', // descrizione opzionale
    }));

    // Aggiungi la riga dei totali all'array di righe
    this.rows = [...this.rows, totalsRow];
  }

  calculateTotalsAndAddRowAttendance(rows: any[]) {
    // Inizializzazione della riga dei totali
    let totalsRow = {
      project_name: 'Totale',
      task_name_slug: '',
      task_parent: '',
      totals: true,
      attendance: [],
    };

    // Calcola i totali per ogni giorno
    let totalsByDay = rows.reduce((acc, row) => {
      row.attendance.forEach((entry) => {
        if (acc[entry.history_date]) {
          acc[entry.history_date] += entry.history_hour;
        } else {
          acc[entry.history_date] = entry.history_hour;
        }
      });
      return acc;
    }, {});

    // Prepara la struttura timesheet per la riga dei totali
    totalsRow.attendance = Object.entries(totalsByDay).map(([date, hours]) => ({
      history_date: date,
      history_hour: hours,
      history_description: 'Total', // descrizione opzionale
    }));

    // Aggiungi la riga dei totali all'array di righe
    this.rowsAttendance = [...rows, totalsRow];
  }

  setupColumns() {
    this.columns = [
      ...this.days.map((day) => ({
        prop: `${day.date}`,
        name: `${day.name}`,
        flexGrow: 1,
        sortable: true,
        draggable: false,
        resizeable: true,
        headerClass: 'datatable-header-cell', // Applica la classe CSS per il titolo
        cellClass: 'datatable-body-cell', // Applica la classe CSS per il corpo
      })),
    ];
  }

  setupColumnsWork() {
    this.columnsWork = [
      ...this.days.map((day) => ({
        prop: `${day.date}`,
        name: `${day.name}`,
        flexGrow: 1,
        sortable: true,
        draggable: false,
        resizeable: true,
        cellTemplate: this.dayCellTemplate, // Assicurati di avere un riferimento a un template
        headerClass: 'datatable-header-cell', // Applica la classe CSS per il titolo
        cellClass: 'datatable-body-cell', // Applica la classe CSS per il corpo
      })),
    ];
  }
  ionViewWillEnter() {
    this.authService.getLocalData('auth-profile').then((e) => {
      console.log(e.user_data);
      this.userInfo = e.user_data;
      console.log(this.userInfo);
      if (this.userInfo.deps?.length !== 0) {
        this.isResp = true;
        this.selectedEmployee = this.userInfo.contracts[0];
        this.userInfo.deps.push({
          company: this.userInfo.contracts[0].company,
          ref_id: this.userInfo.contracts[0].ref_id,
          ref_name: this.userInfo.last_name + ' ' + this.userInfo.first_name,
        });
      }
      if (this.userInfo.contracts[0].ref_id) {
        this.employee_id = this.userInfo.contracts[0].ref_id;
        this.taskService.getTimesheet(this.employee_id).subscribe((e) => {
          console.log(e);
          this.rows = e;
          this.allChartData = [...this.chartData]; // Save the original data
          this.allPieChartData = [...this.pieChartData]; // Save the original data

          this.loadData();
        });
        this.taskService.getTimesheetWork(this.employee_id).subscribe((e) => {
          console.log(e);
          this.rowsAttendance = e;
          console.log(this.rowsAttendance);

          this.rowsAttendance.forEach((element) => {
            this.typesAttendances.push({
              task_id: element.task_id,
              task_name: element.task_name,
            });
          });
          console.log('entros');

          this.rowsAttendance.forEach((item) => {
            if (item.attendance && item.attendance.length > 0) {
              item.attendance.forEach((attendanceDate) => {
                if (attendanceDate.history_approved) {
                  this.highlightedDates.push({
                    date: attendanceDate.history_date, // Assicurati che `attendanceDate` sia nel formato 'YYYY-MM-DD'
                    textColor: '#ffffff', // Puoi personalizzare questi colori
                    backgroundColor: '#2dd55b', // Ad esempio, rosso per date bloccate
                    history_id: attendanceDate.history_id,
                    task_id: item.task_id,
                  });
                } else if (
                  attendanceDate.history_approved == null &&
                  attendanceDate.history_not_approved == null
                ) {
                  this.highlightedDates.push({
                    date: attendanceDate.history_date, // Assicurati che `attendanceDate` sia nel formato 'YYYY-MM-DD'
                    textColor: '#ffffff', // Puoi personalizzare questi colori
                    backgroundColor: '#FFC91C', // Ad esempio, rosso per date bloccate
                    history_id: attendanceDate.history_id,
                    task_id: item.task_id,
                  });
                } else if (attendanceDate.history_not_approved) {
                  console.log(item);

                  this.highlightedDates.push({
                    date: attendanceDate.history_date, // Assicurati che `attendanceDate` sia nel formato 'YYYY-MM-DD'
                    textColor: '#ffffff', // Puoi personalizzare questi colori
                    backgroundColor: '#D92F46', // Ad esempio, rosso per date bloccate
                    history_id: attendanceDate.history_id,
                    task_id: item.task_id,
                  });
                }
              });
            }
          });

          console.log('Highlighted Dates:', this.highlightedDates);
          console.log(this.typesAttendances);

          this.loadDataAttendance();
        });
        this.showTable = true;
      }
    });

    this.taskService.getResourcesTasksList().subscribe((e) => {
      console.log('resources', e);
    });

    this.taskService.getHistoriesTasksList().subscribe((e) => {
      console.log('histories', e);
    });
  }

  selectEmployee(event: any) {
    console.log(event);
    let ref_id = event.detail.value.ref_id;
    this.selectedEmployee = event.detail.value;
    console.log(this.selectedEmployee);

    if (ref_id) {
      this.taskService.getTimesheet(ref_id).subscribe((e) => {
        console.log(e);
        this.rows = e;
        this.loadData();

        this.showTable = true;
      });
      this.taskService.getTimesheetWork(ref_id).subscribe((e) => {
        console.log(e);
        this.rowsAttendance = e;
        this.loadDataAttendance();

        this.showTable = true;
      });
    } else {
      this.taskService.getTimesheet(this.selectedEmployee).subscribe((e) => {
        console.log(e);
        this.rows = e;
        this.loadData();

        this.showTable = true;
      });
      this.taskService
        .getTimesheetWork(this.selectedEmployee)
        .subscribe((e) => {
          console.log(e);
          this.rowsAttendance = e;
          this.loadDataAttendance();

          this.showTable = true;
        });
    }
  }

  getUser(data: any) {
    this.userInfo = data;
    let roleId = this.userInfo.roleId;
    let myId = this.userInfo.id;

    this.addSingleForm.patchValue({ firstname: this.userInfo.firstname });
    this.addSingleForm.patchValue({ lastname: this.userInfo.lastname });
    this.timesheet.getAvatar(this.userInfo.lastname).subscribe((blob: Blob) => {
      const url = URL.createObjectURL(blob);
      this.avatar = url;
    });
  }

  loadPage() {
    if (this.showUserTable == true) {
      this.showUserTable = false;
      this.showUserRequest = false;
      this.showUserCalendar = false;
      this.valueSearch = '';
      this.showTable = true;
    }
    this.submitting = false;
    let a: any = [];
    let requests: any = [];
    let temp: any = [];
    this.attachmentToUpload = [];
    // Array di tutte le date dei giorni lavorativi dal lunedì al venerdì del mese corrente
    const currentMonth = this.currentDate.getMonth(); // ottieni il mese corrente (0-11)

    // sottrai 1 mese dal mese corrente
    const newMonth = currentMonth - 1;

    // crea una nuova data con il nuovo mese e lo stesso giorno e anno della data corrente
    const newDate = new Date(
      this.currentDate.getFullYear(),
      newMonth,
      this.currentDate.getDate()
    );
  }

  checkAttachment(data: any, id: any) {
    let count = 0;
    this.attachmentList.forEach((e: any) => {
      if (e.parentId === id) {
        count++;
        // interrompe il ciclo forEach() quando l'allegato è stato trovato
        return;
      }
    });
    if (count === 0) {
      /* this.attachmentToUpload.push({
          tipologia: data.tipologia,
          data: data.data,
          note: data.note
        }) */
      this.attachmentToUpload.push(data);
      this.showNotification = false;
    }
    if (this.attachmentToUpload.length === 0) this.showNotification = true;
  }

  getClass(hour: string, timesheet: any[], day: string): string {
    if (hour === '+') {
      return 'plus-style'; // Nessuna entry per quel giorno
    }
    const entry = timesheet.find((item) => item.workhistory_date === day);
    if (entry) {
      if (entry.workhistory_approved) {
        return 'approved-style'; // Stile blu se approved è true
      }
      if (entry.workhistory_not_approved) {
        return 'not-approved-style'; // Stile rosso se not approved è true
      }
    }
    return 'normal-style'; // Stile normale negli altri casi
  }

  getClassWork(hour: string, attendance: any[], day: string): string {
    if (hour === '+') {
      return 'plus-style'; // Nessuna entry per quel giorno
    }
    const entry = attendance.find((item) => item.history_date === day);
    if (entry) {
      if (entry.history_approved) {
        return 'approved-style'; // Stile blu se approved è true
      }
      if (entry.history_not_approved) {
        return 'not-approved-style'; // Stile rosso se not approved è true
      }
    }
    return 'normal-style'; // Stile normale negli altri casi
  }

  getClassTotal(value: any) {
    if (value <= 8) return value === '0' ? 'zero-style' : 'approved-style';
    else {
      return value === '0' ? 'zero-style' : 'not-approved-style';
    }
  }

  getClassTotalWork(value: any) {
    if (value <= 8) return value === '0' ? 'zero-style' : 'approved-style';
    else {
      return value === '0' ? 'zero-style' : 'not-approved-style';
    }
  }

  getClassEnd(value: string): string {
    return value === '+' ? 'plus-end-style' : 'normal-end-style';
  }

  showEndTaskToggle;
  showEndTask() {
    if (this.showEndTaskToggle) {
      if (this.selectedEmployee?.ref_id) {
        this.taskService
          .getTimesheetEnd(this.selectedEmployee.ref_id)
          .subscribe((e) => {
            console.log(e);
            this.rows = e;
            this.loadDataToggle();
            this.showEndTaskToggle = true;
          });
      } else {
        this.taskService
          .getTimesheetEnd(this.userInfo.contracts[0].ref_id)
          .subscribe((e) => {
            console.log(e);
            this.rows = e;
            this.loadDataToggle();
            this.showEndTaskToggle = true;
          });
      }
    } else {
      if (this.selectedEmployee?.ref_id) {
        this.taskService
          .getTimesheet(this.selectedEmployee.ref_id)
          .subscribe((e) => {
            console.log(e);
            this.rows = e;
            this.loadDataToggle();
            this.showEndTaskToggle = false;
          });
      } else {
        this.taskService
          .getTimesheet(this.userInfo.contracts[0].ref_id)
          .subscribe((e) => {
            console.log(e);
            this.rows = e;
            this.loadDataToggle();
            this.showEndTaskToggle = false;
          });
      }
    }
  }

  onTipologiaChange(event: any) {
    const tipologia = event.detail.value;
    const tipologiaControl = this.addSingleForm.get('tipologia');
    const codiceProgettoControl = this.addSingleForm.get('codice_progetto');
    const taskControl = this.addSingleForm.get('task');
    this.showCodiceProgetto = tipologiaControl.value === 'Lavorato';

    // Da vedere in un secondo momento giustificativo obbligatorio
    /* if(tipologiaControl.value === 'Esame' || tipologiaControl.value === 'Donazione sangue' || tipologiaControl.value == 'Malattia'){
        this.valRequired = true
      } */

    if (!this.showCodiceProgetto) {
      codiceProgettoControl.disable();
      codiceProgettoControl.clearValidators();
      taskControl.disable();
      taskControl.clearValidators();
    } else {
      codiceProgettoControl.enable();
      codiceProgettoControl.setValidators(Validators.required);
      taskControl.enable();
      taskControl.setValidators(Validators.required);
    }
    codiceProgettoControl.updateValueAndValidity();
    taskControl.updateValueAndValidity();

    // Salvare il valore precedente della tipologia selezionata
    this.previousTipologia = tipologia;
    // Reset del form solo se la tipologia selezionata è diversa dalla precedente
    if (tipologia !== this.previousTipologia) {
      this.addSingleForm.reset();
    }
    /* if (tipologiaControl.value !== 'Lavorato') {
        console.log(3)
        codiceProgettoControl.enable();
        codiceProgettoControl.setValidators(Validators.required);
      } */
  }

  onTipologiaChangeBulk(event: any, i: number) {
    const tipologia = event.detail.value;
    const tipologiaControlBulk = this.forms[i].get('tipologia');
    const codiceProgettoBulkControl = this.forms[i].get('codice_progetto');
    const taskBulkControl = this.forms[i].get('task');
    this.showCodiceProgettoBulk[i] = tipologiaControlBulk.value === 'Lavorato';

    if (!this.showCodiceProgettoBulk[i]) {
      codiceProgettoBulkControl.disable();
      codiceProgettoBulkControl.clearValidators();
      taskBulkControl.disable();
      taskBulkControl.clearValidators();
    } else {
      codiceProgettoBulkControl.enable();
      codiceProgettoBulkControl.setValidators(Validators.required);
      taskBulkControl.enable();
      taskBulkControl.setValidators(Validators.required);
    }
    codiceProgettoBulkControl.updateValueAndValidity();
    taskBulkControl.updateValueAndValidity();

    const dateControl = this.forms[i].get('date');
    if (
      tipologiaControlBulk.value === 'Ferie' ||
      tipologiaControlBulk.value === 'Malattia' ||
      tipologiaControlBulk.value === 'Lavorato'
    ) {
      dateControl.clearValidators();
    } else {
      dateControl.setValidators([Validators.required, Validators.minLength(2)]);
    }
    dateControl.updateValueAndValidity();

    // Salvare il valore precedente della tipologia selezionata
    this.previousTipologiaBulk = tipologia;
    // Reset del form solo se la tipologia selezionata è diversa dalla precedente
    if (tipologia !== this.previousTipologiaBulk) {
      this.forms[i].reset();
    }
  }

  onTipologiaChangeBulkRequest(event: any, i: number) {
    const tipologia = event.detail.value;
    const tipologiaControlBulk = this.formsRequest[i].get('tipologia');
    const codiceProgettoBulkControl =
      this.formsRequest[i].get('codice_progetto');
    const taskBulkControl = this.formsRequest[i].get('task');
    this.showCodiceProgettoBulk[i] = tipologiaControlBulk.value === 'Lavorato';

    if (!this.showCodiceProgettoBulk[i]) {
      codiceProgettoBulkControl.disable();
      codiceProgettoBulkControl.clearValidators();
      taskBulkControl.disable();
      taskBulkControl.clearValidators();
    } else {
      codiceProgettoBulkControl.enable();
      codiceProgettoBulkControl.setValidators(Validators.required);
      taskBulkControl.enable();
      taskBulkControl.setValidators(Validators.required);
    }
    codiceProgettoBulkControl.updateValueAndValidity();
    taskBulkControl.updateValueAndValidity();

    // Salvare il valore precedente della tipologia selezionata
    this.previousTipologiaBulkRequest = tipologia;
    // Reset del form solo se la tipologia selezionata è diversa dalla precedente
    if (tipologia !== this.previousTipologiaBulkRequest) {
      this.formsRequest[i].reset();
    }
  }

  onTipologiaChangeEdit(event: any) {
    let tipologia: any;
    //console.log(event)
    if (event === 'Lavorato') {
      tipologia = event;
    } else {
      tipologia = event.detail.value;
    }
    const tipologiaControl = this.editForm.get('tipologia');
    const codiceProgettoControl = this.editForm.get('codice_progetto');
    const taskControl = this.editForm.get('task');
    this.showTask = tipologiaControl.value === 'Lavorato';

    // Da vedere in un secondo momento giustificativo obbligatorio
    /* if(tipologiaControl.value === 'Esame' || tipologiaControl.value === 'Donazione sangue' || tipologiaControl.value == 'Malattia'){
        this.valRequired = true
      } */

    if (!this.showTask) {
      codiceProgettoControl.disable();
      codiceProgettoControl.clearValidators();
      taskControl.disable();
      taskControl.clearValidators();
    } else {
      codiceProgettoControl.enable();
      codiceProgettoControl.setValidators(Validators.required);
      taskControl.enable();
      taskControl.setValidators(Validators.required);
    }
    codiceProgettoControl.updateValueAndValidity();
    taskControl.updateValueAndValidity();

    // Salvare il valore precedente della tipologia selezionata
    this.previousTipologia = tipologia;
    // Reset del form solo se la tipologia selezionata è diversa dalla precedente
    if (tipologia !== this.previousTipologia) {
      this.editForm.reset();
    }
    /* if (tipologiaControl.value !== 'Lavorato') {
        console.log(3)
        codiceProgettoControl.enable();
        codiceProgettoControl.setValidators(Validators.required);
      } */
  }

  getWorkingDaysOfMonth(): string[] {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const workingDays: string[] = [];
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      const dayOfWeek = date.getDay();
      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        workingDays.push(
          date.toLocaleDateString('it-IT', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })
        );
      }
    }
    return workingDays;
  }

  formatDate(dateStr: string): string {
    const [year, month, day] = dateStr.split('-'); // Dividi la data in anno, mese e giorno
    return `${day}/${month}/${year}`; // Concatena il giorno, il mese e l'anno nel formato richiesto
  }

  getMancanti() {
    let array: any = [];
    let data = new Date();
    let today = data.toLocaleDateString('it-IT', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    this.userDate.forEach((e: any) => {
      e.cells[3].value = this.formatDate(e.cells[3].value);
    });
    /* console.log(this.userDate) */
    let obj = JSON.stringify(this.userDate);
    let workingDaysUntilToday: any = [];
    for (let i = 0; i < this.workingDaysOfMonth.length; i++) {
      if (today >= this.workingDaysOfMonth[i]) {
        workingDaysUntilToday.push(this.workingDaysOfMonth[i]);
      }
    }
    for (let i = 0; i < workingDaysUntilToday.length; i++) {
      if (!obj.includes(workingDaysUntilToday[i]))
        array.push(workingDaysUntilToday[i]);
    }
    this.arrayMancanti = array;
    if (this.arrayMancanti.length > 0) {
      this.showText = false;
      this.showAlertMancanti = true;
      this.showSpinner = false;
    } else if (this.arrayMancanti.length == 0) {
      /* console.log('finito di caricare') */
      this.showText = true;
      this.showSpinner = false;
      this.showAlertMancanti = false;
    }
  }

  getListAttachment() {
    //Funzione che richiama gli allegati della tabella
    this.timesheet.getListAttachment().subscribe({
      next: (data: any) => {
        this.attachmentList = data.data;
        this.userDate.forEach((e: any) => {
          if (
            e.tipologia === 'Esame' ||
            e.tipologia === 'Donazione sangue' ||
            e.tipologia === 'Malattia'
          ) {
            this.checkAttachment(e, e.id);
          }
        });
      },
    });
  }

  attachRow(idRow: any) {
    //Funzione che richiama gli allegati della tabella
    // this.p = new Attach()
    /* console.log('idRow',idRow) */
    this.arrAttach = this.attachmentList.filter(function (item: any) {
      return item['parentId'] == idRow;
    });
    if (this.arrAttach.length != 0) {
      this.attachment = this.arrAttach.map((e: any) => {
        return e['id']; //ID Allegato
      });
      for (let i = 0; i < this.attachment.length; i++) {
        this.timesheet.getAttachment(this.attachment[i]).subscribe({
          next: (data: any) => {
            this.p!.id = this.attachment[i];
            this.p!.name = data.name;
            this.p!.url = data.url;
            let a = Object.values(this.p!);
            this.arrayAttach.push(a);
            //console.log(this.arrayAttach)
          },
        });
      }
    }
    this.arrayAttach = [];
    //this.setAttachModalOpen(true)
  }

  async onLogout() {
    const alert = await this.alertController.create({
      header: 'Vuoi uscire?',
      buttons: [
        {
          text: 'Annulla',
          handler: () => {
            alert.dismiss;
          },
        },
        {
          text: 'Continua',
          handler: () => {
            this.router.navigateByUrl('/login');
          },
        },
      ],
    });

    await alert.present();
  }

  async inputChanged(event: { target: any }) {
    //Funzione di ricerca per fornitore
    const a = event.target;
    const value = a.value as string;
    this.checkCode = value;
    if (value.length <= 0) {
      this.items = [];
      return;
    }

    const list = await this.codiciProgetto;
    /* console.log(list) */
    let items = list.filter((item: any) => {
      return (
        Boolean(item) &&
        item.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase())
      );
    });

    this.items = items;
  }

  selected(item: string, input: any) {
    //Funzione correlata con la funzione di ricerca per fornitore
    input.value = '';
    this.checkCode = item;
    this.addSingleForm.patchValue({ codice_progetto: item });
    this.items = [];
  }

  selectedEdit(item: string, input: any) {
    //Funzione correlata con la funzione di ricerca per fornitore
    input.value = '';
    this.checkCode = item;
    this.editForm.patchValue({ codice_progetto: item });
    this.items = [];
  }

  async inputChangedUserProject(event: { target: any }) {
    //Funzione di ricerca per fornitore
    const a = event.target;
    const value = a.value as string;
    this.checkCodeUserProject = value;
    if (value.length <= 0) {
      this.items = [];
      return;
    }

    const list = await this.codiciProgetto;
    /* console.log(list) */
    let items = list.filter((item: any) => {
      return (
        Boolean(item) &&
        item.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase())
      );
    });

    this.items = items;
  }

  selectedUserProject(item: string, input: any) {
    //Funzione correlata con la funzione per utente
    input.value = '';
    this.checkCodeUserProject = item;
    this.addRequestForm.patchValue({ codice_progetto: item });
    this.items = [];
  }

  async inputChangedUser(event: { target: any }) {
    //Funzione di ricerca per utente
    const a = event.target;
    const value = a.value as string;
    this.checkCodeUser = value;
    if (value.length <= 0) {
      this.itemsRequest = [];
      return;
    }

    const list = await this.allUsersInfo;
    /* console.log(list) */
    let items = list.filter((item: any) => {
      return (
        Boolean(item) &&
        item.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase())
      );
    });

    this.itemsRequest = items;
  }

  async inputChangedUserBulk(event: { target: any }, i: number) {
    // Aggiungi il parametro i per indicare l'indice del form
    const a = event.target;
    const value = a.value as string;
    this.checkCodeUserBulkRequest = value;
    if (value.length <= 0) {
      this.itemsBulkRequest[i] = []; // Utilizza l'array itemsBulkRequest corretto
      return;
    }

    const list = await this.allUsersInfo;
    /* console.log(list) */
    let items = list.filter((item: any) => {
      return (
        Boolean(item) &&
        item.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase())
      );
    });

    this.itemsBulkRequest[i] = items; // Utilizza l'array itemsRequest corretto
  }

  async inputChangedRequestBulk(event: { target: any }, i: number) {
    // Aggiungi il parametro i per indicare l'indice del form
    const a = event.target;
    const value = a.value as string;
    this.checkCodeUser = value;
    if (value.length <= 0) {
      this.itemsBulkRequestProject[i] = [];
      return;
    }

    const list = await this.codiciProgetto;
    /* console.log(list) */
    let items = list.filter((item: any) => {
      return (
        Boolean(item) &&
        item.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase())
      );
    });

    this.itemsBulkRequestProject[i] = items; // Utilizza l'array itemsRequest corretto
  }

  async inputChangedUserSingleForm(event: { target: any }) {
    const a = event.target;
    const value = a.value as string;
    this.checkCodeUser = value;
    if (value.length <= 0) {
      this.itemsRequest = [];
      return;
    }

    const list = await this.allUsersInfo;
    /* console.log(list) */
    let items = list.filter((item: any) => {
      return (
        Boolean(item) &&
        item.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase())
      );
    });

    this.itemsRequest = items;
  }

  selectedUser(item: string, input: any) {
    //Funzione correlata con la funzione di ricerca per fornitore
    input.value = '';
    this.checkCodeUser = item;
    let userEmail;
    this.addRequestForm.patchValue({ userInfo_richiesto: item });
    userEmail = this.usersInfo.filter((e: any) => e.fullName === item); //ricerca l'utente selezionato e aggiunge l'email al form
    this.addRequestForm.patchValue({ email: userEmail[0].email });
    this.itemsRequest = [];
  }

  selectedUserRequestBulk(item: string, input: any, i: any) {
    //Funzione correlata con la funzione di ricerca per fornitore
    input.value = '';
    this.checkCodeUserBulkRequest = item;
    let userEmail;
    this.formsRequest[i].patchValue({ userInfo_richiesto: item });
    userEmail = this.usersInfo.filter((e: any) => e.fullName === item); //ricerca l'utente selezionato e aggiunge l'email al form
    this.formsRequest[i].patchValue({ email: userEmail[0].email });
    this.itemsBulkRequest[i] = [];
  }

  selectedUserSingle(item: string, input: any) {
    //Funzione correlata con la funzione di ricerca per fornitore
    input.value = '';
    this.checkCodeUser = item;
    this.addSingleForm.patchValue({ richiedente: item });
    this.itemsRequest = [];
  }

  selectedBulk(item: string, input: any, i: any) {
    //Funzione correlata con la funzione di ricerca per fornitore
    //input[i].value = '';
    this.checkCode = item;
    this.forms[i].patchValue({ codice_progetto: item });
    this.items = [];
  }

  selectedRequestBulk(item: string, input: any, i: any) {
    //Funzione correlata con la funzione di ricerca per fornitore
    input.value = '';
    this.checkCode = item;
    this.formsRequest[i].patchValue({ codice_progetto: item });
    this.itemsBulkRequestProject[i] = [];
  }

  onProfile() {
    this.router.navigateByUrl('/home/user-profile');
  }

  async submitUpload() {
    //Submit aggiungi pagamento
    let formData = new FormData();
    //console.log(this.file)
    formData.append('attachment', this.file, this.file.name);
    this.timesheet.upload(this.row_id, formData).subscribe({
      next: (data) => {
        this.file = null!;
      },
    });
  }

  async submitUploadSingle(rowId: any) {
    //Submit aggiungi pagamento
    let formData = new FormData();
    //console.log(this.file)
    formData.append('attachment', this.file, this.file.name);
    this.timesheet.upload(rowId, formData).subscribe({
      next: (data) => {
        this.file = null!;
      },
    });
  }

  onActivate(event: any) {
    // Dettagli riga

    if (
      event.type == 'click' &&
      this.isResp &&
      event.cellIndex == 2 &&
      event.row.project_name !== 'Totale' &&
      event.row?.timesheet.length !== 0
    ) {
      this.setTaskDetailOpen(true);
      console.log(event);
      //  = event.row.timesheet;
      this.userTask = event.row.timesheet.sort((a, b) => {
        const aNullCount =
          (a.workhistory_approved === null ? 1 : 0) +
          (a.workhistory_not_approved === null ? 1 : 0);
        const bNullCount =
          (b.workhistory_approved === null ? 1 : 0) +
          (b.workhistory_not_approved === null ? 1 : 0);

        if (aNullCount > bNullCount) {
          return -1;
        } else if (aNullCount < bNullCount) {
          return 1;
        } else {
          return 0;
        }
      });
      console.log(this.userTask);
      this.allApproved = this.checkAllApproved(this.userTask);
    }
    if (
      event.type == 'click' &&
      this.isResp &&
      event.cellIndex == 0 &&
      event.row.project_name !== 'Totale' &&
      event.row.timesheet
    ) {
      console.log(event);
      this.currentRow = event.row;
      this.addPrjToTask();
    }
    // if (
    //   event.type == 'click' &&
    //   this.isResp &&
    //   event.cellIndex == 0 &&
    //   event.row.project_name !== 'Totale' &&
    //   event.row.project_code !== ''
    // ) {
    //   this.copyToClipboard(event.row.project_code);
    // }
    if (
      event.type == 'click' &&
      !this.isResp &&
      event.cellIndex == 0 &&
      event.row.project_name !== 'Totale' &&
      event.row.project_code !== ''
    ) {
      this.copyToClipboard(event.row.project_code);
    }
  }

  copyToClipboard(text: string): void {
    if (text) {
      const textToCopy = text;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          console.log('Testo copiato negli appunti:', textToCopy);

          this.presentToast(textToCopy);
        })
        .catch((err) => {
          console.error('Errore durante la copia negli appunti:', err);
        });
    } else {
      console.log('Nessun testo tra parentesi quadre trovato');
      // Gestisci il caso in cui non ci sono parentesi quadre
    }
  }

  onActivateAttendance(event: any) {
    // Dettagli riga
    if (
      event.type == 'click' &&
      this.isResp &&
      event.cellIndex == 0 &&
      event.row.project_name !== 'Totale' &&
      event.row?.attendance.length !== 0
    ) {
      this.setAttendanceDetailOpen(true);
      console.log(event);
      this.userTask = event.row.attendance;
      console.log(this.userTask);
      this.allApproved = this.checkAllApproved(this.userTask);
    }
  }

  addPrjToTask() {
    this.setPrjTaskModalRespOpen(true);
  }

  notApproved: boolean;
  // onCheckboxChange(taskGroup: FormGroup, checkbox: any, all: any) {
  //   console.log(taskGroup);
  //   console.log(checkbox);
  //   console.log(all);

  //   this.addTaskForm = this.formBuilder.group({
  //     // taskresource_id: [this.rows[0].taskresource_id, Validators.required],
  //     approved: ['', Validators.required],
  //   });
  //   if (checkbox === '1') {
  //     this.addTaskForm.controls['approved'].setValue('1');
  //     this.taskService
  //       .approveHour(taskGroup['workhistory_id'], this.addTaskForm.value)
  //       .subscribe((e) => {
  //         console.log(e);
  //       });
  //   } else if (checkbox === '0') {
  //     this.addTaskForm.controls['approved'].setValue('0');
  //     this.taskService
  //       .approveHour(taskGroup['workhistory_id'], this.addTaskForm.value)
  //       .subscribe((e) => {
  //         console.log(e);
  //       });
  //   } else if (checkbox.detail.checked === true) {
  //     this.addTaskForm.controls['approved'].setValue('1');
  //     this.taskService
  //       .approveHour(taskGroup['workhistory_id'], this.addTaskForm.value)
  //       .subscribe((e) => {
  //         console.log(e);
  //       });
  //   } else if (checkbox.detail.checked === false) {
  //     this.addTaskForm.controls['approved'].setValue('0');
  //     this.taskService
  //       .approveHour(taskGroup['workhistory_id'], this.addTaskForm.value)
  //       .subscribe((e) => {
  //         console.log(e);
  //       });
  //   }
  // }

  onCheckboxChange(task, event, checkboxType) {
    console.log(event);

    const isChecked = event.detail.checked;

    if (checkboxType === 'approved') {
      task.workhistory_approved = isChecked;
      if (isChecked) {
        task.workhistory_not_approved = false;
      }
      this.addTaskForm = this.formBuilder.group({
        approved: [task.workhistory_approved ? '1' : '0', Validators.required],
      });
    } else if (checkboxType === 'notApproved') {
      task.workhistory_not_approved = isChecked;
      if (isChecked) {
        task.workhistory_approved = false;
      }
      this.addTaskForm = this.formBuilder.group({
        not_approved: [
          task.workhistory_not_approved ? '1' : '0',
          Validators.required,
        ],
      });
    }

    this.taskService
      .approveHour(task.workhistory_id, this.addTaskForm.value)
      .subscribe((response) => {
        console.log(response);
      });
  }

  onCheckboxChangeAttendance(task, event, checkboxType) {
    const isChecked = event.detail.checked;

    if (checkboxType === 'approved') {
      task.history_approved = isChecked;
      if (isChecked) {
        task.history_not_approved = false;
      }
      this.addTaskForm = this.formBuilder.group({
        approved: [task.history_approved ? '1' : '0', Validators.required],
      });
    } else if (checkboxType === 'notApproved') {
      task.history_not_approved = isChecked;
      if (isChecked) {
        task.history_approved = false;
      }
      this.addTaskForm = this.formBuilder.group({
        not_approved: [
          task.history_not_approved ? '1' : '0',
          Validators.required,
        ],
      });
    }

    this.taskService
      .approveHourAttendance(task.history_id, this.addTaskForm.value)
      .subscribe((response) => {
        console.log(response);
      });
  }

  onCheckboxChangeAttendance_1(
    taskGroup: FormGroup,
    checkbox: any,
    all: boolean
  ) {
    console.log(taskGroup);

    this.addTaskForm = this.formBuilder.group({
      // taskresource_id: [this.rows[0].taskresource_id, Validators.required],
      approved: ['', Validators.required],
    });
    if (checkbox === '1') {
      this.addTaskForm.controls['approved'].setValue('1');
      this.taskService
        .approveHourAttendance(taskGroup['history_id'], this.addTaskForm.value)
        .subscribe((e) => {
          console.log(e);
        });
    } else if (checkbox === '0') {
      this.addTaskForm.controls['approved'].setValue('0');
      this.taskService
        .approveHourAttendance(taskGroup['history_id'], this.addTaskForm.value)
        .subscribe((e) => {
          console.log(e);
        });
    } else if (checkbox.detail.checked === true) {
      this.addTaskForm.controls['approved'].setValue('1');
      this.taskService
        .approveHourAttendance(taskGroup['history_id'], this.addTaskForm.value)
        .subscribe((e) => {
          console.log(e);
        });
    } else if (checkbox.detail.checked === false) {
      this.addTaskForm.controls['approved'].setValue('0');
      this.taskService
        .approveHourAttendance(taskGroup['history_id'], this.addTaskForm.value)
        .subscribe((e) => {
          console.log(e);
        });
    }
  }

  toggleAllApproval(event, all: boolean) {
    console.log(event);

    this.userTask.forEach((task) => {
      this.onCheckboxChange(task, event, 'approved');
    });

    this.taskService
      .getTimesheet(this.selectedEmployee.ref_id)
      .subscribe((e) => {
        console.log('1238');

        this.rows = [...e];
        this.rows.forEach((e) => {
          if (e.workhistory_approved) this.allApproved = true;
          else {
            this.allApproved = false;
          }
        });
        this.setTaskDetailOpen(false, false);
        this.loadData();
      });
    // this.setTaskDetailOpen(false, false);
  }

  toggleAllApprovalAttendance(event, all: boolean) {
    this.userTask.forEach((task) => {
      this.onCheckboxChangeAttendance(
        task,
        event.detail.checked ? '1' : '0',
        true
      );
    });
    this.taskService
      .getTimesheetWork(this.selectedEmployee.ref_id)
      .subscribe((e) => {
        console.log('1238');

        this.rowsAttendance = [...e];
        this.rows.forEach((e) => {
          if (e.history_approved) this.allApproved = true;
          else {
            this.allApproved = false;
          }
        });
        this.setAttendanceDetailOpen(false, false);
        this.loadDataAttendance();
      });
  }

  onBulkModalDismiss() {
    // Svuota il form e crea un nuovo formGroup
    this.forms = [this.createBulkForm()];
    this.showCodiceProgettoBulk[0] = false;
    this.isBulkModalOpen = false;
  }

  onBulkRequestModalDismiss() {
    // Svuota il form e crea un nuovo formGroup
    this.formsRequest = [this.createBulkRequestForm()];
    this.showCodiceProgettoBulk[0] = false;
    this.isBulkRequestModalOpen = false;
  }

  setBulkRequestModalOpen(isOpen: boolean) {
    this.isBulkRequestModalOpen = isOpen;
    this.itemsBulkRequest.push([]);
    this.itemsBulkRequestProject.push([]);
    if (isOpen && this.formsRequest.length === 0) {
      this.addNewFormRequest();
    }
  }

  setRequestModalOpen(isOpen: boolean) {
    //Modale dettagli
    this.isModalRequestOpen = isOpen;
    if (isOpen === true) {
      let currentDate = new Date().toISOString().split('T')[0];
      this.addRequestForm = this.formBuilder.group({
        codice_progetto: ['', Validators.required],
        tipologia: ['Lavorato', Validators.required],
        task: ['', Validators.required],
        userInfo_richiesto: ['', Validators.required],
        date: [currentDate, Validators.required],
        notes: [''],
        email: [''],
        richiedente: [''],
      });
    }
  }

  createTask() {
    console.log(this.addTaskForm.value);
    if (this.addTaskForm.get('job_id').value.length === 0) {
      this.addTaskForm.patchValue({ job_id: 0 });
    }
    this.taskService.createTask(this.addTaskForm.value).subscribe((e) => {
      console.log(e);
      if (e) {
        let form = {
          task_id: e.data.id,
          resource_id: this.userInfo.contracts[0].ref_id,
        };
        console.log(form);
        this.taskService.createTaskResource(form).subscribe((e) => {
          if (e) {
            this.taskService.getTimesheet(this.employee_id).subscribe((e) => {
              console.log(e);
              this.rows = e;
              this.loadData();
            });
          }
        });
      }
    });
    this.isNewTaskModalOpen = false;
  }

  updateTask() {
    console.log(this.addPrjTaskForm.value);

    this.taskService.updateTask(this.addPrjTaskForm.value).subscribe((e) => {
      console.log(e);
      if (e) {
        this.taskService
          .getTimesheet(this.selectedEmployee.ref_id)
          .subscribe((e) => {
            console.log(e);
            this.rows = e;
            this.loadData();
            this.isNewSetPrjTask = false;
          });
      }
    });
  }

  createTaskResp() {
    console.log(this.addTaskForm.value);
    if (this.addTaskForm.get('job_id').value.length === 0) {
      this.addTaskForm.patchValue({ job_id: 0 });
    }
    this.taskService.createTask(this.addTaskForm.value).subscribe((e) => {
      console.log(e);
      if (e) {
        if (this.selectedEmployeeForm.length == 1) {
          let form = {
            task_id: e.data.id,
            resource_id: this.selectedEmployeeForm[0].ref_id,
            max_hour: this.addTaskForm.value.max_hour,
          };

          this.taskService.createTaskResource(form).subscribe((e) => {
            if (e) {
              this.taskService
                .getTimesheet(this.selectedEmployeeForm[0].ref_id)
                .subscribe((e) => {
                  this.rows = [...e];
                  this.loadData();
                });
            }
          });
        } else {
          this.selectedEmployeeForm.forEach((employee) => {
            let form = {
              task_id: e.data.id,
              resource_id: employee.ref_id,
              max_hour:
                this.addTaskForm.value.max_hour /
                this.selectedEmployeeForm.length,
            };

            this.taskService.createTaskResource(form).subscribe((e) => {
              if (e) {
                this.taskService
                  .getTimesheet(employee.ref_id)
                  .subscribe((e) => {
                    this.rows = [...e];
                    this.loadData();
                  });
              }
            });
          });
        }
      }
    });
    this.isNewTaskModalRespOpen = false;
  }

  setNewTaskModalOpen(isOpen: boolean) {
    //Modale dettagli
    this.isNewTaskModalOpen = isOpen;
    this.prj = [];
    this.committer = [];
    if (isOpen === true) {
      this.addTaskForm = this.formBuilder.group({
        name: ['', Validators.required],
        description: ['', Validators.required],
        job_id: [this.prj, Validators.required],
        date_start: [''],
        date_end: [''],
      });
    }
  }

  setPrjTaskModalRespOpen(isOpen: boolean) {
    //Modale dettagli
    this.isNewSetPrjTask = isOpen;
    if (isOpen === true) {
      this.addPrjTaskForm = this.formBuilder.group({
        job_id: [
          this.currentRow.project_id !== 0 ? this.currentRow.project_id : '',
          Validators.required,
        ],
        task_id: [this.currentRow.task_id, Validators.required],
      });
      console.log(this.addPrjTaskForm);
    } else {
      this.editPrj = false;
    }
  }

  setNewTaskModalRespOpen(isOpen: boolean) {
    //Modale dettagli
    console.log('aaaa');
    this.prj = [];
    this.committer = [];
    this.isNewTaskModalRespOpen = isOpen;
    if (isOpen === true) {
      this.addTaskForm = this.formBuilder.group({
        name: ['', Validators.required],
        max_hour: ['', Validators.required],
        description: ['', Validators.required],
        job_id: [this.prj, Validators.required],
        date_start: [''],
        date_end: [''],
      });
    }
  }
  setTaskDetailOpen(isOpen?: boolean, value?: boolean) {
    //Modale dettagli
    this.isTaskDetailsOpen = isOpen;
    if (isOpen === true) {
    } else if (isOpen === false && value === false) {
      this.callbackModal();
    } else {
      this.isTaskDetailsOpen = false;
    }
  }

  callbackModal() {
    this.taskService
      .getTimesheet(this.selectedEmployee.ref_id)
      .subscribe((e) => {
        this.rows = [...e];
        this.rows.forEach((e) => {
          if (e.workhistory_approved) this.allApproved = true;
          else {
            this.allApproved = false;
          }
        });
        this.loadData();
      });
  }

  setAttendanceDetailOpen(isOpen?: boolean, value?: boolean) {
    //Modale dettagli
    this.isAttendanceDetailsOpen = isOpen;
    if (isOpen === true) {
    } else if (isOpen === false && value === false) {
      this.callbackModalAttendance();
    } else {
      this.isTaskDetailsOpen = false;
    }
  }

  async setChartsOpen(isOpen?: boolean, value?: boolean) {
    const modal = await this.modalCtrl.create({
      component: TimesheetChartsPage,
      cssClass: 'fullscreen',
      componentProps: {
        rows: this.rowsEnd,
        user: this.userInfo,
        ////nav: this.nav  // Pass nav for IonNav from modal if necessary
      },
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    // if (role === 'confirm') {
    //   this.message = `Hello, ${data}!`;
    // }
  }

  async setChartsOpenAll(isOpen?: boolean, value?: boolean) {
    const modal = await this.modalCtrl.create({
      component: TimesheetChartsPage,
      cssClass: 'fullscreen',
      componentProps: {
        rows: this.rowsEnd,
        user: this.selectedEmployee,
        deps: this.userInfo.deps,
        isResp: this.isResp,
        ////nav: this.nav  // Pass nav for IonNav from modal if necessary
      },
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    // if (role === 'confirm') {
    //   this.message = `Hello, ${data}!`;
    // }
  }

  callbackModalAttendance() {
    this.taskService
      .getTimesheetWork(this.selectedEmployee.ref_id)
      .subscribe((e) => {
        this.rowsAttendance = [...e];
        this.rowsAttendance.forEach((e) => {
          if (e.history_approved) this.allApproved = true;
          else {
            this.allApproved = false;
          }
        });
        this.loadDataAttendance();
      });
  }
  checkAllApproved(task: any) {
    console.log(task);
    let counter: boolean = true;
    task.forEach((e) => {
      if (!e.workhistory_approved) counter = false;
    });
    return counter;
  }
  setBulkModalOpen(isOpen: boolean) {
    //Modale dettagli
    this.isBulkModalOpen = isOpen;
    if (isOpen && this.forms.length === 0) {
      console.log(this.forms.length);
      this.addNewForm();
    }
  }

  setEditModalOpen(isOpen: boolean, item: any) {
    this.isEditModalOpen = isOpen;
    if (item !== '') {
      this.row_id = item.id;
      this.editForm.patchValue({ tipologia: item.tipologia });
      this.editForm.patchValue({ codice_progetto: item.codice });
      this.editForm.patchValue({ task: item.task });
      this.editForm.patchValue({ date: item.data });
      this.editForm.patchValue({ hours: item.ore });
      this.editForm.patchValue({ notes: item.note });
      this.editForm.patchValue({ firstname: this.userInfo.firstname });
      this.editForm.patchValue({ lastname: this.userInfo.lastname });
      this.editForm.patchValue({ richiedente: this.fullNameRichiedente });
    }
    if (isOpen === false) this.editForm.reset();
  }

  addNewForm() {
    if (this.forms.length < 40) {
      this.forms.push(this.createBulkForm());
      this.showCodiceProgettoBulk.push(false);
      if (this.forms.length + 1 > 40) {
        this.showAddButton = false;
      }
    }
  }

  addNewFormRequest() {
    this.itemsBulkRequest.push([]);
    this.itemsBulkRequestProject.push([]);
    if (this.formsRequest.length < 40) {
      this.formsRequest.push(this.createBulkRequestForm());
      this.showCodiceProgettoBulkRequest.push(false);
      if (this.formsRequest.length + 1 > 40) {
        this.showAddButton = false;
      }
    }
  }

  removeForm(i: number) {
    this.forms.splice(i, 1);
    if (this.forms.length < 7) {
      this.showAddButton = true;
    }
  }

  canRemove(i: number) {
    return this.forms.length > 1 && i > 0;
  }

  removeFormRequest(i: number) {
    this.formsRequest.splice(i, 1);
    if (this.formsRequest.length < 7) {
      this.showAddButton = true;
    }
  }

  canRemoveRequest(i: number) {
    return this.formsRequest.length > 1 && i > 0;
  }

  isSubmitDisabled() {
    return !this.forms.every((f) => f.valid) || this.submitting;
  }

  isSubmitRequestDisabled() {
    return !this.formsRequest.every((f) => f.valid) || this.submitting;
  }

  createBulkForm(): FormGroup {
    let currentDate = new Date().toISOString().split('T')[0];
    return this.formBuilder.group({
      tipologia: ['', Validators.required],
      codice_progetto: [
        { value: '', disabled: !this.showCodiceProgettoBulk },
        this.showCodiceProgettoBulk ? Validators.required : null,
      ],
      task: [{ value: '', disabled: !this.showCodiceProgettoBulk }],
      date: [currentDate, Validators.required],
      hours: ['', Validators.required],
      notes: [''],
      firstname: [''],
      lastname: [''],
      start_date: [''],
      end_date: [''],
      richiedente: [this.fullNameRichiedente],
    });
  }

  createBulkRequestForm(): FormGroup {
    let currentDate = new Date().toISOString().split('T')[0];
    return this.formBuilder.group({
      codice_progetto: ['', Validators.required],
      tipologia: ['Lavorato', Validators.required],
      task: ['', Validators.required],
      userInfo_richiesto: ['', Validators.required],
      date: [currentDate, Validators.required],
      notes: [''],
      email: [''],
      richiedente: [this.responsabileInfo],
    });
  }

  async addRowsWithPromise(form: FormGroup, dateArray: string[]) {
    for (let i = 0; i < dateArray.length; i++) {
      form.patchValue({
        firstname: this.userInfo.firstname,
        lastname: this.userInfo.lastname,
        richiedente: this.fullNameRichiedente,
        date: dateArray[i],
      });

      await new Promise<void>((resolve, reject) => {
        this.timesheet.addRow(form.value).subscribe({
          next: (data) => {
            if (data.message === 'SUCCESS') {
              this.currentStep++;
              if (this.currentStep === this.totalSteps) {
                this.progressBar = false;
                this.forms = [this.createBulkForm()];
                this.loadPage();
              }
              resolve();
            }
          },
          error: (error) => {
            reject(error);
          },
        });
      });
    }
  }

  async alertDeleteAttach(id: any, attachName: any) {
    //Funzione controller elimina allegato
    const alert = await this.alertController.create({
      header: 'Vuoi cancellare questo allegato?',
      message: attachName,
      buttons: [
        {
          text: 'Annulla',
          role: 'cancel',
          handler: () => {
            //console.log("Annullato")
          },
        },
        {
          text: 'Confermo',
          role: 'confirm',
          handler: () => {
            this.timesheet.deleteAttachment(id).subscribe({
              next: (data: any) => {
                this.attachment = this.arrAttach.map((e: any) => {
                  return e['id']; //ID Allegato
                });
                this.arrayAttach = [];
                for (let i = 0; i < this.attachment.length; i++) {
                  this.timesheet.getAttachment(this.attachment[i]).subscribe({
                    next: (data: any) => {
                      this.p!.id = this.attachment[i];
                      this.p!.name = data.name;
                      this.p!.url = data.url;
                      let a = Object.values(this.p!);
                      this.arrayAttach.push(a);
                      //console.log(this.arrayAttach)
                    },
                  });
                }
              },
            });
            this.loadPage();
          },
        },
      ],
    });

    await alert.present();
  }

  /* SUPER-REPOSPOSABILI */

  loadSuper() {
    this.projectStats = this.formBuilder.group({
      codice_progetto: [''],
    });
  }
  async inputChangedSuper(event: { target: any }) {
    //Funzione di ricerca per fornitore
    const a = event.target;
    const value = a.value as string;
    if (value.length <= 0) {
      this.items = [];
      return;
    }

    const list = await this.codiciProgetto;
    /* console.log(list) */
    let items = list.filter((item: any) => {
      return (
        Boolean(item) &&
        item.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase())
      );
    });

    this.items = items;
  }

  setSelectedOptionSuper(i: any) {
    setTimeout(() => {
      this.selectedOption = 'Tutte';
      const selectElement = document.querySelector(`#mySelect`);
      selectElement!.setAttribute('value', 'Tutte');
    }, 300);
  }

  closeTimesheet() {
    this.modalCtrl.dismiss();
  }

  goBack() {
    console.log(this.routingState);
    this.router.navigate(['/' + this.routingState.navigateBack()]);
  }

  onSearchInput(event: any) {
    console.log(event);
    let name = event.detail.value;
    if (name != '') {
      console.log(this.employee_id);

      let url = this.employee_id + '/?project=' + name;
      this.taskService.getTimesheetSearch(url).subscribe((e) => {
        this.rows = [...e];
        this.loadData();
      });
    } else {
      this.taskService.getTimesheet(this.employee_id).subscribe((e) => {
        this.rows = [...e];
        this.loadData();
      });
    }
  }

  onGetRowClass = (row: any) => {
    if (!row.status) return '';
    //console.log(row);
    // id 3 is Delete for Status Order
    if (row.status.id === 4 || row.status.id === 5) {
      return 'deleted';
    } else if (row.status.id === 2) {
      return 'request';
    } else if (row.status.id === 1) {
      return 'draft';
    } else if (row.status.id === 3) {
      return 'order';
    } else return '';
  };

  queryFilter = {
    search_by: 'sort',
    search_data: [{ prop: 'priority.id', dir: 'asc' }],
  };

  sorted(columnProp: string, direction: string) {
    console.log(columnProp, direction);
    if (columnProp == 'priority.name') {
      columnProp = 'priority.id';
    }

    this.queryFilter = {
      search_by: 'sort',
      search_data: [{ prop: columnProp, dir: direction }],
    };
    localStorage.setItem(
      'filter_sort_orders',
      JSON.stringify({
        search_by: 'sort',
        search_data: [{ prop: columnProp, dir: direction }],
      })
    );

    console.log(this.queryFilter);
  }

  calculateRowSums() {
    this.rows.forEach((row) => {
      // Calculate sum of days for each row
      const daySum = this.days.reduce((acc, day) => acc + (row[day] || 0), 0);
      row['daySum'] = daySum; // Add a new property to store the sum
    });
  }

  columnTotals: any = {};

  calculateColumnTotals() {
    const totals = { activity: 'Total' }; // Assuming activity doesn't need a sum
    this.days.forEach((day) => {
      totals[day] = this.rows.reduce((acc, row) => acc + (row[day] || 0), 0);
    });
    totals['daySum'] = this.days.reduce((acc, day) => acc + totals[day], 0);
    this.columnTotals = totals; // This can be a property used to display the total row
    this.rows.push(this.columnTotals); // Optionally, add it as the last row
  }

  searchPortsPrj(
    event /*:  { component: IonicSelectableComponent; text: string }, type */
  ) {
    console.log(event, event.text);
    this.selectedText = event.text;
    //console.log(event.component._modalComponent);
    //console.log(event.component._modalComponent._content);

    //event.component._modalComponent._content.scrollToBottom(1500);
    //event.component._modalComponent._content.scrollY = false

    let url = '';

    const text = event.text.trim().toLowerCase();
    //event.component.startSearch();

    // Close any running subscription.
    if (this.prjSubscription) {
      this.prjSubscription.unsubscribe();
    }

    if (!text) {
      // Close any running subscription.
      if (this.prjSubscription) {
        this.prjSubscription.unsubscribe();
      }

      //event.component.endSearch();
      //event.component.enableInfiniteScroll();
      switch (event['module']) {
        case 'project':
          // url = '?page=' + this.page_project + '&items=' + this.size;
          if (this.committer.length == 0) {
            if (this.selectedEmployee?.ref_id) {
              this.taskService
                .getJobsByResource(this.selectedEmployee.ref_id)
                .subscribe((data) => {
                  console.log('!text call more getProjectList', data);
                  console.log(data);

                  //data.results = event.component.items = (data.results);

                  //this.numPage_project = data.num_pages;

                  //event.component.items = data.results;
                  this.onSearchSelector = '';
                  this.numPage_project = data.num_pages;
                  this.projects = data.results;
                  //event.component.endInfiniteScroll();
                });
            } else {
              this.taskService
                .getJobsByResource(this.userInfo.contracts[0].ref_id)
                .subscribe((data) => {
                  console.log('!text call more getProjectList', data);
                  console.log(data);

                  //data.results = event.component.items = (data.results);

                  //this.numPage_project = data.num_pages;

                  //event.component.items = data.results;
                  this.onSearchSelector = '';
                  this.numPage_project = data.num_pages;
                  this.projects = data.results;
                  //event.component.endInfiniteScroll();
                });
            }
          } else {
            if (this.selectedEmployee?.ref_id) {
              this.taskService
                .getJobsByResource(this.selectedEmployee.ref_id)
                .subscribe((data) => {
                  console.log('!text call more getProjectList', data);
                  console.log(data);

                  //data.results = event.component.items = (data.results);

                  //this.numPage_project = data.num_pages;

                  //event.component.items = data.results;
                  this.onSearchSelector = '';
                  this.numPage_project = data.num_pages;
                  this.projects = data.results;
                  //event.component.endInfiniteScroll();
                });
            } else {
              this.taskService
                .getJobsByResource(this.userInfo.contracts[0].ref_id)
                .subscribe((data) => {
                  console.log('!text call more getProjectList', data);
                  console.log(data);

                  //data.results = event.component.items = (data.results);

                  //this.numPage_project = data.num_pages;

                  //event.component.items = data.results;
                  this.onSearchSelector = '';
                  this.numPage_project = data.num_pages;
                  this.projects = data.results;
                  //event.component.endInfiniteScroll();
                });
            }
          }
          break;
        case 'committer':
          // url = '?page=' + this.page_project + '&items=' + this.size;

          if (this.selectedEmployee?.ref_id) {
            this.taskService
              .getJobsByResource(this.selectedEmployee.ref_id)
              .subscribe((data) => {
                console.log('!text call more getCommitterList', data);
                console.log(data);

                //data.results = event.component.items = (data.results);

                //this.numPage_project = data.num_pages;

                //event.component.items = data.results;
                this.onSearchSelector = '';
                this.numPage_vendor = data.num_pages;
                this.vendors = data.results;
                //event.component.endInfiniteScroll();
              });
          } else {
            this.taskService
              .getJobsByResource(this.userInfo.contracts[0].ref_id)
              .subscribe((data) => {
                console.log('!text call more getCommitterList', data);
                console.log(data);

                //data.results = event.component.items = (data.results);

                //this.numPage_project = data.num_pages;

                //event.component.items = data.results;
                this.onSearchSelector = '';
                this.numPage_vendor = data.num_pages;
                this.vendors = data.results;
                //event.component.endInfiniteScroll();
              });
          }
          break;
      }

      return;
    }

    switch (event['module']) {
      case 'project':
        // url = '?page=' + this.page_project + '&items=25&name=' + text;
        this.prjSubscription = this.taskService
          .getJobsByResource(this.selectedEmployee.ref_id)
          .subscribe((data) => {
            console.log('text call from search getVendorsList', data);
            // Subscription will be closed when unsubscribed manually.
            if (this.prjSubscription.closed) {
              return;
            }
            this.onSearchSelector = text;
            this.numPage_project = data?.num_pages;
            this.projects = data.results;
          });

        break;
      case 'committer':
        // url = '?page=' + this.page_project + '&items=25&name=' + text;
        this.prjSubscription = this.taskService
          .getJobsByResource(this.selectedEmployee.ref_id)
          .subscribe((data) => {
            console.log('text call from search getCommitterList', data);
            // Subscription will be closed when unsubscribed manually.
            if (this.prjSubscription.closed) {
              return;
            }
            this.onSearchSelector = '';
            this.numPage_vendor = data.num_pages;
            this.vendors = data.results;
          });

        break;
    }
  }
  filterPorts(vendors: any, text: string) {
    console.log(vendors);
    return vendors.filter((vendors) => {
      return vendors.name.toLowerCase().indexOf(text) !== -1; //||
      //port.country.name.toLowerCase().indexOf(text) !== -1 ||
      //port.id.toString().toLowerCase().indexOf(text) !== -1;
    });
  }

  onInfiniteScroll(
    /* 
event: { component: IonicSelectableComponent; text: string },
type: string */ event
  ) {
    let url;
    let _type = event['type'] ? event['type'] : null;
    console.log('after', _type);

    url = '?page=' + ++this.page_project + '&items=' + this.size;

    this.taskService.getJobsByResource(url).subscribe((data) => {
      console.log('!text call more getVendorsList', data);
      console.log(data);

      //data.results = event.component.items = (data.results);

      //this.numPage_project = data.num_pages;

      //event.component.items = data.results;
      this.onSearchSelector = '';
      this.numPage_project = data.num_pages;
      this.projects = data.results;
      //event.component.endInfiniteScroll();
    });
  }

  onInfiniteScrollCommitter(
    /* 
event: { component: IonicSelectableComponent; text: string },
type: string */ event
  ) {
    let url;
    let _type = event['type'] ? event['type'] : null;
    console.log('after', _type);

    url = '?page=' + ++this.page_project + '&items=' + this.size;

    this.taskService.getJobsByResource(url).subscribe((data) => {
      console.log('!text call more getVendorsList', data);
      console.log(data);

      //data.results = event.component.items = (data.results);

      //this.numPage_project = data.num_pages;

      //event.component.items = data.results;
      this.onSearchSelector = '';
      this.numPage_vendor = data.num_pages;
      this.vendors = data.results;
      //event.component.endInfiniteScroll();
    });
  }

  onInfiniteScrollPrj(
    /* 
event: { component: IonicSelectableComponent; text: string },
type: string */ event
  ) {
    let url;
    let _type = event['type'] ? event['type'] : null;
    console.log('after', _type);

    // url = '?page=' + ++this.page_project + '&items=' + this.size;
    console.log(this.selectedEmployee);

    this.taskService
      .getJobsByResource(this.selectedEmployee.ref_id)
      .subscribe((data) => {
        console.log('!text call more getVendorsList', data);
        console.log(data);

        //data.results = event.component.items = (data.results);

        //this.numPage_project = data.num_pages;

        //event.component.items = data.results;
        this.onSearchSelector = '';
        this.numPage_project = data.num_pages;
        this.projects = data.results;
        //event.component.endInfiniteScroll();
      });
  }
  onSelectProjectComp(selected) {
    this.prj = selected.item.id;
    console.log(this.prj);
    this.addTaskForm.patchValue({ job_id: this.prj });
    console.log(this.addTaskForm.value);
  }

  onSelectCommitterComp(selected) {
    this.committer = selected.item.id;
    console.log(this.committer);
    // this.addTaskForm.patchValue({ job_id: this.committer });
    // console.log(this.addTaskForm.value);
  }

  onSelectProjectTaskComp(selected) {
    this.prj = selected.item.id;
    console.log(this.prj);
    console.log(this.currentRow);

    this.addPrjTaskForm.patchValue({ job_id: this.prj });
    console.log(this.addPrjTaskForm.value);
  }
  selectedEmployeeForm: any;
  OnSelectEmployee(selected: any) {
    console.log(selected);
    this.selectedEmployeeForm = selected.detail.value;
    console.log(this.selectedEmployee);
    this.addTaskForm.patchValue({ ref_id: this.selectedEmployeeForm.ref_id });
    console.log(this.addTaskForm.value);
  }
  editPrj: boolean = false;
  editPrjButton() {
    this.editPrj = true;
  }

  async presentToast(statusText) {
    const toast = await this.toastController.create({
      message: 'Codice commessa copiato: ' + statusText,
      duration: 2000,
      mode: 'ios',
      cssClass: 'toast',
      color: 'success',
    });
    toast.present();
  }

  onDetailToggle(event) {
    // console.log('Detail Toggled', event);
  }

  toggleExpandGroup(group) {
    // console.log('Toggled Expand Group!', group);
    this.groupExpansionDefaultStatus = false;
    this.table1.groupHeader.toggleExpandGroup(group);
  }
  selectedFilter = 'task_total'; // Valore predefinito
  applyFilter(event) {
    // Aggiorna direttamente `rows` in base al filtro selezionato
    this.selectedFilter = event.detail.value;
    console.log(event);
  }

  onSegmentChange() {
    if (this.selectedHour !== null) {
      // Quando un segment è selezionato
      this.customHour = null; // Svuota l'input personalizzato
      this.inputActive = true; // Disabilita l'input
      this.segmentActive = false; // Abilita i segment
    } else {
      // Quando nessun segment è selezionato
      this.inputActive = false; // Abilita l'input
    }
  }
}
